import { FormControl, FormLabel, TextField } from "@mui/material";
import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { ICommandAsync } from "../../Commands";
import clsx from "clsx";
import styled from "@emotion/styled";
import { IKeyState, InputTypes } from "./EditText";

const StyledInputElement = styled("input")`
    width: 320px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.5;
    padding: 5px 9px;
    border-radius: 0px;
    transition: all 150ms ease;
`;

interface IProps {
    id?: string;
    value: () => string;
    autoFill?: boolean;
    style?: any;
    className?: string;
    displayName: string;
    gutterBottom?: boolean;
    inputProps?: any;
    inputPropClasses?: any;
    placeholder?: string;
    type?: InputTypes;
    validationMessage?: () => string;
    rows?: number;
    multiline?: boolean;
    disabled?: boolean;
    errorText?: () => any;
    execute: (value: string) => void;
    //command: ICommandAsync;
}

export const EditText2: React.FC<IProps> = observer((props) => {
    const keyState = useRef<IKeyState>({ enterKeyPressed: false, backspaceKeyPressed: false, deleteKeyPressed: false });
    const getClasses = () => {
        return clsx({
            [props.className!]: !isEmptyOrWhitespace(props.className),
            ["editText"]: true,
            ["editTextGutterBottom"]: hasGutterBottom(),
        });
    };
    useEffect(() => {});

    const getId = (): string => {
        return props.id!;
    };

    const getAutoFill = (): "on" | "off" | "new-password" => {
        return isNullOrUndefined(props.autoFill) ? "on" : props.autoFill ? "on" : "new-password";
    };

    const getType = (): InputTypes => {
        return props.type!;
    };

    const getPlaceholder = (): string => {
        return isEmptyOrWhitespace(props.placeholder) ? "" : props.placeholder!;
    };

    const getValidationMessage = (): string => {
        return isEmptyOrWhitespace(props.validationMessage?.() as string) ? "" : (props.validationMessage?.() as string);
    };

    const hasError = (): boolean => {
        if (props.validationMessage) return !isEmptyOrWhitespace(props.validationMessage!());
        return false;
    };

    const hasGutterBottom = (): boolean => {
        return isNullOrUndefined(props.gutterBottom) ? false : props.gutterBottom!;
    };

    const isDisabled = (): boolean => {
        //return isNullOrUndefined(props.command.canExecute) ? false : !props.command.canExecute();
        return false;
    };

    const [value, setValue] = useState("");
    const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        //setValue(event.target.value);
        //await props.command.execute(event.target.value, keyState.current);
        props.execute(event.target.value);
    };

    const onKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>): Promise<void> => {
        let keyId: number = event.keyCode;

        switch (keyId) {
            case 8:
                keyState.current.backspaceKeyPressed = true;
                keyState.current.enterKeyPressed = false;
                keyState.current.deleteKeyPressed = false;
                break;
            case 13:
                keyState.current.backspaceKeyPressed = false;
                keyState.current.enterKeyPressed = true;
                keyState.current.deleteKeyPressed = false;
                break;
            case 46:
                keyState.current.backspaceKeyPressed = false;
                keyState.current.enterKeyPressed = false;
                keyState.current.deleteKeyPressed = true;
                break;
            default:
                break;
        }
        if (keyId === 8 || keyId === 13 || keyId === 46) {
            //await props.command.execute(props.value, keyState.current);
            //await CommandInvoker<{ value: string; keyState: IKeyState }>(props.command, { value: props.value(), keyState: keyState.current });
        }
    };

    const handleErrorText = (): any => {
        if (props.errorText) {
            props.errorText();
        } else {
            return "";
        }
    };
    return (
        <FormControl className={props.className} style={props.style} onClick={() => handleErrorText()}>
            {/*<label htmlFor={getId()}>{props.displayName}</label>
            <StyledInputElement type="text" name="name" value={props.value()} onChange={onChange} className="form-control" id={getId()} placeholder={getPlaceholder()} />
            <label htmlFor={getId()}>{getValidationMessage()}</label>*/}
            <FormLabel>{props.displayName}</FormLabel>
            <TextField
                InputProps={{
                    //disableUnderline: true,
                    ...props.inputProps,
                    classes: {
                        root: props.inputPropClasses,
                    },
                }}
                size={"small"}
                InputLabelProps={{ shrink: false }}
                autoComplete={getAutoFill()}
                disabled={props.disabled}
                error={hasError()}
                helperText={getValidationMessage()}
                id={getId()}
                //label={props.displayName}
                onChange={onChange}
                //onKeyDown={onKeyDown}
                placeholder={getPlaceholder()}
                type={getType()}
                value={props.value()}
                rows={props.rows}
                multiline={props.multiline}
            />
        </FormControl>
    );
});

EditText2.defaultProps = {
    type: "text",
    autoFill: true,
};

import styled from "@emotion/styled";
import { theme } from "../../Styles/AppTheme";

const ResultsSummaryContainer = styled.div`
    font-size: 10px;
    font-weight: bold;
    max-width: 125px;
    color: ${theme.palette.text.primary};
`;

interface IResultsSummaryProps {
    data: any[];
    resultType: string;
}

export const SearchSummary = (props: IResultsSummaryProps) => {
    const { data, resultType } = props;
    const showResults = () => {
        if (data.length === 1) {
            return `${data.length} ${resultType}`;
        } else {
            return `${data.length} ${resultType}s`;
        }
    };
    return <ResultsSummaryContainer>Showing: {showResults()}</ResultsSummaryContainer>;
};

import * as MobX from "mobx";
import { InitialState } from "../Models";
import { makeObservable, observable, runInAction } from "mobx";
import { deleteJWT, isJWTValid, ViewModelBase, HttpClient, ApiResult, Validation } from "@shoothill/core";
import { container } from "tsyringe";

export class InitViewModel extends ViewModelBase<any> {
    private httpClient = container.resolve(HttpClient);

    public DataReady: boolean = false;
    public InitData: InitialState = {} as InitialState;

    constructor() {
        super({});
        makeObservable(this, {
            DataReady: observable,
        });
    }

    public getInitialState = async (): Promise<boolean> => {
        if (!(await isJWTValid())) {
            await deleteJWT();
        }
        let apiResult = await this.httpClient.Get<InitialState>("/api/initialstate/getinitialstatejson");
        await runInAction(async () => {
            // if (!apiResult.payload.accountStatus.isLoggedIn) {
            // 	StoresInstance.domain.AccountStore.Logout(false);
            // }
            if (apiResult.wasSuccessful) {
                if (apiResult.payload.errors.length > 0) {
                    console.warn("GetInitialState succeeded with errors.");
                    apiResult.payload.errors.forEach((a: string) => {
                        console.warn(a);
                    });
                }
                //await this.stores.init(apiResult.payload);
                this.InitData = apiResult.payload;
                this.DataReady = true;
            }
        });
        return apiResult.wasSuccessful;
    };
}

import { AddressModel, AddressModelValidator, TestModel, TestModelValidator } from "../../../Examples/TestModel";
import { makeObservable, observable } from "mobx";
import { FieldType, HttpClient, KeyValuePair, Validation, ViewModelBase } from "@shoothill/core";
import { ICommandAsync, RelayCommandAsync } from "../../../Globals/Commands";
import { IKeyState } from "../../../Globals/Views/Primitives/EditText";
import { container } from "tsyringe";
import { GlobalHistory } from "../../../index";

//extend viewmodel base and passing your model as the generic type
export class AddressViewModel extends ViewModelBase<AddressModel> {
    public errorMessage: string = "";
    public validMessage: string = "";

    constructor() {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new AddressModel(), false);
        this.setValidator(new AddressModelValidator());
        makeObservable(this, {
            errorMessage: observable,
            validMessage: observable,
        });
        (window as any).avm = this;
    }

    public updatePostcodeCommand: ICommandAsync = new RelayCommandAsync(async (value: string) => {
        await this.updateField("postCode", value);
    });

    private async updateField(fieldName: keyof FieldType<AddressModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (this.isModelValid()) {
            //Do stuff here
            this.errorMessage = "";
            this.validMessage = "Form is valid";
        } else {
            this.errorMessage = "Form is not valid";
            this.validMessage = "";
        }
    };

    public goToAdmin = () => {
        this.history.push(`/admin`);
    };
}

import { Box, Button, Link, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import { EditableCopyViewModel } from "Globals/ViewModels/EditableCopyViewModel";
import { LoginViewModel } from "./LoginViewModel";
import { Link as RouterLink } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";
import { container } from "tsyringe";
import { useRouter, EditText, TypographyX, AutoGrid, Show } from "../../Globals";

//custom
import Logo from "../../Content/assets/logoPortrait.svg";
import { HHButton } from "Globals/Components/General/Buttons/HHButton";
import { ForgotPasswordLinkText, LoginContainer, LoginForm, LoginInput } from "./LoginView.style";

export const NoAccess: React.FunctionComponent = observer(() => {
    const [viewModel] = useState(() => new LoginViewModel());
    const { history, location, match } = useRouter();

    const doSubmit = async (e: any) => {
        e.preventDefault();
        await viewModel.logoutCommand.execute();
    };

    return (
        <>
            <LoginContainer>
                <Box style={{ marginBottom: "34px" }}>
                    <img src={Logo} alt="Healthappy logo" />
                </Box>
                <Box style={{ marginBottom: "29px" }}>
                    <Typography variant="h5">You do not have access to this site. Please logout.</Typography>
                </Box>
                <LoginForm onSubmit={doSubmit}>
                    <HHButton text={"Logout"} type="submit" width="274px" />
                </LoginForm>
            </LoginContainer>
        </>
    );
});

import { Box, Button, Fade, Paper, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import { autorun } from "mobx";
import styled from "@emotion/styled";
import { KeyValuePair, ViewModelBase } from "@shoothill/core";
import { AutoGrid } from "Globals/Components/Grid";

import ReactJson from "react-json-view";
import { TestViewModel } from "./TestViewModel";
import { TestModel } from "./TestModel";
import { EditText, IKeyState } from "../Globals/Views/Primitives/EditText";
import { Select } from "../Globals/Views/Primitives/Select";
import { useViewModel } from "../Globals/Hooks/UseViewModel";

import { MultiSelect } from "../Globals/Views/Primitives/MultiSelect";
import { CheckBox } from "../Globals/Views/Primitives/CheckBox";
import { AddressViewModel } from "../Views/Admin/Users/AddressViewModel";
import { observer, useObserver } from "mobx-react-lite";

export const TestStyle = styled(Box)<any>``;
const MainGrid = styled.section`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 2fr;
`;

function AddressView() {
    const [viewModel] = useState(() => new AddressViewModel());
    return useObserver(() => (
        <EditText
            displayName="Postcode"
            value={() => viewModel.getValue("postCode")}
            validationMessage={() => viewModel.getError("postCode")}
            command={viewModel.updatePostcodeCommand}
        />
    ));
}

export const TestPage: React.FunctionComponent = observer(() => {
    const [viewModel] = useState(() => new TestViewModel());
    const [json, setJson] = useState("{}");

    //useEffect below only gets run on initial render
    useEffect(() => {
        // *optional* passing the router into the viewmodel so we can navigate from there
        autorun(() => {
            //let a = viewModel.getModel.firstName;
            let p = JSON.stringify(viewModel.getModel, null, 2);
            setJson(p);
        });

        //This gets run when the page is exited
        return () => {
            console.log("Unmounting");
        };
    }, []);

    return (
        <Fade in timeout={1000}>
            <form onSubmit={viewModel.doSubmit}>
                {viewModel.errorMessage !== "" && (
                    <Typography variant="h2" style={{ color: "red" }}>
                        {viewModel.errorMessage}
                    </Typography>
                )}
                {viewModel.validMessage !== "" && (
                    <Typography variant="h2" style={{ color: "green" }}>
                        {viewModel.validMessage}
                    </Typography>
                )}
                <MainGrid>
                    <AutoGrid style={{ maxHeight: "100vh" }} dc={"1fr"} rowGap={"10px"} paddingTop={2} paddingLeft={2}>
                        <Button variant={"contained"} onClick={() => viewModel.isModelValid()}>
                            Validate Model
                        </Button>
                        <MultiSelect options={viewModel.getTeams} command={viewModel.updateTeamsCommand} value={() => viewModel.getValue("teams")} />

                        <EditText
                            displayName="First Name"
                            value={() => viewModel.getValue("firstName")}
                            command={viewModel.updateFirstNameCommand}
                            validationMessage={() => viewModel.getError("firstName")}
                        />
                        <EditText
                            displayName="Last Name"
                            value={() => viewModel.getValue("lastName")}
                            command={viewModel.updateLastNameCommand}
                            validationMessage={() => viewModel.getError("lastName")}
                        />

                        <AddressView />

                        <EditText displayName="DOB" value={() => viewModel.getValue("dob")} command={viewModel.updateDOBCommand} />

                        <Select
                            displayName="Age"
                            value={() => viewModel.getValue("age")}
                            options={
                                [
                                    { value: 0, label: "Zero" },
                                    { value: 10, label: "Ten" },
                                    { value: 20, label: "Twenty" },
                                    { value: 30, label: "Thirty" },
                                    { value: 40, label: "A lot older than I look" },
                                ] as KeyValuePair[]
                            }
                            command={viewModel.updateAgeCommand}
                        />
                        <Select value={() => viewModel.getValue("team")} displayName={"Choose a team..."} options={viewModel.getTeams} command={viewModel.updateTeamCommand} />

                        <EditText displayName="Memo" type={"textarea"} value={() => viewModel.getValue("memo")} command={viewModel.updateMemoCommand} />

                        <CheckBox displayName="Terms and Conditions" value={() => viewModel.getValue("terms")} command={viewModel.updateTermsCommand} />
                    </AutoGrid>

                    <ReactJson style={{ fontSize: "12px", overflow: "auto", height: "100vh" }} src={JSON.parse(json)} />

                    <Button color="inherit" onClick={viewModel.TestBearer}>
                        Test Call
                    </Button>
                    <Button onClick={viewModel.goToAdmin}>Go To Admin</Button>
                </MainGrid>
            </form>
        </Fade>
    );
});

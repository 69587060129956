export const LeftArrow = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="11.919" height="8.748" viewBox="0 0 11.919 8.748">
            <path
                id="Path_5245"
                data-name="Path 5245"
                d="M15.63,19.136l-.948.948a.2.2,0,0,1-.3,0l-4.151-4.151a.177.177,0,0,1,0-.284l4.151-4.17a.2.2,0,0,1,.3,0l.948.948a.2.2,0,0,1,0,.3L13.469,14.89h8.4a.2.2,0,0,1,.209.209v1.346a.2.2,0,0,1-.209.209H13.45l2.18,2.2A.206.206,0,0,1,15.63,19.136Z"
                transform="translate(-10.156 -11.406)"
                fill="#1ba4eb"
            />
        </svg>
    );
};

// Libraries
import React from "react";
import styled from "@emotion/styled";

// Styling & images
import { GridSpaced } from "Globals/Styles/GridHelperStyling";
import { Terms } from "./Terms";

export const TermsAndConditions: React.FC = () => {
    window.scrollTo(0, 0);

    const terms = (
        <>
            <GridSpaced container justify={"center"} margin={"0px auto"} padding={"0px 0px 70px 0px"} additionalcss={"max-width: 1124px; width: 100%;"}>
                <TermsContainer item xs={12} sm={12}>
                    <Terms />
                </TermsContainer>
            </GridSpaced>
        </>
    );

    return (
        <>
            <div>{terms}</div>
        </>
    );
};

const TermsContainer = styled(GridSpaced)`
    p {
        font-size: 14px;
        margin-bottom: 15px;
        letter-spacing: -0.35px;
        line-height: 24px;
    }

    h1 {
        text-align: left;
        font-size: 28px;
    }

    h2 {
        text-align: left;
        font-size: 24px;
    }

    ol li {
        margin-bottom: 15px;
    }
`;

import * as Mui from "@mui/material";
import React, { useEffect } from "react";
import { ApiResult } from "@shoothill/core";
import MaterialTable from "@material-table/core";
import RoleViewModel from "Views/Admin/Roles/RoleViewModel";
import { useObserver } from "mobx-react-lite";
import styled from "@emotion/styled";
import { theme } from "Globals/Styles/AppTheme";
import { RoleModel } from "Globals/Models";
import { useRouter } from "Globals/Hooks/UseRouter";

const roleViewModel = new RoleViewModel();

export const Container = styled(Mui.Box)`
    .MuiTableHead-root {
        background-color: rgba(230, 230, 230, 0.8);
        .MuiTableCell-root {
            font-size: 1rem;
            height: 70px;
        }
    }
    .MuiTableCell-root {
        font-size: 0.8rem;
    }
    .MuiTableSortLabel-root {
        :hover {
            color: ${theme.palette.secondary.main};
        }
    }
    .MuiTableRow-root {
        cursor: pointer;
        :hover {
            background-color: rgba(230, 230, 230, 0.3);
        }
    }
`;

export const RoleListContainer: React.FC = () => {
    const { history } = useRouter();
    useEffect(() => {
        const response = roleViewModel.loadRolesAsync().then((apiResult: ApiResult<RoleModel[]>) => {
            if (apiResult.wasSuccessful) {
            }
        });
    }, []);

    const handleRowClick = (e: any, rowData: any) => {
        history.push(`/roleslist/role/${rowData.id}`);
    };

    const handleNewClick = () => {
        history.push(`/roleslist/role/0`);
    };

    return useObserver(() => (
        <Container>
            <Mui.Box p={3}>
                <Mui.Box display="flex" justifyContent="space-between" alignItems="center" margin="30px">
                    <Mui.Box justifyContent="flex-start" alignItems="center">
                        <Mui.Typography variant="h4" color="textPrimary">
                            Roles
                        </Mui.Typography>
                    </Mui.Box>
                    <Mui.Box>
                        <Mui.Box flexGrow={1} textAlign="right">
                            <Mui.Button variant="contained" color="secondary" onClick={() => handleNewClick()}>
                                <Mui.Typography variant="button">New Role</Mui.Typography>
                            </Mui.Button>
                        </Mui.Box>
                    </Mui.Box>
                </Mui.Box>
                {
                    <MaterialTable
                        style={{
                            width: "99%",
                            boxShadow: "0 14px 28px rgba(0,0,0,0.15), 0 10px 10px rgba(0,0,0,0.12) ",
                            borderRadius: "15px",
                            margin: "20px auto 50px",
                            border: "none",
                        }}
                        columns={[{ title: "Name", field: "name" }]}
                        options={{
                            pageSize: 50,
                            pageSizeOptions: [10, 20, 50, 100],
                            emptyRowsWhenPaging: false,
                        }}
                        data={roleViewModel.getRoles}
                        onRowClick={handleRowClick}
                        title=""
                    />
                }
            </Mui.Box>
        </Container>
    ));
};

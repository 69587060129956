import { FormControl, FormLabel, TextField } from "@mui/material";
import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { ICommandAsync } from "../../Commands";
import clsx from "clsx";
import styled from "@emotion/styled";
import { reaction } from "mobx";
import { createStyles, makeStyles } from "@mui/styles";
//import { createStyles } from "@mui/styles/createStyles";

export interface IKeyState {
    enterKeyPressed: boolean;
    backspaceKeyPressed: boolean;
    deleteKeyPressed: boolean;
}

export type InputTypes = "text" | "password" | "email" | "textarea" | "number" | "tel";

const StyledInputElement = styled("input")`
    width: 320px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.5;
    padding: 5px 9px;
    border-radius: 0px;
    transition: all 150ms ease;
`;

interface IProps {
    id?: string;
    value: () => string;
    autoFill?: boolean;
    style?: any;
    className?: string;
    displayName: string;
    allowDecimals?: boolean;
    gutterBottom?: boolean;
    inputProps?: any;
    inputPropClasses?: any;
    placeholder?: string;
    type?: InputTypes;
    validationMessage?: () => string;
    rows?: number;
    multiline?: boolean;
    disabled?: boolean;
    errorText?: () => any;
    command: ICommandAsync;
}

export const EditText: React.FC<IProps> = observer((props) => {
    const keyState = useRef<IKeyState>({ enterKeyPressed: false, backspaceKeyPressed: false, deleteKeyPressed: false });
    //EN: Need the below for text inputs to stop the cursor jumping to the end
    //This happens because we get a render with the old value and then another render with the new value
    //This cause react to think the value has been completely replaced and it resets the cursor
    //So we maintain state internally and just update the state if the observable changes
    //This only applied for things with cursors as far as I know
    //More info here https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html
    let [value, setValue] = useState<string>("");

    useEffect(() => {
        reaction(
            () => props.value(),
            (value) => {
                setValue(value);
            },
        );
        setValue(props.value());
    }, []);

    const getClasses = () => {
        return clsx({
            [props.className!]: !isEmptyOrWhitespace(props.className),
            ["editText"]: true,
            ["editTextGutterBottom"]: hasGutterBottom(),
        });
    };

    const useStyles = makeStyles(
        createStyles({
            helperText: {
                margin: 0,
                fontSize: "12px",
            },
        }),
    );
    const classes = useStyles();

    const getId = (): string => {
        return props.id!;
    };

    const getAutoFill = (): "on" | "off" | "new-password" => {
        return isNullOrUndefined(props.autoFill) ? "on" : props.autoFill ? "on" : "new-password";
    };

    const getType = (): InputTypes => {
        if (props.type === "number") return "text";
        return props.type!;
    };

    const getPlaceholder = (): string => {
        return isEmptyOrWhitespace(props.placeholder) ? "" : props.placeholder!;
    };

    const getValidationMessage = (): string => {
        return isEmptyOrWhitespace(props.validationMessage?.() as string) ? "" : (props.validationMessage?.() as string);
    };

    const hasError = (): boolean => {
        if (props.validationMessage) return !isEmptyOrWhitespace(props.validationMessage!());
        return false;
    };

    const hasGutterBottom = (): boolean => {
        return isNullOrUndefined(props.gutterBottom) ? false : props.gutterBottom!;
    };

    const isDisabled = (): boolean => {
        return isNullOrUndefined(props.command.canExecute) ? false : !props.command.canExecute();
    };

    const onChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        let val = event.target.value;
        if (props.type === "number") {
            if (props.allowDecimals) {
                val = val.replace(/[^0-9.]/g, "");
            } else {
                val = val.replace(/[^0-9]/g, "");
            }
            if (val.length === 0) val = "0";
        }
        setValue(val);
        await props.command.execute(val, keyState.current);
    };

    const onKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>): Promise<void> => {
        let keyId: number = event.keyCode;

        switch (keyId) {
            case 8:
                keyState.current.backspaceKeyPressed = true;
                keyState.current.enterKeyPressed = false;
                keyState.current.deleteKeyPressed = false;
                break;
            case 13:
                keyState.current.backspaceKeyPressed = false;
                keyState.current.enterKeyPressed = true;
                keyState.current.deleteKeyPressed = false;
                break;
            case 46:
                keyState.current.backspaceKeyPressed = false;
                keyState.current.enterKeyPressed = false;
                keyState.current.deleteKeyPressed = true;
                break;
            default:
                break;
        }
        if (keyId === 8 || keyId === 13 || keyId === 46) {
            await props.command.execute(value, keyState.current);
        }
    };

    const handleErrorText = (): any => {
        if (props.errorText) {
            props.errorText();
        } else {
            return "";
        }
    };

    return (
        <FormControl className={props.className} style={props.style} onClick={() => handleErrorText()}>
            {/*<label htmlFor={getId()}>{props.displayName}</label>
            <StyledInputElement type="text" name="name" value={props.value()} onChange={onChange} className="form-control" id={getId()} placeholder={getPlaceholder()} />
            <label htmlFor={getId()}>{getValidationMessage()}</label>*/}
            <FormLabel>{props.displayName}</FormLabel>
            <TextField
                InputProps={{
                    //disableUnderline: true,
                    ...props.inputProps,
                    classes: {
                        root: props.inputPropClasses,
                    },
                }}
                size={"small"}
                InputLabelProps={{ shrink: false }}
                autoComplete={getAutoFill()}
                disabled={props.disabled}
                error={hasError()}
                helperText={getValidationMessage()}
                id={getId()}
                //label={props.displayName}
                onChange={onChange}
                onKeyDown={onKeyDown}
                placeholder={getPlaceholder()}
                type={getType()}
                value={value}
                rows={props.rows}
                multiline={props.multiline}
                FormHelperTextProps={{
                    classes: {
                        root: classes.helperText,
                    },
                }}
            />
        </FormControl>
    );
});

EditText.defaultProps = {
    type: "text",
    autoFill: true,
    allowDecimals: true,
};

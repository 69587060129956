import { Box, Button, FormLabel } from "@mui/material";
import { useObserver } from "mobx-react-lite";

import { NewProfileQuestionAnswer } from "../../../Globals/Models/NewProfileQuestionAnswer";
import NewProfileQuestionAnswerViewModel from "../../../Globals/ViewModels/Admin/NewProfileQuestionAnswerViewModel";
import { RoundedEditText } from "./AdminAddProfileView.styles";
import { EditText } from "../../../Globals/Views/Primitives/EditText";

//styling
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelRounded from "@mui/icons-material/CancelRounded";
import DragHandleRoundedIcon from "@mui/icons-material/DragHandleRounded";
import styled from "@emotion/styled";
import { theme } from "../../../Globals/Styles/AppTheme";
import NewProfileViewModel from "Globals/ViewModels/Admin/NewProfileViewModel";
import { runInAction } from "mobx";
import * as Mui from "@mui/material";
import { useDrag, useDrop, DropTargetMonitor, XYCoord } from "react-dnd";
import React from "react";

const AnswerContainer = styled.div`
    position: relative;
    background: ${theme.palette.info.light};
    width: 467px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 0px;
`;
const AnswerBtnGroup = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
`;

const AnswerBtn = styled.button`
    display: inline-block;
    border: none;
    padding: 0;
    margin: 0;
    text-decoration: none;
    background: none;
    line-height: 1;
    cursor: pointer;
    text-align: center;
    transition: transform 250ms ease-in-out, transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;

    svg {
        height: 30px;
        width: 30px;
    }

    :hover,
    :focus {
        transform: scale(1.1);
    }

    :active {
        transform: scale(1.1);
    }
`;

const AnswerEditText = styled(EditText)`
    .MuiOutlinedInput-root {
        background: ${theme.palette.common.white};
    }
`;

export interface IProfileQuestionAnswer {
    parentViewModel: NewProfileViewModel;
    childViewModel: NewProfileQuestionAnswerViewModel;
    key: any;
    index: number;
    id: string;
    moveCard: (dragIndex: number, hoverIndex: number) => void;
    removeAnswer: (index: number) => void;
}

interface DragItem {
    index: number;
    id: string;
    type: string;
}

export const ProfileQuestionAnswer: React.FC<IProfileQuestionAnswer> = ({ parentViewModel, childViewModel, key, index, id, moveCard, removeAnswer }) => {
    const ref = React.useRef<HTMLDivElement>(null);

    const [{ handlerId }, drop] = useDrop({
        accept: "card",
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: DragItem, monitor: DropTargetMonitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();

            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            // Determine mouse position
            const clientOffset = monitor.getClientOffset();

            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex);

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: "card",
        item: () => {
            return { id, index };
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return useObserver(() => (
        <AnswerContainer ref={ref} data-handler-id={handlerId}>
            <Box style={{ width: "100%" }} display="flex" justifyContent="space-between" alignItems="center">
                <DragHandleRoundedIcon style={{ marginLeft: "15px", color: "#9d9d9d", fontSize: "24px" }} />
                <RoundedEditText
                    displayName=""
                    command={childViewModel.updateAnswerTextCommand}
                    value={() => childViewModel.getValue("answerText")}
                    type={"textarea"}
                    rows={1}
                    multiline={true}
                    placeholder={"An answer"}
                    validationMessage={() => childViewModel.getError("answerText")}
                    style={{ width: "345px" }}
                />
                <AnswerBtn onClick={() => removeAnswer(index)}>
                    <CancelRounded style={{ width: "30px", height: "30px", color: "#404040", marginRight: "15px" }} />
                </AnswerBtn>
            </Box>

            <Box style={{ display: "flex", justifyContent: "flex-end", width: "100%", paddingRight: "61px" }}>
                <Box style={{ display: "grid", gridTemplateColumns: "1fr 1fr", columnGap: "10px", alignItems: "center" }}>
                    <FormLabel>{"Score"}</FormLabel>
                    <AnswerEditText
                        displayName=""
                        command={childViewModel.updateAnswerScoreCommand}
                        value={() => childViewModel.getValue("answerScore")}
                        validationMessage={() => childViewModel.getError("answerScore")}
                        type="text"
                        style={{ width: "50px", margin: "0px" }}
                    />
                </Box>
            </Box>
        </AnswerContainer>
    ));
};

export const MenuLogoutIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17.074" height="15.998" viewBox="0 0 17.074 15.998">
            <g id="menu-logout" transform="translate(-5 -7.801)">
                <path
                    id="Path_5245"
                    data-name="Path 5245"
                    d="M16.6,19.136l.948.948a.2.2,0,0,0,.3,0L22,15.932a.178.178,0,0,0,0-.284l-4.151-4.17a.2.2,0,0,0-.3,0l-.948.948a.2.2,0,0,0,0,.3l2.161,2.161h-8.4a.2.2,0,0,0-.209.209v1.346a.2.2,0,0,0,.209.209h8.417l-2.18,2.2A.206.206,0,0,0,16.6,19.136ZM6.782,23.8H13.89a1.78,1.78,0,0,0,1.782-1.782V18.7a.231.231,0,0,0-.227-.227H14.118a.231.231,0,0,0-.227.227v2.6a.708.708,0,0,1-.72.72H7.483a.708.708,0,0,1-.72-.72V10.3a.708.708,0,0,1,.72-.72h5.668a.708.708,0,0,1,.72.72v2.6a.231.231,0,0,0,.227.227h1.308a.231.231,0,0,0,.227-.227V9.582A1.78,1.78,0,0,0,13.853,7.8H6.782A1.78,1.78,0,0,0,5,9.582V22.036A1.776,1.776,0,0,0,6.782,23.8Z"
                />
            </g>
        </svg>
    );
};

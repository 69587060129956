import { Stores } from "./Globals/Stores";
import { deleteJWT, HttpClient } from "@shoothill/core";
import { container } from "tsyringe";
import { AxiosError, AxiosResponse } from "axios";
import { GlobalHistory } from "./index";

export class Startup {
    private httpClient: HttpClient = container.resolve(HttpClient);

    public init = async () => {
        console.log("Startup init");
        const stores = new Stores();
        await stores.init();

        //Register intercept handlers for axios calls
        this.httpClient.setOnResponseCallback = this._handleResponse;
        this.httpClient.setOnErrorCallback = this._handleError;
        console.log("Finished startup init");
    };

    private _handleResponse = ({ data }: AxiosResponse): any => {
        if (!data.wasSuccessful) {
            if (data.errors.length > 0 && data.errors[0].statusCode === 401) {
                deleteJWT().then(() => {
                    GlobalHistory.push("/login");
                });
            }
        }
        return data;
    };

    private _handleError = (error: AxiosError) => {
        console.log("Axios error");
        let res = error.response;
        if (res && res.status == 401) {
            GlobalHistory.push("/login");
        } else if (res && res.status == 404) {
            console.log("We found an error!!!");
        }
        console.error("Looks like there was a problem. Status Code: " + res!.status);
        return Promise.reject(error);
    };
}

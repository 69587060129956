import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { theme } from "../../Styles/AppTheme";

export const ToolbarContainer = styled(Box)`
    padding: 0px 30px;
    height: 70px;
    display: grid;
    grid-template-columns: minmax(95px, 125px) 10fr 1fr;
    border-top: 1px solid ${theme.palette.healthAppyBlue.main};
    align-items: center;

    .MuiFormControl-root {
        padding: 0;
    }

    .toolbar__checkbox {
        .MuiTypography-root {
            font-weight: bold;
            color: pink;
        }
    }
`;

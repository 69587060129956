import { Box, Button, Typography } from "@mui/material";
import React from "react";

export const Buttons: React.FC = () => {
    return (
        <>
            <>
                <Typography>
                    Lots more buttons can be found here{" "}
                    <a href="https://material-ui.com/components/buttons/" target="_blank">
                        Material UI Buttons
                    </a>
                </Typography>
                <Box>
                    <Button variant="contained">Default</Button>
                    <Button variant="contained" color="primary">
                        Primary
                    </Button>
                    <Button variant="contained" color="secondary">
                        Secondary
                    </Button>
                    <Button variant="contained" color="secondary" disabled>
                        Disabled
                    </Button>
                    <Button variant="contained" href="#contained-buttons">
                        Link
                    </Button>
                    <input accept="image/*" id="contained-button-file" multiple type="file" />
                    <label htmlFor="contained-button-file">
                        <Button variant="contained" component="span">
                            Upload
                        </Button>
                    </label>
                </Box>
            </>
        </>
    );
};

// Libs
import { KeyValuePair, ModelBase } from "@shoothill/core";
import { Validator } from "../../../../Globals/Validation";
import { action, makeObservable, observable, runInAction } from "mobx";
import { UserFile } from "Globals/Models";

import { ContentModelDTO } from "../../DTO/ContentModelDTO";
import { ContentAndRelated } from "../../DTO/ContentAndRelated";

export class NewContentModel extends ModelBase<NewContentModel> {
    public id: string = "";
    public categoryId: string = "";
    public contentTypeId: string = "";
    public isEasy: boolean = false;
    public isMedium: boolean = false;
    public isHard: boolean = false;
    public mainImageUrl: string = "";
    public mainImageName: string = "";
    public sponsorImageUrl: string = "";
    public sponsorImageName: string = "";
    public title: string = "";
    public subTitle: string = "";
    public isCalendarBonus: boolean = false;
    public isFavourite: boolean = false;
    public bonusPoints: number = 0;
    public points: number = 0;
    public occurrenceType: string = "day";
    public occurrenceDay: string = "0";
    public startDate: Date | null = null;
    public isRecurring: boolean = false;
    public address: string = "";
    public location: string = "";
    public contentHTML: string = "";
    public isVisibleInApp: boolean = false;
    public isDeleted: boolean = false;
    public createdByUserId: string = "";
    public createdDate: Date | null = null;
    public lastUpdatedByUserId: string = "";
    public lastUpdatedDate: Date | null = null;

    // Store for TinyMCE
    public contentImages: UserFile[] = [];

    // Store for Blacklist
    public customers: string[] = [];

    // Validator for difficulty
    public difficulty: number = 0;

    constructor() {
        super();
        makeObservable(this, {
            id: observable,
            categoryId: observable,
            isEasy: observable,
            isMedium: observable,
            isHard: observable,
            mainImageUrl: observable,
            mainImageName: observable,
            sponsorImageUrl: observable,
            sponsorImageName: observable,
            title: observable,
            subTitle: observable,
            isCalendarBonus: observable,
            isFavourite: observable,
            bonusPoints: observable,
            contentTypeId: observable,
            points: observable,
            occurrenceType: observable,
            occurrenceDay: observable,
            startDate: observable,
            address: observable,
            location: observable,
            contentHTML: observable,
            contentImages: observable,
            customers: observable,
            isRecurring: observable,
            isVisibleInApp: observable,
            isDeleted: observable,
            toDto: action,
            reset: action,
        });
    }

    toDto(): ContentModelDTO {
        return {
            id: this.id,
            categoryId: this.categoryId,
            contentTypeId: this.contentTypeId,
            isEasy: this.isEasy,
            isMedium: this.isMedium,
            isHard: this.isHard,
            mainImageUrl: this.mainImageUrl,
            mainImageName: this.mainImageName,
            sponsorImageUrl: this.sponsorImageUrl,
            sponsorImageName: this.sponsorImageName,
            title: this.title,
            subTitle: this.subTitle,
            isCalendarBonus: this.isCalendarBonus,
            isFavourite: this.isFavourite,
            bonusPoints: this.bonusPoints,
            points: this.points,
            occurrenceType: this.occurrenceType,
            occurrenceDay: this.occurrenceDay,
            startDate: this.startDate,
            address: this.address,
            contentHTML: this.contentHTML,
            isVisibleInApp: this.isVisibleInApp,
            isDeleted: this.isDeleted,
            isRecurring: this.isRecurring,
            createdByUserId: this.createdByUserId,
            createdDate: this.createdDate,
            lastUpdatedByUserId: this.lastUpdatedByUserId,
            lastUpdatedDate: this.lastUpdatedDate,
        };
    }

    customersToDto(): string[] {
        return this.customers;
    }

    fromDto(payload: ContentAndRelated) {
        runInAction(() => {
            this.customers = payload.customers;

            let dif = 0;
            if (payload.content.isEasy) {
                dif++;
            }
            if (payload.content.isMedium) {
                dif++;
            }
            if (payload.content.isHard) {
                dif++;
            }
            this.difficulty = dif;
        });
    }

    reset() {
        this.id = "";
        this.categoryId = "";
        this.isEasy = false;
        this.isMedium = false;
        this.isHard = false;
        this.mainImageUrl = "";
        this.mainImageName = "";
        this.sponsorImageUrl = "";
        this.sponsorImageName = "";
        this.title = "";
        this.subTitle = "";
        this.isCalendarBonus = false;
        this.isFavourite = false;
        this.bonusPoints = 0;
        this.contentTypeId = "";
        this.points = 0;
        this.occurrenceType = "day";
        this.occurrenceDay = "-1";
        this.startDate = null;
        this.address = "";
        this.location = "";
        this.contentHTML = "";
        this.contentImages = [];
        this.customers = [];
        this.isRecurring = false;
        this.isVisibleInApp = false;
        this.isDeleted = false;
    }
}

export class NewContentValidator extends Validator<NewContentModel> {
    constructor() {
        super();

        this.ruleFor("categoryId").notEmpty().withMessage("A category must be selected.");
        this.ruleFor("startDate")
            .notNull()
            .withMessage("A start date must be set.")
            .when((model) => model.occurrenceType === "date");
        this.ruleFor("occurrenceDay")
            .notNull()
            .withMessage("A day must be set.")
            .notEqual("-1")
            .withMessage("A day must be set.")
            .when((model) => model.occurrenceType === "day");
        this.ruleFor("customers").must({ predicate: (customers, model) => model.customers.length > 0, message: (value, model) => "At least one customer must be approved." });
        this.ruleFor("title").notEmpty().withMessage("Title can not be blank.");
        //this.ruleFor("bonusPoints").notNull().withMessage("Points can not be blank.").notEqual(0);
        //this.ruleFor("subTitle").notEmpty().withMessage("Subtitle can not be blank.");

        //this.ruleFor("difficulty").notEqual(0).withMessage("At least one difficulty must be selected.");

        //this.ruleFor("mainImageUrl").notEmpty().withMessage("A main image must be uploaded.");
        //this.ruleFor("contentHTML").notEmpty().withMessage("A piece of content must have a body of data.");
        /*this.ruleFor("confirmPassword")
            .notEmpty()
            .must({ predicate: (confirmPassword, model) => confirmPassword === model.password, message: (value, model) => "Passwords must match" })
            .when((model) => model.password.length > 0);*/
    }
}

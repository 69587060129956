import { FormControl, FormLabel, TextField } from "@mui/material";
import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";
import { ICommandAsync } from "../../Commands";
import clsx from "clsx";
import styled from "@emotion/styled";

export interface IKeyStateGoogle {
    enterKeyPressed: boolean;
    backspaceKeyPressed: boolean;
    deleteKeyPressed: boolean;
}

export type InputTypesGoogle = "text";

const StyledInputElement = styled("input")`
    width: 320px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 5px 5px;
    border-radius: 1px;
    transition: all 150ms ease;
`;

interface IProps {
    id?: string;
    value: () => string;
    autoFill?: boolean;
    style?: any;
    className?: string;
    displayName: string;
    gutterBottom?: boolean;
    inputProps?: any;
    placeholder?: string;
    type?: InputTypesGoogle;
    validationMessage?: () => string;
    searchAddress?: string;
    setLocation: (address: string, latitude: string, longitude: string, postcode: string) => void;
    setAddress?: (address: string) => void;
    disabled?: boolean;

    command: ICommandAsync;
}

export const GoogleAddress: React.FC<IProps> = observer((props) => {
    const inputReference = useRef<HTMLInputElement>();
    const keyState = useRef<IKeyStateGoogle>({ enterKeyPressed: false, backspaceKeyPressed: false, deleteKeyPressed: false });
    const [selection, setSelection] = React.useState<[number | null, number | null] | null>(null);
    //EN: This is to fix a weird react issue where the cursor keeps jumping to the end of the input
    React.useLayoutEffect(() => {
        if (selection && inputReference.current) {
            //[ref.current.selectionStart, ref.current.selectionEnd] = selection;
            setTimeout(() => {
                inputReference!.current!.selectionStart = selection[0];
                inputReference!.current!.selectionEnd = selection[1];
            }, 1);
        }
    }, [selection]);

    const getClasses = () => {
        return clsx({
            [props.className!]: !isEmptyOrWhitespace(props.className),
            ["editText"]: true,
            ["editTextGutterBottom"]: hasGutterBottom(),
        });
    };
    useEffect(() => {
        let listener: google.maps.MapsEventListener | undefined;

        if (!isNullOrUndefined(inputReference) && !isNullOrUndefined(inputReference.current)) {
            const provider = new google.maps.places.Autocomplete(inputReference.current!, {
                componentRestrictions: { country: "gb" },
            });

            listener = google.maps.event.addListener(provider, "place_changed", async () => {
                const location = provider.getPlace();

                if (!isEmptyOrWhitespace(location.place_id)) {
                    const validLocationType = location.types!.includes("postal_code") || location.types!.includes("street_address");
                    const result = validLocationType ? location.formatted_address : `${location.name}, ${location.formatted_address}`;
                    const coordinates = location.geometry!.location;
                    let postcode = "";

                    location?.address_components?.forEach((entry) => {
                        if (entry.types?.[0] === "postal_code") {
                            postcode = entry.long_name;
                        }
                    });

                    await props.setLocation(result!, coordinates.lat().toString(), coordinates.lng().toString(), postcode);
                    await props.command.execute(result, keyState.current);
                }
            });
        }

        return () => {
            if (!isNullOrUndefined(listener)) {
                google.maps.event.removeListener(listener!);
            }
        };
    }, []);

    const getId = (): string => {
        return props.id!;
    };

    const getAutoFill = (): "on" | "off" | "new-password" => {
        return isNullOrUndefined(props.autoFill) ? "on" : props.autoFill ? "on" : "new-password";
    };

    const getType = (): InputTypesGoogle => {
        return props.type!;
    };

    const getPlaceholder = (): string => {
        return isEmptyOrWhitespace(props.placeholder) ? "" : props.placeholder!;
    };

    const getValidationMessage = (): string => {
        return isEmptyOrWhitespace(props.validationMessage?.() as string) ? "" : (props.validationMessage?.() as string);
    };

    const hasError = (): boolean => {
        if (props.validationMessage) return !isEmptyOrWhitespace(props.validationMessage!());
        return false;
    };

    const hasGutterBottom = (): boolean => {
        return isNullOrUndefined(props.gutterBottom) ? false : props.gutterBottom!;
    };

    const isDisabled = (): boolean => {
        return isNullOrUndefined(props.command.canExecute) ? false : !props.command.canExecute();
    };

    const onChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        if (props.setAddress) {
            props.setAddress(event.target.value);
        }
        setSelection([event.target.selectionStart, event.target.selectionEnd]);
        await props.command.execute(event.target.value, keyState.current);
    };

    const onKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>): Promise<void> => {
        let keyId: number = event.keyCode;

        switch (keyId) {
            case 8:
                keyState.current.backspaceKeyPressed = true;
                keyState.current.enterKeyPressed = false;
                keyState.current.deleteKeyPressed = false;
                break;
            case 13:
                keyState.current.backspaceKeyPressed = false;
                keyState.current.enterKeyPressed = true;
                keyState.current.deleteKeyPressed = false;
                break;
            case 46:
                keyState.current.backspaceKeyPressed = false;
                keyState.current.enterKeyPressed = false;
                keyState.current.deleteKeyPressed = true;
                break;
            default:
                break;
        }
        if (keyId === 8 || keyId === 13 || keyId === 46) {
            await props.command.execute(props.value(), keyState.current);
        }
    };

    return (
        <FormControl className={props.className} style={props.style}>
            <FormLabel>{props.displayName}</FormLabel>
            <TextField
                InputProps={{
                    ...props.inputProps,
                }}
                size={"small"}
                InputLabelProps={{ shrink: false }}
                inputProps={{
                    style: {
                        padding: "0px 5px",
                    },
                }}
                inputRef={inputReference}
                autoComplete={getAutoFill()}
                disabled={props.disabled}
                error={hasError()}
                helperText={getValidationMessage()}
                id={getId()}
                onChange={onChange}
                onKeyDown={onKeyDown}
                placeholder={getPlaceholder()}
                type={getType()}
                value={props.value()}
            />
        </FormControl>
    );
});

GoogleAddress.defaultProps = {
    type: "text",
    autoFill: true,
};

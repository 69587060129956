import { ModelBase, ViewModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { CategoryModel } from "./CategoryModel";
import { ProfileQuestionsModel } from "./ProfileQuestionsModel";
import { ProfileQuestionAnswersViewModel } from "./ProfileQuestionAnswersViewModel";

export class ProfileQuestionsViewModel extends ViewModelBase<ProfileQuestionsModel> {
    constructor(init: any) {
        super(new ProfileQuestionsModel(init));
        makeObservable(this, {});
    }
}

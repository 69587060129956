import { AccountStatus, ApiResult, HttpClient } from "@shoothill/core";
import { RegisterUserModel, RegisterUserValidator } from "Views/Register/RegisterUserModel";
import { ViewModelBase } from "@shoothill/core";
import { FieldType } from "@shoothill/core";

import { AppUrls } from "AppUrls";
import { container } from "tsyringe";
import { AccountStore } from "Globals/Stores/Domain";
import { DomainStores } from "Globals/Stores";
import { RelayCommandAsync, ICommandAsync } from "../../Globals/Commands";
import { IKeyState } from "../../Globals";

//Pass in <any> as the generic type because we are showing the use of nested classes
export class RegisterUserViewModel extends ViewModelBase<RegisterUserModel> {
    private accountStore = container.resolve(AccountStore);
    private domainStores = container.resolve(DomainStores);
    private httpClient = container.resolve(HttpClient);

    constructor() {
        super(new RegisterUserModel());
        console.log("Register View Model Created");
        this.setValidator(new RegisterUserValidator());
    }

    public updateFirstNameCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("firstName", value, keyState);
    });
    public updateLastNameCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("lastName", value, keyState);
    });
    public updateEmailAddressCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("emailAddress", value, keyState);
    });
    public updatePhoneNumber: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("phoneNumber", value, keyState);
    });
    public updatePasswordCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("password", value, keyState);
        this.isFieldValid("confirmPassword");
    });
    public updateConfirmPasswordCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("confirmPassword", value, keyState);
    });
    public updateTermsCommand: ICommandAsync = new RelayCommandAsync(async (value: string) => {
        await this.updateField("terms", value);
    });

    private async updateField(fieldName: keyof FieldType<RegisterUserModel>, value: any, keyState?: IKeyState) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    public register = async (): Promise<ApiResult<AccountStatus>> => {
        let response = await this.httpClient.Post<AccountStatus>(AppUrls.Server.Account.Register, this.getModel);
        if (response.wasSuccessful) {
            await this.accountStore.getLoginState(response.payload);
            this.history.push("/");
        } else {
            this.domainStores.OpenSnackBar("There was an error registering this user. " + response.errors[0].message, "error");
        }
        return response;
    };
}

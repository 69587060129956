import { TestModel, TestModelValidator } from "./TestModel";
import { makeObservable, observable } from "mobx";
import { FieldType, HttpClient, KeyValuePair, Validation, ViewModelBase } from "@shoothill/core";
import { ICommandAsync, RelayCommandAsync } from "../Globals/Commands";
import { IKeyState } from "../Globals/Views/Primitives/EditText";
import { container } from "tsyringe";
import { GlobalHistory } from "../index";

//extend viewmodel base and passing your model as the generic type
export class TestViewModel extends ViewModelBase<TestModel> {
    private static _instance: TestViewModel;
    private httpClient = container.resolve(HttpClient);
    private bind = this.getContext();
    public errorMessage: string = "";
    public validMessage: string = "";

    constructor() {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new TestModel(), false);
        this.setValidator(new TestModelValidator());
        makeObservable(this, {
            errorMessage: observable,
            validMessage: observable,
        });
    }

    public updateFirstNameCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("firstName", value);
    });
    public updateLastNameCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("lastName", value);
    });
    public updateTeamsCommand: ICommandAsync = new RelayCommandAsync(async (value: string[]) => {
        await this.updateField("teams", value);
    });
    public updateTeamCommand: ICommandAsync = new RelayCommandAsync(async (value: string[]) => {
        await this.updateField("team", value);
    });
    public updateAgeCommand: ICommandAsync = new RelayCommandAsync(async (value: any) => {
        await this.updateField("age", value);
    });
    public updateDOBCommand: ICommandAsync = new RelayCommandAsync(async (value: string) => {
        await this.updateField("dob", value);
    });
    public updateMemoCommand: ICommandAsync = new RelayCommandAsync(async (value: string) => {
        await this.updateField("memo", value);
    });
    public updateTermsCommand: ICommandAsync = new RelayCommandAsync(async (value: boolean) => {
        await this.updateField("terms", value);
    });

    private async updateField(fieldName: keyof FieldType<TestModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    public get getTeams() {
        return [
            {
                value: "0",
                label: "Blackpool",
            },
            {
                value: "1",
                label: "Man City",
            },
            {
                value: "2",
                label: "Shrewsbury",
            },
            {
                value: "3",
                label: "Wolves",
            },
        ] as KeyValuePair[];
    }
    public TestBearer = async () => {
        let response = await this.httpClient.Get("api/account/testbearer");
    };

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (this.isModelValid()) {
            //Do stuff here
            this.errorMessage = "";
            this.validMessage = "Form is valid";
        } else {
            this.errorMessage = "Form is not valid";
            this.validMessage = "";
        }
    };

    /*    public isModelValid(): boolean {
        return true;
    }
    //This must be present in your viewmodel. Just return true if you dont need to validate anything.
    //keyof BlankModel & string lets you add type checking to the fieldName
    //I am using the validator package to make checking easier https://www.npmjs.com/package/validator
    public isFieldValid(fieldName: keyof FieldType<TestModel> | string, value: any): Validation {
        let errorMessage: string = "";
        let isValid: boolean = false;
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return [isValid, errorMessage];
    }*/

    public goToAdmin = () => {
        this.history.push(`/admin`);
    };

    //public afterUpdate: undefined;
    //public beforeUpdate: undefined;
    afterUpdate(fieldName: keyof FieldType<TestModel>, value: any): void {
        //Do something like call an api or whatever
        //Can use this instead of mobx reaction
        return;
    }
    beforeUpdate(fieldName: keyof FieldType<TestModel>, value: any): any {
        //Do something and return the new value if you want or just use it to perform another action
        if (value === "something" && fieldName === "firstName") {
            value = "boo";
        }
        return value;
    }
}

// App
import { BaseStore } from "@shoothill/core";
import Moment from "moment";
import { Stores } from "../../Stores";
import { RoleModel } from "Globals/Models";
import { singleton } from "tsyringe";
import { action, computed, makeObservable, observable } from "mobx";

interface Filters {
    date: any;
    orderAsc: boolean;
    searchFilter: string;
}

@singleton()
export class RoleStore extends BaseStore {
    private roles = observable<RoleModel>([]);
    private isLoadingData: boolean = false;
    private filters: Filters = {
        date: Moment(),
        orderAsc: true,
        searchFilter: "",
    };
    private lastUpdated: any = Moment();

    public constructor() {
        super();
        makeObservable<RoleStore, "isLoadingData" | "filters" | "lastUpdated">(this, {
            isLoadingData: observable,
            filters: observable,
            lastUpdated: observable,
            getRoleCount: computed,
            setRoles: action,
            clearRoles: action,
            setIsLoadingData: action,
            getRoles: computed,
            getIsLoadingData: computed,
            getFilters: computed,
            getFilteredRoles: computed,
        });
    }

    public Init(stores: Stores) {}

    get getRoleCount(): number {
        return this.roles.length;
    }

    public setRoles(roles: RoleModel[]) {
        this.roles.clear();
        roles.forEach((role, index) => {
            let domainModel = this.roles.find((dm) => dm.id === role.id);

            if (!domainModel) {
                domainModel = new RoleModel(role.id);

                domainModel.toModel(role);
                this.roles.push(domainModel);
            }
        });
        this.isLoadingData = false;
        this.lastUpdated = Moment();
    }

    public clearRoles() {
        this.roles.clear();
    }

    public setIsLoadingData(loading: boolean) {
        this.isLoadingData = loading;
    }

    public get getRoles() {
        return this.roles;
    }

    public get getIsLoadingData() {
        return this.isLoadingData;
    }

    public get getFilters() {
        return this.filters;
    }

    public get getFilteredRoles() {
        let roles = this.roles.slice();
        if (this.filters.searchFilter) {
            roles = this.roles.filter((or) => or.name.toLowerCase().includes(this.filters.searchFilter));
        }
        return roles;
    }
}

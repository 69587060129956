// Libs
import { ModelBase } from "@shoothill/core";
import { Validator } from "../../../../Globals/Validation";
import { makeObservable, observable } from "mobx";

export class NewFAQModel extends ModelBase<NewFAQModel> {
    public question: string = "";
    public answer: string = "";

    constructor() {
        super();
        makeObservable(this, {
            question: observable,
            answer: observable,
        });
    }
}
export class NewFAQValidator extends Validator<NewFAQModel> {
    constructor() {
        super();

        this.ruleFor("question").notEmpty();
        this.ruleFor("answer").notEmpty();
    }
}

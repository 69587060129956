import { Box, Button, TextField } from "@mui/material";
import { getHistory } from "@shoothill/core";
import { AppUrls } from "AppUrls";
import { theme } from "Globals/Styles/AppTheme";
import React, { useContext, useState } from "react";
import styled from "@emotion/styled";
import AdminCustomersListViewModel, { AdminCustomerTableDataModel } from "../../../Globals/ViewModels/Admin/AdminCustomersListViewModel";
import { NewCustomerModal } from "../Modals/NewCustomerModal";
import { useObserver } from "mobx-react-lite";
import MaterialTable, { MTableToolbar } from "@material-table/core";
import Typography from "@mui/material/Typography";

//custom

import { ToolbarContainer } from "../../../Globals/Components/Table/ToolbarContainer";
import { HHTable } from "../../../Globals/Components/Table/HHTable";
import { HHButton } from "Globals/Components/General/Buttons/HHButton";
import { SearchSummary } from "Globals/Components/Table/SearchSummary";
import { AdminSecondaryToolbar, TablePageContent, ToolbarTitle } from "../../../Globals/Components/General/General.styles";
import { AdminContentLiteTableDataModel } from "../../../Globals/ViewModels/Admin/AdminContentListViewModel";
import { DeleteSVG } from "../../../Globals/Views/IconSVGs/DeleteSVG";
import { DeleteModal } from "../Modals/DeleteModal";

export const AdminCustomersListView: React.FC = () => {
    const [filter, setFilter] = useState("");
    const [viewModel] = useState(new AdminCustomersListViewModel());
    const [newCustomerModal, setNewCustomerModal] = useState(false);
    const [deleteCustomerModal, setDeleteCustomerModal] = useState(false);
    const [customerIdToDelete, setCustomerIdToDelete] = useState<string | null>(null);

    const modals = () => (
        <>
            {newCustomerModal && (
                <NewCustomerModal
                    isActive={newCustomerModal}
                    confirmClose={() => {
                        viewModel.loadData("");
                        setNewCustomerModal(false);
                    }}
                    onCancel={() => setNewCustomerModal(false)}
                />
            )}
            {deleteCustomerModal && (
                <DeleteModal
                    isActive={deleteCustomerModal}
                    confirmClose={async () => {
                        if (customerIdToDelete) {
                            await viewModel.deleteCustomer(customerIdToDelete);
                        }
                        setDeleteCustomerModal(false);
                    }}
                    onCancel={() => {
                        setCustomerIdToDelete(null);
                        setDeleteCustomerModal(false);
                    }}
                    title="Delete Customer"
                    message="This will delete the customer and all related data, are you sure you want to do this?"
                />
            )}
        </>
    );

    const columns = [
        { title: "Customer Name", field: "name", width: "20%" },
        { title: "Contact", field: "contactName", width: "20%" },
        { title: "Contact email", field: "contactEmail", width: "20%" },
        { title: "Contact number", field: "contactNumber", width: "10%" },
        { title: "No. of users", field: "numberOfUsers", width: "5%" },
        { title: "Last amended", field: "lastUpdatedDate", width: "10%" },
        { title: "Date added", field: "createdDate", width: "10%" },
    ];

    const actions = [
        (rowData: AdminContentLiteTableDataModel) => ({
            icon: () => (
                <Box>
                    <DeleteSVG />
                </Box>
            ),
            tooltip: "Delete Customer",
            onClick: () => {
                setCustomerIdToDelete(rowData.id);
                setDeleteCustomerModal(true);
            },
        }),
    ];

    return useObserver(() => (
        <>
            <Box style={{ padding: "0", margin: 0, width: "100%" }}>
                <AdminSecondaryToolbar>
                    <ToolbarTitle>Customers</ToolbarTitle>
                    <HHButton onClick={() => setNewCustomerModal(true)} text="Add new customer" width="304px" />
                </AdminSecondaryToolbar>
                <Box display="flex" width="100%">
                    <TablePageContent>
                        <HHTable
                            onSearchChange={(query: any) => {
                                viewModel.loadData(query);
                            }}
                            columns={columns}
                            options={{
                                actionsColumnIndex: -1,
                            }}
                            data={viewModel.getTableData}
                            components={{
                                Toolbar: (props: any) => (
                                    <>
                                        <ToolbarContainer>
                                            <SearchSummary data={viewModel.getTableData} resultType="customer" />
                                            <MTableToolbar {...props} />
                                        </ToolbarContainer>
                                    </>
                                ),
                            }}
                            onRowClick={(event: any, rowData: { id: string }, togglePanel: any) => {
                                if (!rowData) return;
                                getHistory().push(AppUrls.Client.Admin.CustomerUsers.replace(":customerId?", rowData.id));
                            }}
                            actions={actions}
                            title=""
                            isLoading={viewModel.IsLoading}
                            localization={{
                                header: {
                                    actions: "Delete",
                                },
                            }}
                        />
                    </TablePageContent>
                </Box>
            </Box>
            {modals()}
        </>
    ));
};

import * as MobX from "mobx";

import { FieldType, HttpClient, sortByString, ApiResult, ViewModelBase, Validation } from "@shoothill/core";
import { GenericIncludeDeleted, LoginModel, RoleModel } from "../../../Globals/Models";
import { UserModel } from "Views/Admin/Users/UserModel";
import { action, computed, makeObservable, observable, runInAction } from "mobx";

import { AppUrls } from "AppUrls";
import { UserStore } from "Globals/Stores/Domain";
import { container } from "tsyringe";
import { RoleStore } from "Globals/Stores/Domain/Admin";
import { IKeyState } from "Globals/Views/Primitives/EditText";
import { ICommandAsync, RelayCommandAsync } from "Globals/Commands";

export default class UserViewModel extends ViewModelBase<UserModel> {
    private userStore = container.resolve(UserStore);
    private roleStore = container.resolve(RoleStore);
    private httpClient = container.resolve(HttpClient);

    public userCount: number = 0;
    public ascOrder = true;
    public Valid: boolean = false;
    public resetLoginAttemptsError = "";
    public isTableLoading: boolean = false;

    constructor() {
        super(new UserModel(""));
        makeObservable(this, {
            userCount: observable,
            ascOrder: observable,
            Valid: observable,
            resetLoginAttemptsError: observable,
            isTableLoading: observable,
            set: action,
            loadUsersAsync: action,
            setDeleted: action,
            getUsers: computed,
            setIsTableLoading: action,
            getUserCount: computed,
            getIsLoadingData: computed,
            setOrderAsc: action,
            getOrderAsc: action,
            resetFailedLoginAttempts: action,
        });
    }
    public updateUserNameCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("email", value, keyState);
    });
    public updateFirstNameCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("firstName", value, keyState);
    });
    public updateLastNameCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("lastName", value, keyState);
    });
    public updatePasswordCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("password", value, keyState);
    });
    public updateSelectRolesCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("userRoles", value, keyState);
    });
    private async updateField(fieldName: keyof FieldType<UserModel>, value: any, keyState: IKeyState) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    public setUser(user: UserModel, newUser: boolean) {
        this.setValue("id", newUser ? "" : user.id);
        this.setValue("firstName", newUser ? "" : user.firstName);
        this.setValue("lastName", newUser ? "" : user.lastName);
        this.setValue("email", newUser ? "" : user.email);
        this.setValue("password", newUser ? "" : user.password);
    }

    public get(fieldName: any) {
        return this.getValue(fieldName);
    }

    public set(fieldName: any, value: string | number | boolean | Date) {
        this.setValue(fieldName, value);
    }

    public async loadUsersAsync(): Promise<ApiResult<UserModel[]>> {
        const includeGenericDeleted: GenericIncludeDeleted = {
            includeDeleted: true,
        };
        const apiResult = await this.httpClient.Post<UserModel[]>("/api/user/getall", includeGenericDeleted);

        if (apiResult.wasSuccessful) {
            this.userStore.setUsers(apiResult.payload);
            MobX.runInAction(() => (this.userCount = this.userStore.getUserCount));
        }
        return apiResult;
    }

    public async getUserRoles(): Promise<ApiResult<RoleModel[]>> {
        const apiResult = await this.httpClient.Post<RoleModel[]>("/api/user/GetUserRoles", { id: this.getValue("id") });
        if (apiResult.wasSuccessful) {
            apiResult.payload.forEach((role) => {
                MobX.runInAction(() => {
                    const existingRole = this.roleStore.getRoles.find((r) => r.id === role.id);

                    if (existingRole) {
                        this.model.userRoles.push(existingRole);
                    }
                });
            });
        }
        return apiResult;
    }

    public async resetFailedLoginAttempts(): Promise<void> {
        this.IsLoading = true;
        const apiResult = await this.httpClient.Post<any>(AppUrls.Server.Admin.ResetFailedLoginAttemptsCount, {
            id: this.getValue("id"),
        });
        if (apiResult.wasSuccessful) {
            MobX.runInAction(() => (this.resetLoginAttemptsError = ""));
        } else {
            MobX.runInAction(() => {
                this.IsErrored = true;
                this.resetLoginAttemptsError = "Unknown Error resetting Failed Login Attempts Count";
            });
        }
        this.IsLoading = false;
    }

    public setDeleted = (userId: string) => {
        let user = this.userStore.getUsers.find((a) => a.id === userId);
        user!.isDeleted = true;
        //TODO: Send back to server and isdeleted flag in the database
    };

    get getUsers(): UserModel[] {
        let users = this.userStore.getUsers;
        users.sort((a: UserModel, b: UserModel) => {
            if (this.ascOrder) {
                return sortByString(a.firstName, b.firstName);
            } else {
                return sortByString(b.firstName, a.firstName);
            }
        });

        return users.filter((a) => !a.isDeleted);
    }

    public setIsTableLoading = (state: boolean) => {
        this.isTableLoading = state;
    };

    public getUser = (id: string) => {
        if (id) {
            return this.userStore.getUsers.find((u) => u.id === id);
        } else {
            return new UserModel("");
        }
    };

    get getUserCount(): number {
        return this.userCount;
    }

    get getIsLoadingData(): boolean {
        return this.userStore.getIsLoadingData;
    }

    public setOrderAsc() {
        this.ascOrder = !this.ascOrder;
    }

    get getOrderAsc(): boolean {
        return this.ascOrder;
    }

    public async postUserDetailAsync() {
        const apiResult = await this.httpClient.Post<UserModel>(`/api/user/Upsert`, this.getModel);
        if (apiResult.wasSuccessful) {
            let existingUser = this.userStore.getUsers.find((u) => u.id === this.getValue("id"));
            if (existingUser) {
                const index = this.userStore.getUsers.indexOf(existingUser, 0);
                if (index > -1) {
                    this.userStore.getUsers.splice(index, 1);
                }
            }
            apiResult.payload.password = "";
            const newUser = new UserModel(apiResult.payload.id);
            newUser.toModel(apiResult.payload);
            this.userStore.getUsers.push(newUser);
        }
        return apiResult.payload;
    }
}

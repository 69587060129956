import { PaletteColorOptions, Theme } from "@mui/material";
import { createTheme, Palette, PaletteColor } from "@mui/material/styles";
import createBreakpoints from "@mui/system/createTheme/createBreakpoints";
import { colors } from "@mui/material";
import { TypeText } from "@mui/material/styles/createPalette";
import React from "react";
import { singleton } from "tsyringe";
import { flatMap } from "lodash-es";

export const pxToRem = (value: number) => {
    return `${value / 16}rem`;
};
const getTheme = (): Theme => {
    return createTheme(appTheme);
};
//Define your custom breakpoints here or accept the default values which are the standard common breakpoints
let customBreakpointValues = {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
};

export const customBreakpoints = createBreakpoints({ values: customBreakpointValues });

declare module "@mui/material/styles" {
    interface TypographyVariants {
        poster: React.CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        poster?: React.CSSProperties;
    }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides {
        poster: true;
    }
}

const appTheme = createTheme({
    //customBreakpointValues,
    ///**************
    ///Set up your spacing here according to Daves designs
    ///Dave tends to do multiples of 10. so p={1} will be 10px padding. p(5) will be 50px padding
    ///The code below will shrink the padding on small devices
    ///**************
    spacing: (value: number) => {
        let spacing = 10; //Default spacing on desktop
        const screenWidth = document.body.clientWidth;
        if (screenWidth <= appTheme.breakpoints.values["md"]) spacing /= 2;
        if (screenWidth > appTheme.breakpoints.values["md"] && screenWidth <= appTheme.breakpoints.values["lg"]) spacing /= 1.5;
        if (screenWidth > appTheme.breakpoints.values["lg"]) spacing /= 1;
        return value * spacing;
    },
    ///**************
    ///Set up your text sizes here to match Dave's designs
    ///You can also set up the values text should be on mobile devices if different
    ///**************

    typography: {
        fontFamily: ["Manrope", "sans-serif"].join(","),
        h1: {
            fontSize: pxToRem(63),
            [customBreakpoints.down("sm")]: {
                fontSize: pxToRem(12),
            },
        },
        h2: {
            fontSize: pxToRem(60),
            [customBreakpoints.down("sm")]: {
                fontSize: pxToRem(38),
            },
        },
        h3: {
            fontSize: pxToRem(48),
            [customBreakpoints.down("sm")]: {
                fontSize: pxToRem(32),
            },
        },
        h4: {
            fontSize: pxToRem(32),
            [customBreakpoints.down("sm")]: {
                fontSize: pxToRem(24),
            },
        },
        h5: {
            fontFamily: ["Manrope", "sans-serif"].join(","),
            fontWeight: "bold",
            fontSize: pxToRem(21),
            [customBreakpoints.down("sm")]: {
                fontSize: pxToRem(18),
            },
        },
        h6: {
            fontSize: pxToRem(18),
            [customBreakpoints.down("sm")]: {
                fontSize: pxToRem(16),
            },
        },
        subtitle1: {
            fontSize: pxToRem(16),
            [customBreakpoints.down("sm")]: {
                fontSize: pxToRem(14),
            },
        },
        subtitle2: {
            fontSize: pxToRem(14),
            [customBreakpoints.down("sm")]: {
                fontSize: pxToRem(10),
            },
        },
        body1: {
            fontSize: pxToRem(16),
            [customBreakpoints.down("sm")]: {
                fontSize: pxToRem(14),
            },
        },
        body2: {
            fontSize: pxToRem(14),
            fontWeight: "bold",
            [customBreakpoints.down("sm")]: {
                fontSize: pxToRem(10),
            },
        },
        button: {
            fontSize: pxToRem(16),
            [customBreakpoints.down("sm")]: {
                fontSize: pxToRem(14),
            },
        },
        caption: {
            fontSize: pxToRem(14),
            [customBreakpoints.down("sm")]: {
                fontSize: pxToRem(10),
            },
        },
        poster: {
            color: "red",
        },
    },
    ///***************
    ///Set up your material ui overrides here or in the index.scss file. Scss will probably be faster and easier overall
    ///***************

    components: {
        // Name of the component
        MuiButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    fontSize: "1rem",
                },
            },
        },
    },
});

///***************
///Add your Custom Colours below for typescript checking
///Probably only need to add to interface Palette as I have done here with accent
///***************
declare module "@mui/material/styles/createPalette" {
    interface IconPaletteColorOptions {
        pdf?: string;
        csv?: string;
    }
    interface IconPaletteColor {
        pdf: string;
        csv: string;
    }
    interface PaletteOptions {
        accent?: PaletteColorOptions;
        icon?: IconPaletteColorOptions;
    }
    ///***************
    ///Add your Custom Colours below for typescript
    ///***************
    interface Palette {
        tableHeader: PaletteColor;
        healthAppyBlue: PaletteColor;
        purpleAccent: PaletteColor;
        customText: TypeText;
        icon: IconPaletteColor;
    }
}

let customPalette = {
    ///****************
    ///Customise the colours below to match your the designs
    ///****************
    common: { black: "#000", white: "#fff" },
    background: { paper: "#fff", default: "#fafafa" },
    primary: { light: "#6d6d6d", dark: "#1b1b1b", main: "#424242", contrastText: "#fff" },
    secondary: { light: "#FFFFFF", main: "#FCFCFC", dark: "#EDEDED", contrastText: "#000" },
    error: { light: "#e57373", main: "#f44336", dark: "#d32f2f", contrastText: "#fff" },
    warning: { light: "#ffb74d", main: "#FF0000", dark: "#f57c00", contrastText: "rgba(0, 0, 0, 0.87)" },
    info: { light: "#EFEFEF", main: "#D4D4D4", dark: "#1976d2", contrastText: "#fff" },
    success: { light: "#81c784", main: "#4caf50", dark: "#388e3c", contrastText: "rgba(0, 0, 0, 0.87)" },
    text: {
        primary: "#040505",
        secondary: "rgba(0,0,0, 0.54)",
        disabled: "#7F7F7F",

        hint: "rgba(0,0,0, 0.38)",
    },
    divider: "rgba(0,0,0,0.12)",
    ///***************
    ///Add your Custom Colours below and in the declare module above this code block
    ///***************
    tableHeader: { main: "#EFEFEF" },
    healthAppyBlue: { main: "#1BA4EB", light: "#D6F1FF", dark: "#0095DA", accent: "#41B8ED" },
    purpleAccent: { light: colors.purple[300], main: colors.purple[500], dark: colors.purple[700], contrastText: "#ffb74d" }, //This is a custom colour

    customText: {
        primary: "#191919",
        secondary: "#222222",
        disabled: "hsl(0, 0%, 46%)",
        hint: "rgba(255,255,255, 0.38)",
    },
    icon: { csv: "rgba(255,255,255, 0.87)", pdf: "rgba(255,255,255, 0.87)" },
} as any as Palette;

export let theme = getTheme();
theme.breakpoints = customBreakpoints;
theme.palette = { ...theme.palette, ...customPalette };
export const palette = theme.palette;
window.theme = theme;

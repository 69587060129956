import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { ProfileQuestionsViewModel } from "./ProfileQuestionsViewModel";

export class CategoryModel extends ModelBase<CategoryModel> {
    public id: string = "";
    public name: string = "";
    public description: string = "";
    public isDeleted: boolean = false;
    public ordinal: number = 0;
    public colour: string = "";
    public profileQuestions: ProfileQuestionsViewModel[] = [];

    constructor(init?: CategoryModel) {
        super();
        if (init) {
            this.toModel(init);
            this.profileQuestions = [];
            init.profileQuestions.forEach((item, index) => {
                let pqvm = new ProfileQuestionsViewModel(item);
                this.profileQuestions.push(pqvm);
            });
        }
        makeObservable(this, {
            name: observable,
            profileQuestions: observable,
        });
    }
}

import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

export class ProfileQuestionAnswersModel extends ModelBase<ProfileQuestionAnswersModel> {
    public answerText: string = "";
    public orderPosition: number = 0;
    public score: number = 0;

    constructor(init?: ProfileQuestionAnswersModel) {
        super();
        if (init) this.toModel(init);

        makeObservable(this, {
            answerText: observable,
            orderPosition: observable,
            score: observable,
        });
    }
}

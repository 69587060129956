import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { CategoryModel } from "./CategoryModel";
import { CategoryViewModel } from "./CategoryViewModel";

export class ProfilesModel extends ModelBase<ProfilesModel> {
    public categories: CategoryViewModel[] = [];

    constructor() {
        super();
        makeObservable(this, {});
    }
}

import { ModelBase, ViewModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { CategoryModel } from "./CategoryModel";
import { ProfileQuestionsModel } from "./ProfileQuestionsModel";
import { ProfileQuestionAnswersModel } from "./ProfileQuestionAnswersModel";

export class ProfileQuestionAnswersViewModel extends ViewModelBase<ProfileQuestionAnswersModel> {
    constructor(init?: any) {
        super(new ProfileQuestionAnswersModel(init));
        makeObservable(this, {});
    }
}

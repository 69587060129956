import NewContentViewModel from "../../Globals/ViewModels/Admin/NewContentViewModel";
import { useEffect, useState } from "react";
import { theme } from "../../Globals/Styles/AppTheme";

export const useColorTheme = (label: string, type: "accent" | "main") => {
    // this function pulls an array of objects {id,name,colors} found in the view model, finds the desired name and returns the corresponding colour

    const [viewModel] = useState(new NewContentViewModel());
    const [colorTheme, setColorTheme] = useState("transparent");

    useEffect(() => {
        const getColor = () => {
            let category = viewModel.getCategoryColors.find((cat) => cat.name === label);
            if (category === undefined) {
                if (type == "accent") {
                    return "transparent";
                } else {
                    return theme.palette.info.light;
                }
            } else {
                if (type === "accent") {
                    return category.accentColour;
                } else {
                    return category.colour;
                }
            }
        };
        setColorTheme(getColor());
    }, [label]);

    return colorTheme;
};

import { Box, Button, Modal } from "@mui/material";
import styled from "@emotion/styled";
import { theme } from "../../../Globals/Styles/AppTheme";
import NewCustomerUserViewModel from "../../../Globals/ViewModels/Admin/NewCustomerUserViewModel";
import { EditText, EditText2, EditText3, Select, GoogleAddress, CheckBox } from "../../../Globals";
import { CustomDatePicker } from "Globals/Views/Primitives/CustomDatePicker";
import React, { useEffect, useContext, useState, useMemo } from "react";
import { observer, useObserver } from "mobx-react-lite";
import Typography from "@mui/material/Typography";
import { AutoGrid, EditNumber } from "../../../Globals";
import { DeleteSVG } from "../../../Globals/Views/IconSVGs/DeleteSVG";
import { ButtonsContainer, ModalBase, ModalHeader } from "./ModalComponents";
import { isNullOrEmpty } from "@shoothill/core";

interface IProps {
    isActive: boolean;
    confirmClose(confirm: boolean): void;
    onCancel(): void;
    customerId: string;
    customerUserId: string | null;
}

export const NewCustomerUserModal: React.FC<IProps> = observer((props: IProps) => {
    const viewModel = useMemo(() => new NewCustomerUserViewModel(props.customerUserId), []);
    const [errorMessage, setErrorMessage] = useState("");
    //const [dob, setDob] = React.useState(new Date());
    /*useEffect(() => {
        viewModel.setDOB("dateOfBirth", dob);
    });

    const handleDOB = (value: Date) => {
        setDob(value);
    };*/

    return (
        <>
            <ModalBase open={props.isActive} onClose={() => props.onCancel()}>
                <div>
                    <ModalHeader color={theme.palette.healthAppyBlue.main} title={props.customerUserId ? "Edit User" : "Add user"} />
                    <div className="modalclosebtn" onClick={() => props.onCancel()}>
                        <DeleteSVG className="file-delete" />
                    </div>
                    <Box className="modalbody">
                        <Box className="content">
                            {errorMessage !== "" && (
                                <Typography variant="caption" style={{ color: ` ${theme.palette.warning.main}` }}>
                                    {errorMessage}
                                </Typography>
                            )}
                            <AutoGrid columnGap={"30px"} dc={"1fr 1fr"}>
                                <EditText
                                    displayName="First name"
                                    //execute={viewModel.updateFirstNameCommand.execute}
                                    command={viewModel.updateFirstNameCommand}
                                    value={() => viewModel.getValue("firstName")}
                                    validationMessage={() => viewModel.getError("firstName")}
                                    type="text"
                                />
                                <EditText
                                    displayName="Last name"
                                    command={viewModel.updateLastNameCommand}
                                    value={() => viewModel.getValue("lastName")}
                                    validationMessage={() => viewModel.getError("lastName")}
                                    type="text"
                                />
                            </AutoGrid>
                            <AutoGrid columnGap={"30px"} dc={"1fr 1fr"}>
                                <EditText
                                    displayName="Contact email"
                                    command={viewModel.updateContactEmailCommand}
                                    value={() => viewModel.getValue("contactEmail")}
                                    validationMessage={() => viewModel.getError("contactEmail")}
                                    type="email"
                                />
                                <EditText
                                    displayName="Contact number"
                                    command={viewModel.updateContactNumberCommand}
                                    value={() => viewModel.getValue("contactNumber")}
                                    validationMessage={() => viewModel.getError("contactNumber")}
                                    type="number"
                                />
                            </AutoGrid>
                            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "30px", alignItems: "center" }}>
                                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "30px", alignItems: "center" }}>
                                    <EditText
                                        displayName="Height (Meters)"
                                        command={viewModel.updateHeightCommand}
                                        value={() => viewModel.getValue("height")}
                                        validationMessage={() => viewModel.getError("height")}
                                        allowDecimals={true}
                                        type="number"
                                    />
                                    <EditText
                                        displayName="Weight (Kg)"
                                        command={viewModel.updateWeightCommand}
                                        value={() => viewModel.getValue("weight")}
                                        allowDecimals={false}
                                        validationMessage={() => viewModel.getError("weight")}
                                        type="number"
                                    />
                                </div>
                                <div style={{ marginBottom: "10px" }}>
                                    <CustomDatePicker
                                        label="D.O.B."
                                        //viewModel={viewModel}
                                        value={() => viewModel.getValue("dateOfBirth")}
                                        placeholder="DD/MM/YYYY"
                                        //fieldName={"dateOfBirth"}
                                        command={viewModel.updateDateOfBirthCommand}
                                        /*onChange={(newValue: Date) => {
                                            viewModel.updateDateOfBirthCommand.execute(newValue);
                                        }}*/
                                    />
                                </div>
                            </div>
                            <AutoGrid columnGap={"30px"} dc={"1fr 1fr"}>
                                <GoogleAddress
                                    id="googleAddress"
                                    displayName="Location"
                                    command={viewModel.updateAddressCommand}
                                    value={() => viewModel.getValue("address")}
                                    placeholder="Your postcode e.g. B1 5TG"
                                    validationMessage={() => viewModel.getError("address")}
                                    setLocation={viewModel.setLocation}
                                />
                                <Select
                                    displayName={"Ethnicity"}
                                    options={viewModel.getEthnicityOptions}
                                    placeholder={"Please select..."}
                                    command={viewModel.updateEthnicityCommand}
                                    value={() => viewModel.getValue("ethnicityId")}
                                    validationMessage={() => viewModel.getError("ethnicityId")}
                                    color={"transparent"}
                                />
                            </AutoGrid>

                            <Select
                                displayName={"Role"}
                                options={[
                                    { label: "User", value: "user" },
                                    { label: "Admin", value: "admin" },
                                    { label: "Content Provider", value: "contentprovider" },
                                ]}
                                command={viewModel.updateRoleIdCommand}
                                value={() => viewModel.getValue("roleId")}
                                validationMessage={() => viewModel.getError("roleId")}
                                color={"transparent"}
                            />

                            <Box>
                                <Button disabled={viewModel.httpClient.IsLoading} onClick={() => viewModel.resetPassword()} variant={"contained"}>
                                    Reset Password
                                </Button>
                            </Box>
                        </Box>
                        <ButtonsContainer color={theme.palette.healthAppyBlue.main}>
                            <Button
                                disableElevation
                                variant="contained"
                                disabled={viewModel.httpClient.IsLoading}
                                onClick={async () => {
                                    let close = false;
                                    if (!props.customerUserId) {
                                        close = await viewModel.createNewCustomerUser(props.customerId);
                                    } else {
                                        close = await viewModel.upsertCustomerUser(props.customerId);
                                    }
                                    if (close) {
                                        props.confirmClose(true);
                                    }
                                }}
                                style={{ backgroundColor: `${theme.palette.healthAppyBlue.main}`, marginRight: "25px" }}
                            >
                                {props.customerUserId ? "Save" : "Create"}
                            </Button>

                            <Button disableElevation variant="contained" onClick={() => props.onCancel()} disabled={viewModel.httpClient.IsLoading}>
                                Cancel
                            </Button>
                        </ButtonsContainer>
                    </Box>
                </div>
            </ModalBase>
        </>
    );
});

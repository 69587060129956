import React from "react";
import styled from "@emotion/styled";
import { EditText } from "../../Globals/Views/Primitives/EditText";
import { theme } from "./AppTheme";

export const CustomInput: any = styled(EditText)`
    .MuiInputBase-root {
        font-size: 12px !important;
        font-weight: normal !important;
        white-space: normal !important;
        border-radius: 0px !important;
        height: 28px !important;
        width: 300px !important;
    }
    .MuiInputLabel-root {
        position: relative !important;
        font-size: 14px !important;
        font-weight: bold !important;
        left: -12px !important;
        top: -20px !important;
    }
    .MuiFormHelperText-root {
        position: relative !important;
        font-size: 12px !important;
        left: -12px !important;
    }
`;

import { ModelBase } from "@shoothill/core";
import { RoleModel } from "Globals/Models";
import { makeObservable, observable } from "mobx";

// App

export class UserModel extends ModelBase<UserModel> {
    public id: string = "";
    public firstName: string = "";
    public lastName: string = "";
    public email: string = "";
    public password: string = "";
    public role: string = "";
    public concurrencyToken: string = "";
    public isDeleted: boolean = false;
    public lastLoginDate: Date | null = null;
    public userRoles = observable<RoleModel>([]);

    constructor(id: string) {
        super();
        this.id = id;
        makeObservable(this, {
            firstName: observable,
            lastName: observable,
            email: observable,
            password: observable,
            role: observable,
            concurrencyToken: observable,
            isDeleted: observable,
            lastLoginDate: observable,
            userRoles: observable,
        });
    }
}

// Libs
import { ModelBase } from "@shoothill/core";
import { Validator } from "../../../../Globals/Validation";
import { makeObservable, observable } from "mobx";

export class NewProfileQuestionAnswerModel extends ModelBase<NewProfileQuestionAnswerModel> {
    public id: string | null = null;
    public answerText: string = "";
    public answerScore: number = 0;
    public answerOrderPosition: number = 0;
    public answerId: string = "";
    public isDeleted: boolean = false;

    constructor() {
        super();
        makeObservable(this, {
            id: observable,
            answerText: observable,
            answerScore: observable,
            answerOrderPosition: observable,
            answerId: observable,
            isDeleted: observable,
        });
    }
}
export class NewProfileQuestionAnswerValidator extends Validator<NewProfileQuestionAnswerModel> {
    constructor() {
        super();
        // this.ruleFor("score").notNull().greaterThanOrEqualTo(0).lessThanOrEqualTo(100).withMessage("Value can only be a whole number");
        this.ruleFor("answerText").notNull().notEmpty().withMessage("Please enter some text before adding another answer.");
    }
}

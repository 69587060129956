import React from "react";
import { ForgotPasswordView } from "./Views/ForgotPassword/ForgotPasswordView";
import { ForgotPasswordSuccessView } from "./Views/ForgotPassword/ForgotPasswordSuccessView";
import { SamplesView } from "./Views/Samples/SamplesView";
import { LoginView } from "./Views/Login/LoginView";
import { RegisterView } from "./Views/Register/RegisterView";
import { ResetPasswordView } from "./Views/ResetPassword/ResetPasswordView";
import { SessionExpiredView } from "./Views/SessionExpired/SessionExpiredView";
import { LogoutView } from "Views/Logout/LogoutView";
import { Error } from "Globals/Views/Error";
import { AdminRoutes } from "./Views/Admin/AdminRoutes";
import { PrivacyPolicyView } from "./Views/Legal/PrivacyPolicy/PrivacyPolicyView";
import { TermsAndConditions } from "./Views/Legal/Terms/TermsAndConditions";
import { HomeView } from "./Views/Home/HomeView";
import { LayoutNoHeader } from "Globals/Views/Layouts/LayoutNoHeader";
import { RouteWithLayout as Route } from "./Globals/Views/RouteWithLayout";
import { DefaultLayoutNoFooter } from "Globals/Views/Layouts/DefaultLayoutNoFooter";
import { CommonInjects } from "./Globals/CommonInjects";
import { AppUrls } from "./AppUrls";
import { LogViewer } from "Globals/Views/LogViewer";
import { TestPage } from "./Examples/TestPage";
import { ProfilesScreen } from "./Views/Profiles/ProfilesScreen";
import { container } from "tsyringe";
import { AccountStore } from "Globals/Stores/Domain";
import { Redirect, Route as ReactRouterDomRoute, Switch } from "react-router-dom";
import { Box } from "@mui/material";
import { NoAccess } from "./Views/Login/NoAccess";

export const AppRoutes: React.FC = () => {
    const accountStore = container.resolve(AccountStore);

    return (
        <>
            {/*
            I have added a component that will now accept a different layout per route.
            This line
                import { RouteWithLayout as Route } from "./Core/Components/RouteWithLayout";
            renames the usual route to use the new RouteWithLayout component. The default layout is called DefaultLayout in the Layout folder
            */}
            <ReactRouterDomRoute
                exact
                path="/"
                render={() => {
                    if (accountStore.IsLoggedIn) {
                        if (accountStore.isInRole("admin")) {
                            return <Redirect to={AppUrls.Client.Admin.AdminHome} />;
                        } else if (accountStore.isInRole("contentprovider")) {
                            {
                                return <Redirect to={AppUrls.Client.Admin.Content} />;
                            }
                        } else {
                            return <Redirect to={AppUrls.Client.Account.NoAccess} />;
                        }
                    }
                    return <Redirect to="/login" />;
                    /*return accountStore.IsLoggedIn ? (
                        accountStore.isInRole("admin") ? (
                            <Redirect to={AppUrls.Client.Admin.AdminHome} />
                        ) : (
                            <Redirect to={AppUrls.Client.Account.NoAccess} />
                        )
                    ) : (
                        <Redirect to="/login" />
                    );*/
                }}
            />
            <Route exact path={AppUrls.Client.Home} component={HomeView} />
            <Route exact path={AppUrls.Client.Samples} layout={DefaultLayoutNoFooter} component={SamplesView} />
            <Route exact path={AppUrls.Client.Account.Logout} component={LogoutView} />
            <Route exact path={AppUrls.Client.Account.ForgotPassword} layout={LayoutNoHeader} component={ForgotPasswordView} />
            <Route exact path={AppUrls.Client.Account.ForgotPasswordSuccess} layout={LayoutNoHeader} component={ForgotPasswordSuccessView} />
            <Route exact path={AppUrls.Client.Account.ResetPassword} layout={LayoutNoHeader} component={ResetPasswordView} />
            <Route exact path={AppUrls.Client.Account.Register} component={RegisterView} />
            <Route exact path={AppUrls.Client.Account.NoAccess} component={NoAccess} layout={LayoutNoHeader} />
            <Route exact path={AppUrls.Client.Account.SessionExpired} component={SessionExpiredView} />
            <Route exact path={AppUrls.Client.Admin.LogViewer} component={LogViewer} />
            <Route exact path={AppUrls.Client.Error} component={Error} />
            <Route exact path={AppUrls.Client.PrivacyPolicy} component={PrivacyPolicyView} />
            <Route exact path={AppUrls.Client.Terms} component={TermsAndConditions} />
            <Route exact path={AppUrls.Client.Test} component={TestPage} />
            <Route exact path={AppUrls.Client.Account.Login} layout={LayoutNoHeader} component={LoginView} />
            <Route exact path={"/profiles"} component={ProfilesScreen} />
            {/*<Route exact path={"/native"} layout={LayoutNoHeader} component={ReactNative} />*/}
            {/*<Route exact path={"native"} layout={LayoutNoHeader} component={TestNative2} />*/}
            {/*<Route component={<Box>404</Box>}/>*/}
            <AdminRoutes />

            <CommonInjects />
        </>
    );
};

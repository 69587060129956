import React, { useContext, useState } from "react";
import styled from "@emotion/styled";
import { Box, Button, TextField } from "@mui/material";
import { useObserver } from "mobx-react-lite";

//custom

import { AdminCustomerBillingContainer } from "./AdminCustomer.styles";
import { theme } from "../../../Globals/Styles/AppTheme";
import { HHButton } from "Globals/Components/General/Buttons/HHButton";

const CustomerDetails = styled(Box)`
    height: 100px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    gap: 30px;
`;

interface ICustomHrProps {
    color: string;
    type: string;
}

const CustomHr = styled.div<ICustomHrProps>`
    height: 1px;
    width: 100%;
    border-bottom: 1px ${(props) => props.type} ${(props) => props.color};
`;

const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 25px;
`;

const ButtonGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 375px;
`;

export const AdminCustomerBillingView: React.FC = () => {
    return useObserver(() => (
        <>
            <Box style={{ backgroundColor: "white" }}>
                <AdminCustomerBillingContainer>
                    <CustomerDetails component="form">
                        {/* dummy content */}
                        <TextField id="outlined-basic" label="Outlined" variant="outlined" />
                        <TextField id="outlined-basic" label="Outlined" variant="outlined" />
                        <TextField id="outlined-basic" label="Outlined" variant="outlined" />
                        <TextField id="outlined-basic" label="Outlined" variant="outlined" />
                    </CustomerDetails>
                    <CustomHr color={theme.palette.text.disabled} type="dashed" />
                    <Box height={400}>
                        {/* Billing info goes here. Not received yet
                         */}
                    </Box>
                    <CustomHr color={theme.palette.healthAppyBlue.main} type="solid" />
                    <ButtonContainer>
                        <ButtonGroup>
                            <HHButton width="173px" text="Save" />
                            <HHButton width="173px" text="Cancel" color={theme.palette.info.main} />
                        </ButtonGroup>
                    </ButtonContainer>
                </AdminCustomerBillingContainer>
            </Box>
        </>
    ));
};

import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { ApiResult, FieldType, HttpClient, ViewModelBase } from "@shoothill/core";
import { container } from "tsyringe";

// App
import { AppUrls } from "../../../AppUrls";
import { Customer } from "../../../Globals/Models/Customer";
import { CustomerUserLite } from "../../../Globals/Models/CustomerUserLite";
import { CustomerUserLiteAndRelated } from "../../../Globals/Models/CustomerUserLiteAndRelated";

export interface AdminCustomerUserLiteTableDataModel {
    id: string;
    userName: string;
    dateOfBirth: string;
    address: string;
    postcode: string;
    contactEmail: string;
    createdDate: string;
    lastUpdatedDate: string;
    isDeleted: boolean;
}

export default class AdminCustomerUsersListViewModel extends ViewModelBase {
    private httpClient = container.resolve(HttpClient);

    public customerUsersLite: CustomerUserLite[] = [];
    public customer: any = null;

    constructor(customerId: string) {
        super(null);
        makeObservable(this, {
            customerUsersLite: observable,
            loadData: action,
            getTableData: computed,
            getCustomerName: computed,
        });
        this.loadData("", customerId);
    }

    public async loadData(query: string, customerId: string): Promise<void> {
        this.setIsLoading(true);
        const path = AppUrls.Server.Admin.SearchAllCustomerUsers + "/?search=" + query + "&customerId=" + customerId;
        const apiResult = await this.httpClient.Get<CustomerUserLiteAndRelated>(path);
        if (apiResult.wasSuccessful) {
            runInAction(() => {
                this.customerUsersLite = apiResult.payload.customerUsersLite;
                this.customer = apiResult.payload.customer;
            });
        }
        this.setIsLoading(false);
    }

    public async deleteCustomerUser(id: string): Promise<void> {
        this.setIsLoading(true);
        const path = AppUrls.Server.Admin.DeleteCustomerUser;
        const apiResult = await this.httpClient.Delete(path, id);
        if (apiResult.wasSuccessful) {
            let customerUserLite = this.customerUsersLite.find((user) => user.id === id);
            runInAction(() => {
                if (customerUserLite !== undefined) {
                    this.customerUsersLite.splice(this.customerUsersLite.indexOf(customerUserLite), 1);
                }
            });
        }
        this.setIsLoading(false);
    }

    public get getTableData() {
        let tableData: AdminCustomerUserLiteTableDataModel[] = [];
        this.customerUsersLite.forEach((customerUserLite) => {
            tableData.push({
                id: customerUserLite.id ? customerUserLite.id : "",
                userName: customerUserLite.userName,
                dateOfBirth: new Date(customerUserLite.dateOfBirth).toLocaleDateString("en-GB"),
                address: customerUserLite.address,
                postcode: customerUserLite.postcode,
                contactEmail: customerUserLite.emailAddress,
                createdDate: new Date(customerUserLite.createdDate).toLocaleDateString("en-GB"),
                lastUpdatedDate: new Date(customerUserLite.lastUpdatedDate).toLocaleDateString("en-GB"),
                isDeleted: customerUserLite.isDeleted,
            });
        });
        return tableData;
    }

    public get getCustomerName() {
        if (this.customer) {
            return this.customer.name;
        }
        return "";
    }
}

import MaterialTable from "@material-table/core";
import styled from "@emotion/styled";

//custom
import { theme } from "../../Styles";
import { SearchIcon } from "../../../Content/assets/SearchIcon";
import React from "react";
import Checkbox from "@mui/material/Checkbox";

const CustomTable = styled(MaterialTable)`
    .MuiTable-root {
        width: 99%;
    }
    .MuiTableCell-root {
        padding: 0 14px !important;
    }
`;

export const DisplayCheckbox = styled(Checkbox)`
    .MuiSvgIcon-root {
        fill: #333 !important;
    }
`;

interface IHHTableProps {
    onSearchChange?: any;
    data?: any;
    columns?: any;
    components?: any;
    title?: string;
    options?: any;
    localization?: any;
    isLoading?: any;
    onRowClick?: any;
    icons?: any;
    actions?: any;
    style?: any;
}

export const HHTable = React.forwardRef((props: IHHTableProps, ref: any) => {
    const { onSearchChange, columns, data, components, options, localization, isLoading, onRowClick, actions, icons, style } = props;
    return (
        <React.Fragment>
            <CustomTable
                ref={ref}
                onSearchChange={onSearchChange}
                onRowClick={onRowClick}
                columns={columns}
                data={data}
                components={{ ...components }}
                title=""
                options={{
                    padding: "dense",
                    tableLayout: "fixed",
                    tableWidth: "90%",
                    headerStyle: {
                        border: "none",
                        height: 41,
                        fontWeight: 700,
                        paddingTop: 0,
                        paddingBottom: 0,
                        fontSize: "12px",
                        background: `${theme.palette.tableHeader.main}`,
                    },
                    rowStyle: {
                        fontSize: "12px",
                        height: 41,
                        paddingTop: 0,
                        paddingBottom: 0,
                    },
                    cellStyle: {
                        paddingTop: 0,
                        paddingBottom: 0,
                        height: 41,
                    },
                    actionsCellStyle: {
                        // display: "flex",
                        // alignItems: "center",
                        // justifyContent: "flex-end",
                        // width: "68px",
                    },
                    pageSize: 50,
                    pageSizeOptions: [10, 20, 50, 100],
                    emptyRowsWhenPaging: false,
                    actionsColumnIndex: -1,
                    debounceInterval: 800,
                    search: true,
                    searchFieldAlignment: "left",
                    searchFieldStyle: {
                        position: "relative",
                        borderRadius: "0px",
                        width: "608px",
                        height: "30px",
                        padding: "0px",
                        color: `${theme.palette.info.main}`,
                        fontSize: "12px",
                        fontWeight: "bold",
                        opacity: "1",
                    },
                    searchFieldVariant: "outlined",
                    ...options,
                }}
                icons={{
                    Search: () => (
                        <span style={{ position: "absolute", right: "10px", height: "100%", padding: "5px 0px", marginTop: "2px" }}>
                            <SearchIcon />
                        </span>
                    ),
                    ResetSearch: () => <span style={{ display: "none" }}>{""}</span>,
                    ...icons,
                }}
                style={{
                    ...style,
                }}
                actions={actions}
                localization={{
                    toolbar: {
                        searchPlaceholder: "Keyword search",
                    },
                    ...localization,
                }}
                isLoading={isLoading}
            />
        </React.Fragment>
    );
});

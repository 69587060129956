// Libraries
import { isEmptyOrWhitespace, isNullOrUndefined, KeyValuePair } from "@shoothill/core";
import { observer, useObserver } from "mobx-react-lite";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { theme } from "../../Styles/AppTheme";
import { Autocomplete, Checkbox, Chip, FormControl, FormLabel, ListItemText, MenuItem, Select, SelectChangeEvent, Typography, useAutocomplete } from "@mui/material";
import { ICommandAsync } from "../../Commands";

//custom

import { HHFormControl } from "./Select.styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ClassNames } from "@emotion/react";
import { createStyles } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import styled from "@emotion/styled";

// import DeleteIcon from "@mui/icons-material/Delete";

interface IProps {
    value: () => any;
    className?: string;
    displayName?: string;
    groupBy?: (option: any) => string;
    gutterBottom?: boolean;
    options: KeyValuePair[];
    placeholder?: string;
    validationMessage?: () => string;
    color?: string;
    multiple?: boolean;
    style?: React.CSSProperties;
    disabled?: boolean;
    command: ICommandAsync;
}

export const MultiSelect: React.FC<IProps> = observer((props: IProps) => {
    const color = props.color ? props.color : "inherit";
    const [isHovered, setIsHovered] = useState(false);
    // const getClasses = () => {
    //     return clsx({
    //         [props.className!]: !isEmptyOrWhitespace(props.className),
    //         ["select"]: true,
    //         ["selectGutterBottom"]: hasGutterBottom(),
    //     });
    // };

    const getId = (): string => {
        return "selectControlId";
    };

    const getPlaceholder = (): string => {
        return !isEmptyOrWhitespace(props.placeholder) ? props.placeholder! : "";
    };

    const getValidationMessage = (): string => {
        return isEmptyOrWhitespace(props.validationMessage?.() as string) ? "" : (props.validationMessage?.() as string);
    };

    const hasGutterBottom = (): boolean => {
        return isNullOrUndefined(props.gutterBottom) ? false : props.gutterBottom!;
    };

    const isDisabled = (): boolean => {
        return isNullOrUndefined(props.command.canExecute) ? false : !props.command.canExecute();
    };

    const hasError = (): boolean => {
        return !isEmptyOrWhitespace(props.validationMessage?.() as string);
    };

    const onChange = (event: SelectChangeEvent<string[]>, value: any): void => {
        if (props.command.execute) props.command.execute(event.target.value);
    };

    // const onChecked = (value: any): boolean => {
    //     let retval = props.value().find((a) => a == value) !== null;
    //     return retval;
    // };

    const handleValue = (): string[] => {
        let retVal: any;
        if (typeof props.value() === "object") {
            retVal = props.value().map((item: any) => item);
        } else retVal = props.value();
        return retVal;
    };

    const handleDelete = (chip: KeyValuePair) => {};

    const handleMultiple = (): boolean => {
        return props.multiple ? props.multiple : false;
    };

    const handleStyle = (): any => {
        return props.style ? { width: "375px", ...props.style } : { width: "375px" };
    };

    const CustomMenuProps = {
        PaperProps: {
            sx: {
                boxShadow: "none",
                border: `1px solid ${theme.palette.info.main}`,
                "& .MuiMenuItem-root": {
                    height: 28,

                    padding: "5px 8px",
                    ":first-of-type": {
                        background: "transparent",

                        ":hover": {
                            "& .MuiCheckbox-root": {
                                color: "white",
                            },
                            background: `${color === "transparent" ? theme.palette.info.main : color}`,
                            color: "white",
                        },
                    },
                    ":hover": {
                        background: `${color === "transparent" ? theme.palette.info.main : color}`,
                        color: "white",
                        "& .MuiCheckbox-root": {
                            color: "white",
                        },
                    },
                },
                ".MuiCheckbox-root": {
                    padding: 0,
                },

                ".Mui-checked": {
                    ":hover": {
                        color: "white",
                    },
                },

                ".MuiTypography-root": {
                    fontSize: "14px !important",
                },

                ".Mui-selected": {
                    background: "transparent !important",
                    "& .MuiCheckbox-root": {
                        color: `${color === "transparent" ? theme.palette.info.main : color}`,
                    },
                    ":hover": {
                        background: `${color === "transparent" ? theme.palette.info.main : color} !important`,
                        color: "white",
                    },
                },
            },
        },
    };

    const useStyles = makeStyles(
        createStyles({
            root: {
                zIndex: 1500,

                "& .MuiAutocomplete-listbox": {
                    "& :hover": {
                        background: `${color === "transparent" ? theme.palette.info.light : color}`,
                        color: `${color === "transparent" ? theme.palette.text.primary : theme.palette.common.white}`,
                    },
                },
            },
            helperText: {
                margin: 0,
                fontSize: "12px",
            },
            placeholder: {
                position: "absolute",
                top: 31,
                left: 10,
                zIndex: 1,
                fontWeight: "bold",
                fontSize: 15,
                color: `${isHovered && color !== "transparent" ? theme.palette.common.white : "#959595"}`,
            },
        }),
    );
    const classes = useStyles();

    return (
        <>
            <HHFormControl style={handleStyle()} customcolour={color}>
                <FormLabel>{props.displayName}</FormLabel>

                {/* MUI doesn't give a placeholder option so it's been faked here using React State */}
                {handleValue().length === 0 && <div className={classes.placeholder}>{getPlaceholder()}</div>}

                <Select
                    onMouseOver={(e) => {
                        setIsHovered(true);
                    }}
                    onMouseOut={(e) => {
                        setIsHovered(false);
                    }}
                    multiple={handleMultiple()}
                    style={{ position: "relative" }}
                    disabled={props.disabled}
                    onChange={onChange}
                    value={handleValue()}
                    renderValue={(selected: any[]): any => {
                        let retval: any = [];

                        if (selected && props.options) {
                            for (let item of selected) {
                                let a = props?.options?.find((a) => a.value == item)?.label;
                                retval.push(a);
                            }
                        }

                        if (retval.length === 1) {
                            return retval[0];
                        } else if (retval.length >= props.options?.length - 1) {
                            return "All items selected";
                        } else if (retval.length > 1) {
                            return retval.length + " items selected";
                        }
                    }}
                    error={hasError()}
                    IconComponent={(props) => <ExpandMoreIcon {...props} className={`material-icons ${props.className}`} />}
                    MenuProps={CustomMenuProps}
                >
                    {props.options.map((item: KeyValuePair) => (
                        <MenuItem key={item.label.toString()} value={item.value}>
                            <ListItemText primary={item.label} />
                            <Checkbox
                                checked={props.value().indexOf(item.value) > -1}
                                checkedIcon={<CheckCircleIcon />}
                                icon={<CircleOutlinedIcon />}
                                sx={{ color: `${color === "transparent" ? theme.palette.info.main : color}` }}
                            />
                        </MenuItem>
                    ))}
                </Select>
                <Typography variant="caption" style={{ color: `${theme.palette.error.main}`, fontSize: "12px" }}>
                    {getValidationMessage()}
                </Typography>
            </HHFormControl>
        </>
    );
});

export const DatePicker = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14.667" height="16" viewBox="0 0 14.667 16">
            <path
                id="Path_1763"
                data-name="Path 1763"
                d="M0,4V2a.668.668,0,0,1,.178-.489.668.668,0,0,1,.489-.178h2V0H4V1.333h6.667V0H12V1.333h2a.668.668,0,0,1,.489.178A.668.668,0,0,1,14.667,2V4ZM14.667,5.333v10a.515.515,0,0,1-.178.444A.637.637,0,0,1,14,16H.667a.714.714,0,0,1-.489-.222A.571.571,0,0,1,0,15.333v-10Zm-13.333,4H2.667V8H1.333Zm0,2.667H2.667V10.667H1.333Zm0,2.667H2.667V13.333H1.333ZM4,9.333H5.333V8H4ZM4,12H5.333V10.667H4Zm0,2.667H5.333V13.333H4ZM6.667,9.333H8V8H6.667Zm0,2.667H8V10.667H6.667Zm0,2.667H8V13.333H6.667ZM9.333,9.333h1.333V8H9.333Zm0,2.667h1.333V10.667H9.333Zm0,2.667h1.333V13.333H9.333ZM12,9.333h1.333V8H12ZM12,12h1.333V10.667H12Zm0,2.667h1.333V13.333H12Z"
                fill="#231f20"
            />
        </svg>
    );
};

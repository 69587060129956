import { CoreStore } from "@shoothill/core";
import { container, singleton } from "tsyringe";
import { AccountStore } from "./Domain/AccountStores";
import { DomainStores } from "./DomainStores";
import { GlobalHistory } from "../../index";

export class Stores {
    public CoreStore: CoreStore = new CoreStore();
    public AccountStore = container.resolve(AccountStore);

    constructor() {
        (window as any).stores = this;
    }

    public init = async () => {
        await this.AccountStore.init();
        this.CoreStore.Init(this.AccountStore.initialState.accountStatus.signalRAccessToken, this.AccountStore.initialState.appSettings.useFormLockConcurrency);
        this.CoreStore.SetHistory(GlobalHistory);
    };
}

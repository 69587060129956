// Libs
import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

export class RoleModel extends ModelBase<RoleModel> {
    public id: string = "";
    public name: string = "";

    constructor(id: string) {
        super();
        this.id = id;
        makeObservable(this, {
            name: observable,
        });
    }
}

import { Stores } from "./Globals/Stores";

export class GlobalAppUrls {
    public Client = {
        Home: "/",
        Samples: "/samples/:view?",
        Error: "/error",
        NotFound: "/notfound",
        PrivacyPolicy: "/privacy-policy",
        Terms: "/terms",
        Test: "/test",
        Account: {
            Login: "/login",
            Logout: "/logout",
            ForgotPassword: "/forgotpassword",
            ForgotPasswordSuccess: "/forgotpassword-success",
            ResetPassword: "/resetpassword",
            Register: "/register",
            NoAccess: "/noaccess",
            SessionExpired: "/sessionexpired",
        },
        Admin: {
            AdminHome: "/admin/customers",
            Customer: "/admin/customers/customer/:customerId?",
            CustomerUsers: "/admin/customers/customer/customerusers/:customerId?",
            Content: "/admin/content",
            AddContent: "/admin/content/addcontent/:contentId?",
            Profile: "/admin/profiles",
            AddProfile: "/admin/profiles/addprofile/:questionId?",
            FAQ: "/admin/faqs",
            LogViewer: "/admin/logviewer",
        },
    };

    public Server = {
        Account: {
            Login: "/api/account/login",
            Logout: "/api/account/logout",
            ResetPassword: {
                Reset: "/api/account/resetpassword",
                VerifyToken: "/api/account/resetpasswordtoken",
            },
            SetPassword: "/api/account/setpassword",
            ForgotPassword: "/api/account/forgotpassword",
            Register: "/api/account/register",
        },
        Admin: {
            ResetFailedLoginAttemptsCount: "/api/user/ResetLoginAttempts",
            SearchAllCustomers: "/api/customer/SearchAllCustomers",
            CreateNewCustomer: "/api/customer/Create",
            SearchAllCustomerUsers: "/api/customeruser/SearchAllCustomerUsers",
            CreateNewCustomerUser: "/api/customeruser/Create",
            DeleteCustomer: "/api/customer/DeleteCustomer",
            upsertCustomerUser: "/api/customeruser/Upsert",
            resetuserpassword: "/api/customeruser/resetpassword",
            DeleteCustomerUser: "/api/customeruser/SoftDeleteUserAndRelated",
            GetCustomerUser: "/api/customeruser/GetCustomerUserExtended",
            SearchAllContents: "/api/content/SearchAllContent",
            DeleteContent: "/api/content/DeleteContent",
            ChangeVisible: "/api/content/ChangeVisible",
            UpsertContent: "/api/content/UpsertContent",
            GetContentAndRelated: "/api/content/GetContentAndRelated",
            SearchAllProfiles: "/api/profile/SearchAllProfiles",
            DeleteProfileQuestion: "/api/profile/DeleteProfileQuestion",
            // TODO to be wired in the database
            CreateNewQuestionAnswer: "/api/profile/Create",
            GetQuestionAnswer: "/api/profile/GetProfileQuestion",
            SearchAllFAQs: "/api/faq/SearchAllFAQs",
            CreateNewFAQ: "/api/faq/Create",
            DeleteFAQ: "/api/faq/DeleteFAQ",
            GetProfileSummaryByCategoryId: "/api/profile/GetProfileSummaryByCategoryId",
            UpdateProfileSummary: "/api/profile/UpdateProfileSummary",
        },
        File: {
            UploadFile: "/api/upload/UploadFile",
            DownloadFile: "/api/upload/downloadfile",
        },
    };
}
export const AppUrls = new GlobalAppUrls();

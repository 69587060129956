import { Box, Button, TextField } from "@mui/material";
import { getHistory } from "@shoothill/core";
import { AppUrls } from "../../../AppUrls";
import { theme } from "../../../Globals/Styles/AppTheme";
import React, { useContext, useEffect, useState } from "react";

import AdminCustomerUsersListViewModel, { AdminCustomerUserLiteTableDataModel } from "../../../Globals/ViewModels/Admin/AdminCustomerUsersListViewModel";
import { NewCustomerUserModal } from "../Modals/NewCustomerUserModal";
import { useObserver } from "mobx-react-lite";
import { MTableToolbar } from "@material-table/core";
import Typography from "@mui/material/Typography";
import { useRouter } from "../../../Globals/Hooks/UseRouter";

//custom
import { TablePageContent } from "../../../Globals/Components/General/General.styles";
import { ToolbarContainer } from "../../../Globals/Components/Table/ToolbarContainer";

import { HHButton } from "Globals/Components/General/Buttons/HHButton";
import { HHTable } from "../../../Globals/Components/Table/HHTable";

import { DeleteModal } from "../Modals/DeleteModal";
import { DeleteSVG } from "Globals/Views/IconSVGs/DeleteSVG";
import { SearchSummary } from "Globals/Components/Table/SearchSummary";
import { isNull } from "lodash-es";

export interface MatchParams {
    customerId: string;
}

interface IAdminCustomerUsersListView {
    getCustomerName?: any;
}

export const AdminCustomerUsersListView = (props: IAdminCustomerUsersListView) => {
    const { match } = useRouter();
    const { customerId } = match.params as MatchParams;
    const [viewModel] = useState(new AdminCustomerUsersListViewModel(customerId));
    const { getCustomerName } = props;
    const [filter, setFilter] = useState("");
    const [newCustomerUserModal, setNewCustomerUserModal] = useState(false);
    const [deleteCustomerUserModal, setDeleteCustomerUserModal] = useState(false);
    const [customerUserIdToDelete, setCustomerUserIdToDelete] = useState<string>("");
    const [customerUserIdToEdit, setCustomerUserIdToEdit] = useState<string>("");

    useEffect(() => {
        if (getCustomerName) getCustomerName(viewModel.getCustomerName);
    });

    const modals = () => (
        <div>
            {newCustomerUserModal && (
                <NewCustomerUserModal
                    isActive={newCustomerUserModal}
                    confirmClose={() => {
                        viewModel.loadData("", customerId);
                        setNewCustomerUserModal(false);
                    }}
                    onCancel={() => setNewCustomerUserModal(false)}
                    customerId={customerId}
                    customerUserId={customerUserIdToEdit}
                />
            )}
            {deleteCustomerUserModal && (
                <DeleteModal
                    isActive={deleteCustomerUserModal}
                    confirmClose={() => {
                        if (customerUserIdToDelete) {
                            viewModel.deleteCustomerUser(customerUserIdToDelete);
                        }
                        setDeleteCustomerUserModal(false);
                    }}
                    onCancel={() => {
                        setDeleteCustomerUserModal(false);
                        setCustomerUserIdToDelete("");
                    }}
                    title="Delete User"
                    message="You are about to remove a user from the system are you sure you wish to proceed?"
                />
            )}
        </div>
    );

    const columns = [
        { title: "User name", field: "userName", width: "15%" },
        { title: "Contact email", field: "contactEmail", width: "15%" },
        { title: "D.O.B", field: "dateOfBirth", width: "10%" },
        { title: "Location", field: "address", width: "25%" },
        { title: "Postcode", field: "postcode", width: "10%" },
        { title: "Last amended", field: "lastUpdatedDate", width: "10%" },
        { title: "Date added", field: "createdDate", width: "10%" },
    ];

    const actions = [
        (rowData: AdminCustomerUserLiteTableDataModel) => ({
            icon: () => <DeleteSVG />,
            tooltip: "Delete User",
            onClick: () => {
                setCustomerUserIdToDelete(rowData.id);
                setDeleteCustomerUserModal(true);
            },
        }),
    ];

    return useObserver(() => (
        <>
            <Box style={{ backgroundColor: "white" }}>
                <Box display="flex" width="100%">
                    <TablePageContent>
                        <HHTable
                            onSearchChange={(query: any) => {
                                //     //if (viewModel.customers.length > 600) {
                                viewModel.loadData(query, customerId);
                                //     //}
                            }}
                            columns={columns}
                            data={viewModel.getTableData}
                            components={{
                                Toolbar: (props: any) => (
                                    <>
                                        <ToolbarContainer>
                                            <SearchSummary data={viewModel.getTableData} resultType="user" />
                                            <MTableToolbar style={{ padding: 0 }} {...props} />
                                            <HHButton
                                                onClick={() => {
                                                    setCustomerUserIdToEdit("");
                                                    setNewCustomerUserModal(true);
                                                }}
                                                text="Add new user"
                                                width="137px"
                                            />
                                        </ToolbarContainer>
                                    </>
                                ),
                            }}
                            onRowClick={(event: any, rowData: { id: string }, togglePanel: any) => {
                                setCustomerUserIdToEdit(rowData.id);
                                setNewCustomerUserModal(true);
                            }}
                            title=""
                            actions={actions}
                            localization={{
                                header: {
                                    actions: "",
                                },
                            }}
                            isLoading={viewModel.IsLoading}
                        />
                    </TablePageContent>
                </Box>
                {modals()}
            </Box>
        </>
    ));
};

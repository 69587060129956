import { Box, FormControl, FormHelperText, FormLabel, InputAdornment, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FieldType, IModel, isEmptyOrWhitespace } from "@shoothill/core";
import { useObserver } from "mobx-react-lite";
import { IViewModel, ViewModelBase } from "@shoothill/core/dist/ViewModels";
import React, { useEffect } from "react";
import { theme } from "../../../Globals/Styles/AppTheme";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import { DatePicker } from "../../../Content/assets/DatePicker";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { ICommandAsync } from "../../Commands";
import { reaction } from "mobx";
//import DateFnsUtils from "@date-io/date-fns";

interface IProps<T extends IModel<T>> {
    //viewModel: IViewModel<T> & ViewModelBase<T>;
    //fieldName: keyof FieldType<T>;
    value: () => Date | null;
    //onChange: (val: Date) => void;
    label?: string;
    placeholder?: string;
    minDate?: Date | null;
    maxDate?: Date | null;
    disabled?: boolean;
    disablePast?: boolean;
    error?: string;
    width?: string;
    validationMessage?: () => string;
    errorText?: () => any;
    command: ICommandAsync;
}

const useStyles = makeStyles(() => ({
    wrapper: {
        "& .MuiFormControl-root": {
            width: "137px",
            padding: 0,

            "@media(max-width: 767px)": {
                width: "100% !important",
            },
            marginBottom: 0,
        },
        "& .MuiInputBase-root": {
            paddingRight: 0,
        },

        "& .MuiOutlinedInput-input": {
            height: "28px",
            padding: 0,
            paddingLeft: 10,
            fontSize: 12,
            fontWeight: "bold",
        },

        "& .MuiButtonBase-root": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderLeft: `1px solid ${theme.palette.info.light}`,
            borderRadius: 0,
            height: "28px",
            width: "28px",
            padding: 0,
            position: "absolute",
            right: "13px",
        },
    },
    datePicker: {
        "& .MuiFormLabel-root": {
            "@media(max-width: 767px)": {
                fontSize: "12px !important",
                whiteSpace: "normal",
                position: "relative",
                marginBottom: "-10px",
            },
        },
    },
}));

export function CustomDatePicker<T extends IModel<T>>(props: IProps<T>) {
    const [value, setValue] = React.useState<Date | null>(null);
    const classes = useStyles();

    useEffect(() => {
        reaction(
            () => props.value(),
            (value) => {
                setValue(value);
            },
        );
        setValue(props.value());
    }, []);

    const getValidationMessage = (): string => {
        return isEmptyOrWhitespace(props.validationMessage?.() as string) ? "" : (props.validationMessage?.() as string);
    };
    const hasError = (): boolean => {
        if (props.validationMessage) return !isEmptyOrWhitespace(props.validationMessage!());
        return false;
    };
    const onChange = async (date: any) => {
        setValue(date);
        await props.command.execute(date);
    };

    return useObserver(() => (
        <FormControl className={classes.wrapper}>
            <FormLabel>{props.label}</FormLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                {
                    <DesktopDatePicker
                        className={classes.datePicker}
                        //style={props.viewModel.getError(props.fieldName) !== "" ? { borderBottom: "2px solid red" } : {}}
                        value={value}
                        onChange={(e) => onChange(e)}
                        disablePast={props.disablePast}
                        minDate={props.minDate}
                        maxDate={props.maxDate}
                        //placeholder={props.placeholder}
                        disabled={props.disabled}
                        renderInput={(params) => (
                            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                <TextField {...params} variant="outlined" style={{ width: `${props.width ? props.width : "137px"}` }} />
                            </Box>
                        )}
                        components={{
                            OpenPickerIcon: () => <DatePicker />,
                        }}
                        inputFormat="dd/MM/yyyy"

                        // renderInput={(params) => (
                        //     <Box sx={{ display: "flex", alignItems: "center" }}>
                        //         <input ref={params.inputRef} {...params.inputProps} sx={{ paddingRight: "10px" }} />
                        //         {params.InputProps?.endAdornment}
                        //     </Box>
                        // )}
                    />
                }
            </LocalizationProvider>
            {hasError() && <FormHelperText style={{ color: "red" }}>{getValidationMessage()}</FormHelperText>}
            {/*{props.error != "" ? (
                <>
                    <p style={{ color: theme.palette.error.main, position: "absolute", fontSize: "14px" }}>{props.error}</p>
                </>
            ) : (
                <>
                    {props.viewModel.getError(props.fieldName) !== "" && (
                        <p style={{ color: theme.palette.error.main, position: "absolute", fontSize: "14px" }}>{props.viewModel.getError(props.fieldName)}</p>
                    )}
                </>
            )}*/}
        </FormControl>
    ));
}

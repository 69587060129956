import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
// @ts-ignore
import Swiper from "react-native-swiper/src";
import { ProfilesViewModel } from "./ProfilesViewModel";

interface IProps {}

export const ProfilesScreen = observer((props: IProps) => {
    const [viewModel] = useState(() => new ProfilesViewModel());

    return <>Profiles</>;
});
/*return (
        <Swiper style={styles.wrapper} showsButtons loop={false}>
            <View testID="Hello" style={styles.slide1}>
                <Text style={styles.text}>Hello Swiper</Text>
            </View>
            <View testID="Beautiful" style={styles.slide2}>
                <Text style={styles.text}>Beautiful</Text>
            </View>
            <View testID="Simple" style={styles.slide3}>
                <Text style={styles.text}>And simple</Text>
            </View>
        </Swiper>
    );*/

import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { ApiResult, FieldType, HttpClient, KeyValuePair, ViewModelBase } from "@shoothill/core";
import { container } from "tsyringe";

// App
import { NewCustomerUserModel, NewCustomerUserValidator } from "../../../Globals/Models/Domain/Admin/NewCustomerUserModel";
import { NewCustomerUser } from "../../../Globals/Models/NewCustomerUser";
import { Ethnicity } from "../../../Globals/Models/Ethnicity";
import { AppUrls } from "../../../AppUrls";
import { DomainStores } from "../../Stores";
import { RelayCommandAsync, ICommandAsync, RelayCommand, ICommand } from "../../Commands";
import { IKeyState } from "../../Views";

export default class NewCustomerUserViewModel extends ViewModelBase<NewCustomerUserModel> {
    public httpClient = container.resolve(HttpClient);
    private domainStores = container.resolve(DomainStores);

    constructor(customerUserId: string | null) {
        super(new NewCustomerUserModel());
        this.setValidator(new NewCustomerUserValidator());
        this.loadCustomerUser(customerUserId);
        (window as any).ncuvm = this;
    }

    public setFirstName = (value: string): void => {
        this.setValue("firstName", value);
    };

    public updateFirstNameCommand: ICommandAsync = new RelayCommandAsync(
        async (value: string, keyState: IKeyState) => {
            await this.updateField("firstName", value, keyState);
        },
        () => Promise.resolve(true),
    );

    public updateLastNameCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("lastName", value, keyState);
    });

    public updateContactEmailCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("contactEmail", value, keyState);
    });

    public updateContactNumberCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("contactNumber", value, keyState);
    });

    public updateAddressCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("address", value, keyState);
    });

    public updateEthnicityCommand: ICommandAsync = new RelayCommandAsync(async (value: KeyValuePair) => {
        await this.updateField("ethnicityId", value.value);
    });
    public updateRoleIdCommand: ICommandAsync = new RelayCommandAsync(async (value: KeyValuePair) => {
        await this.updateField("roleId", value.value);
    });
    public updateHeightCommand: ICommandAsync = new RelayCommandAsync(async (value: string) => {
        await this.updateField("height", parseFloat(value));
    });

    public updateWeightCommand: ICommandAsync = new RelayCommandAsync(async (value: string) => {
        await this.updateField("weight", parseFloat(value));
    });

    public updateDateOfBirthCommand: ICommandAsync = new RelayCommandAsync(async (value: Date) => {
        await this.updateField("dateOfBirth", value);
    });

    private async updateField(fieldName: keyof FieldType<NewCustomerUserModel>, value: any, keyState?: IKeyState) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    public setDOB(fieldName: keyof FieldType<NewCustomerUserModel>, value: Date) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    public setLocation = async (address: string, latitude: string, longitude: string, postcode: string) => {
        let modelToUpdate = this.getModel;
        if (modelToUpdate) {
            modelToUpdate.setValue("address", address);
            modelToUpdate.setValue("latitude", latitude);
            modelToUpdate.setValue("longitude", longitude);
            modelToUpdate.setValue("postcode", postcode);
        }
    };

    private async loadCustomerUser(customerUserId: string | null): Promise<void> {
        if (customerUserId === null) return;
        this.setIsLoading(true);
        const apiResult = await this.httpClient.Get<NewCustomerUserModel>(AppUrls.Server.Admin.GetCustomerUser + "/?id=" + customerUserId);
        this.setIsLoading(false);
        if (!apiResult.wasSuccessful) return;
        this.model.reset();
        this.model.toModel(apiResult.payload);
    }

    public createNewCustomerUser = async (customerId: string): Promise<boolean> => {
        let isMyModelValid: boolean = this.isModelValid();
        if (isMyModelValid) {
            this.setIsLoading(true);
            this.setValue("customerId", customerId);

            const apiResult = await this.httpClient.Post<NewCustomerUserModel>(AppUrls.Server.Admin.CreateNewCustomerUser, this.model);
            try {
                if (apiResult.wasSuccessful) {
                    this.setValue("id", apiResult.payload.id);
                    this.history.push(AppUrls.Client.Admin.CustomerUsers.replace(":customerId?", customerId));
                    return true;
                } else {
                    apiResult.errors.forEach((error) => {
                        this.domainStores.OpenSnackBar(`Error: ${error.message}`, "error");
                    });
                    return false;
                }
            } catch {
                this.domainStores.OpenSnackBar(`Unknown error creating this user`, "error");
            }
        }

        return false;
    };

    public upsertCustomerUser = async (customerId: string): Promise<boolean> => {
        let isMyModelValid: boolean = this.isModelValid();
        if (isMyModelValid) {
            this.setIsLoading(true);
            console.log(this.model);
            const apiResult = await this.httpClient.Post<NewCustomerUserModel>(AppUrls.Server.Admin.upsertCustomerUser, this.model);

            if (apiResult.wasSuccessful) {
                this.history.push(AppUrls.Client.Admin.CustomerUsers.replace(":customerId?", customerId));
                return true;
            } else {
                apiResult.errors.forEach((error) => {
                    this.domainStores.OpenSnackBar(`Error: ${error.message}`, "error");
                });
                return false;
            }
        }

        return false;
    };

    public resetPassword = async (): Promise<boolean> => {
        const apiResult = await this.httpClient.Post<NewCustomerUserModel>(AppUrls.Server.Admin.resetuserpassword, this.model);

        if (apiResult.wasSuccessful) {
            this.domainStores.OpenSnackBar("Reset password email sent", "success");
            return true;
        } else {
            apiResult.errors.forEach((error) => {
                this.domainStores.OpenSnackBar(`Error: ${error.message}`, "error");
            });
            return false;
        }
    };

    public get getSortedByOrdinal(): Ethnicity[] {
        return this.domainStores.ethnicities.slice().sort((a, b) => (a.ordinal < b.ordinal ? -1 : 1));
    }

    public get getEthnicityOptions(): KeyValuePair[] {
        /*let placeholder = {
            label: "Please select...",
            value: "placeholder",
        } as KeyValuePair;*/

        let options = this.getSortedByOrdinal.map((value) => ({ value: value.id, label: value.name })) as KeyValuePair[];

        //options.unshift(placeholder);

        return options;
    }

    public get getSelectedOption(): KeyValuePair | null {
        let ethnicity = this.domainStores.ethnicities.find((e) => e.id === this.getModel.ethnicityId);
        if (ethnicity) {
            let selectedOption = {
                label: ethnicity.name,
                value: ethnicity.id,
            } as KeyValuePair;

            return selectedOption;
        }

        return null;
        //return { label: "Please select", value: "placeholder" } as KeyValuePair;
    }
}

import { ResetPasswordModel, ResetPasswordValidator } from "./ResetPasswordModel";
import { makeObservable, observable, runInAction } from "mobx";

import { ApiResult, HttpClient, Validation } from "@shoothill/core";
import { AppUrls } from "AppUrls";
import { ViewModelBase } from "@shoothill/core";
import { getUrlSearchParams } from "@shoothill/core";
import { FieldType } from "@shoothill/core";
import { GlobalHistory } from "../../index";
import { container } from "tsyringe";
import { LoginModel } from "../Login/LoginModel";
import { IKeyState } from "../../Globals";
import { ICommandAsync, RelayCommandAsync } from "../../Globals/Commands";

export class ResetPasswordViewModel extends ViewModelBase<ResetPasswordModel> {
    private httpClient = container.resolve(HttpClient);
    public tokenValid?: boolean;
    public errorMessage: string = "";
    public isSuccess: boolean = false;

    constructor() {
        super(new ResetPasswordModel());
        this.setValidator(new ResetPasswordValidator());
        makeObservable(this, {
            tokenValid: observable,
            errorMessage: observable,
            isSuccess: observable,
        });
    }
    public resetPasswordCommand: ICommandAsync = new RelayCommandAsync(
        async () => {
            this.setIsLoading(true);

            const apiResult = await this.resetPasswordAsync();

            runInAction(() => {
                this.setIsLoading(false);

                if (apiResult) {
                    if (apiResult.wasSuccessful) {
                        this.errorMessage = "";
                        this.isSuccess = true;
                    } else {
                        this.errorMessage = "* " + apiResult.errors[0].message;
                        this.isSuccess = false;
                    }
                } else {
                    this.errorMessage = "* Unknown error has occurred.";
                    this.isSuccess = false;
                }
            });
        },
        () => {
            return Promise.resolve(this.isModelValid());
        },
    );
    public updateNewPasswordCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("newPassword", value, keyState);
        this.isFieldValid("confirmPassword");
    });
    public updateConfirmPasswordCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("confirmPassword", value, keyState);
    });
    private updateField(fieldName: keyof FieldType<ResetPasswordModel>, value: any, keyState: IKeyState) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    public getTokenFromUrl = async () => {
        const urlSearchParams = getUrlSearchParams();
        const forgotPasswordToken = urlSearchParams.get("forgottoken");
        if (forgotPasswordToken) {
            this.model.setValue("token", forgotPasswordToken);
            await this.verifyForgotPasswordTokenAsync();
        } else {
            runInAction(() => {
                this.tokenValid = false;
            });
        }
    };

    public verifyForgotPasswordTokenAsync = async (): Promise<void> => {
        let apiResult = await this.httpClient.Post<ResetPasswordModel>(AppUrls.Server.Account.ResetPassword.VerifyToken, this.model);
        runInAction(() => {
            this.tokenValid = apiResult.wasSuccessful;

            if (this.tokenValid) {
                this.model.toModel(apiResult.payload);
            }
        });
    };

    public resetPasswordAsync = async (): Promise<ApiResult<ResetPasswordModel>> => {
        let apiResult = await this.httpClient.Post<ResetPasswordModel>(AppUrls.Server.Account.ResetPassword.Reset, this.model);
        return apiResult;
    };
}

import { useEffect, useState } from "react";
import { useRouter } from "Globals/Hooks/UseRouter";
import { useHistory } from "react-router";
import { Box, Typography } from "@mui/material";
import { AdminCustomerUsersListView } from "./AdminCustomerUsersListView";
import { AdminCustomerBillingView } from "./AdminCustomerBillingView";

//custom
import { ITabProps, Tabs } from "../../../Globals/Components/Tabs/Tabs";
import { render } from "react-dom";
import { BackToLink } from "Globals/Components/General/BackToLink";
import { AdminCustomerUserToolbar } from "./AdminCustomer.styles";
import { ToolbarTitle } from "Globals/Components/General/General.styles";
import { useObserver } from "mobx-react-lite";

export const AdminCustomerView: React.FC = () => {
    const { match } = useRouter();
    const TAB_USERS = "Users";
    const TAB_BILLING = "Billing";
    const tabOptions: ITabProps[] = [
        { key: TAB_USERS, title: "Users" },
        { key: TAB_BILLING, title: "Account & Billing" },
    ];
    const [selectedTab, setSelectedTab] = useState(TAB_USERS);
    const [customerName, setCustomerName] = useState("");

    let history = useHistory();

    const goBack = () => history.push("/admin/customers");

    const handleCustomerName = (name: string) => {
        if (name) setCustomerName(name);
    };

    const onTabClick = (tab: ITabProps) => {
        setSelectedTab(tab.key);
    };

    const renderContent = () => {
        switch (selectedTab) {
            case TAB_USERS:
                return <AdminCustomerUsersListView getCustomerName={(name: string) => handleCustomerName(name)} />;
            case TAB_BILLING:
                return <AdminCustomerBillingView />;
        }
    };

    return (
        <Box>
            <AdminCustomerUserToolbar>
                <Box>
                    <BackToLink onClick={goBack} text={"Back to customers"} />
                    <ToolbarTitle>{customerName}</ToolbarTitle>
                </Box>
                <Box style={{ display: "flex", alignItems: "end", height: "100%", paddingBottom: "14px" }}>
                    <Tabs tabs={tabOptions} selected={selectedTab} onTabClick={onTabClick} />
                </Box>
            </AdminCustomerUserToolbar>
            {renderContent()}
        </Box>
    );
};

//https://carlosmanotas.github.io/animate-styled/?selectedKind=Bounce%20animation&selectedStory=bounce&full=0&addons=1&stories=1&panelRight=1&addonPanel=storybooks%2Fstorybook-addon-knobs
import { AutoGrid } from "Globals/Components/Grid";

import { Box, Grid, InputLabel } from "@mui/material";
import React from "react";

export const Animation: React.FC = () => {
    return (
        <>
            <AutoGrid dc={"1fr 1fr 1fr 1fr 1fr 1fr 1fr"} pt={3}>
                {/*<Box>
                    <InputLabel>Simple Hover</InputLabel>
                    <FancyBox>Hello</FancyBox>
                </Box>
                <Box>
                    <InputLabel>Fade In</InputLabel>
                    <AnimateStyled name="fadeIn" delay="1000ms">
                        <Box bgcolor="#aaa">Hello</Box>
                    </AnimateStyled>
                </Box>
                <Box>
                    <InputLabel>Bounce In</InputLabel>
                    <AnimateStyled name="bounceIn" delay="1000ms">
                        <Box bgcolor="#aaa">Hello</Box>
                    </AnimateStyled>
                </Box>
                <Box>
                    <InputLabel>Light Speed In</InputLabel>
                    <AnimateStyled name="lightSpeedIn" delay="1000ms">
                        <Box bgcolor="#aaa">Hello</Box>
                    </AnimateStyled>
                </Box>
                <Box>
                    <InputLabel>Zoom In</InputLabel>
                    <AnimateStyled name="zoomIn" delay="1000ms">
                        <Box bgcolor="#aaa">Hello</Box>
                    </AnimateStyled>
                </Box>
                <Box>
                    <InputLabel>Zoom Out</InputLabel>
                    <AnimateStyled name="zoomOut" delay="1000ms">
                        <Box bgcolor="#aaa">Hello</Box>
                    </AnimateStyled>
                </Box>
                <Box>
                    <InputLabel>Flip</InputLabel>
                    <AnimateStyled name="flip" delay="1000ms">
                        <Box bgcolor="#aaa">Hello</Box>
                    </AnimateStyled>
                </Box>*/}
            </AutoGrid>
        </>
    );
};

import styled from "@emotion/styled";
import { Box, Button, Typography } from "@mui/material";
import { theme } from "../../Styles/AppTheme";

export const AdminPrimaryToolbar = styled(Box)`
    display: flex;
    height: 90px;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
`;

export const AdminSecondaryToolbar = styled(Box)`
    display: flex;
    height: 71px;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
`;

export const ToolbarTitle = styled.p`
    font-size: 24px;
    font-weight: bold;
`;

export const TablePageContent: any = styled(Box)`
    display: flex;
    flex-direction: column;
    width: 100%;
    .MuiTable-root {
        width: 100%;
    }
    .MuiTableHead-root {
        font-size: 12px;
        background-color: ${theme.palette.tableHeader.main};
    }

    .MuiTableCell-root,
    .MuiTableRow-root {
        font-size: 12px;
        color: ${theme.palette.customText.primary};
    }

    > div {
        background-color: ${theme.palette.background.paper};
        box-shadow: unset !important;
    }
`;

export const ContentContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid ${theme.palette.healthAppyBlue.main};
    padding: 30px;

    .new-content {
        max-width: 375px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .new-question {
        width: 344px;
    }

    //MUI overrides

    textarea {
        padding: 5px 9px;
        width: 100%;
    }

    .MuiOutlinedInput-root {
        font-size: 14px;
        font-weight: bold;
        padding: 0px;
        input[type="text"] {
            padding: 0px 9px;
        }
        textarea {
            padding: 5px 9px;
            width: 100%;
        }
    }

    .editInput {
        width: 100%;
        textarea {
            width: 100%;
        }
    }

    .modaltextarea {
        .MuiFormControl-root {
            height: auto;
            .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
                height: auto;
            }
        }
    }

    .MuiFormControl-root {
        margin-bottom: 10px;
        .MuiOutlinedInput-root {
            input[type="text"] {
                height: 28px;
            }
        }
    }

    .MuiInputBase-root {
        border-radius: 0px;
    }

    .MuiFormLabel-root {
        color: ${theme.palette.text.primary};
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 5px;
    }

    .MuiCheckbox-root {
        padding: 0px 9px;
    }

    .MuiAutocomplete-root {
        height: 28px;
        .MuiFormControl-root {
            height: 28px;

            .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
                height: 28px;
            }
        }
    }
`;

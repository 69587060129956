import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { EditText } from "../../Globals";
import { theme } from "../../Globals/Styles/AppTheme";

export const LoginContainer = styled(Box)`
    height: 100vh;
    width: 100vw;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const LoginForm = styled.form`
    width: 274px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const LoginInput = styled(EditText)`
    width: 100%;

    .MuiFormLabel-root {
        color: ${theme.palette.customText.primary};
        font-size: 14px;
        font-family: "Manrope Bold", sans-serif;
        font-weight: bold;
        margin-bottom: 5px;
    }

    .MuiOutlinedInput-input,
    .MuiInputBase-input {
        padding: 6px !important;
        font-size: 12px;
        color: ${theme.palette.customText.disabled} !important;
        opacity: 1;
    }
`;

export const ForgotPasswordLinkText = styled.p`
    font-size: 10px;
    font-family: "Manrope Bold", sans-serif;
    font-weight: bold;
    color: ${theme.palette.healthAppyBlue.main};
    text-decoration: underline;
`;

import styled from "@emotion/styled";
import { Box, Button, Typography } from "@mui/material";
import { theme } from "../../../Globals/Styles/AppTheme";

export const AdminCustomerUserToolbar = styled(Box)`
    display: flex;
    height: 90px;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
`;

export const AdminCustomerBillingContainer = styled(Box)`
    width: 100%;
    height: calc(100vh - 151px); //100vh - (navigation toolbar + admin toolbar)
    // background-color: rgba(255, 215, 0, 0.2);
    border-top: 1px solid ${theme.palette.healthAppyBlue.main};
    padding: 0 30px;
`;

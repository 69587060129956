import { action, makeObservable, observable } from "mobx";
import { AccountStore } from "./Domain";

import { Customer, EditableCopy, EditableCopyRoute, Ethnicity, Category, NewProfileQuestionAnswer, ContentLite } from "Globals/Models";
import { ContentType } from "../Models/ContentType";
import { singleton, container } from "tsyringe";
import { KeyValuePair } from "@shoothill/core";

@singleton()
export class DomainStores {
    public openSnackBar = false;
    public snackBarSeverity: "success" | "error" | "warning" | "info" = "success";
    public openErrorDialog = false;
    public errorDialogContent: string = "";
    public snackBarContent: string = "";
    public categories = observable<Category>([]);
    public contentTypes = observable<ContentType>([]);
    public customers = observable<Customer>([]);
    public editableCopies = observable<EditableCopy>([]);
    public editableCopyRoutes = observable<EditableCopyRoute>([]);
    public ethnicities = observable<Ethnicity>([]);
    public AccountStore = container.resolve(AccountStore);
    public bonusPoints = observable<KeyValuePair>([]);
    public points = observable<KeyValuePair>([]);
    public answers = observable<NewProfileQuestionAnswer>([]);

    constructor() {
        console.log("Domain store created");
        makeObservable(this, {
            openSnackBar: observable,
            snackBarSeverity: observable,
            openErrorDialog: observable,
            errorDialogContent: observable,
            snackBarContent: observable,
            categories: observable,
            contentTypes: observable,
            customers: observable,
            editableCopies: observable,
            editableCopyRoutes: observable,
            ethnicities: observable,
            OpenSnackBar: action,
            CloseSnackBar: action,
            ShowErrorDialog: action,
        });
        this.init();
    }

    public init = () => {
        this.editableCopies.clear();
        this.editableCopyRoutes.clear();
        this.ethnicities.clear();
        this.AccountStore.initialState.editableCopies.forEach((editableCopy) => {
            this.editableCopies.push(editableCopy);
        });
        this.AccountStore.initialState.editableCopyRoutes.forEach((editableCopyRoute) => {
            this.editableCopyRoutes.push(editableCopyRoute);
        });
        this.AccountStore.initialState.ethnicities.forEach((ethnicity) => {
            this.ethnicities.push(ethnicity);
        });
        this.AccountStore.initialState.categories.forEach((category) => {
            this.categories.push(category);
        });
        this.AccountStore.initialState.contentTypes.forEach((type) => this.contentTypes.push(type));
        this.AccountStore.initialState.customers.forEach((customer) => {
            this.customers.push(customer);
        });
    };

    public OpenSnackBar(content: string, severity: "success" | "error" | "warning" | "info") {
        console.log("open snack");
        this.snackBarContent = content;
        this.snackBarSeverity = severity;
        this.openSnackBar = true;
    }

    public CloseSnackBar() {
        this.openSnackBar = false;
    }

    public ShowErrorDialog(content: string) {
        this.errorDialogContent = content;
        this.openErrorDialog = true;
    }
}

import { Box, Fade, Paper, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { EditableCopyViewModel } from "Globals/ViewModels/EditableCopyViewModel";
import { useObserver } from "mobx-react-lite";
import { Centered } from "Globals/Components/Centered";

export const ForgotPasswordSuccessView: React.FunctionComponent = () => {
    const { current: viewModel } = useRef(new EditableCopyViewModel());

    return useObserver(() => (
        <Box justifyContent="center" alignItems={"center"} textAlign="center" display="flex" flexDirection={"column"}>
            <Typography component="h1" variant="h3" style={{ marginBottom: "25px", marginTop: "25px" }}>
                Password reset
            </Typography>
            <Typography variant="body1" gutterBottom>
                If the email you entered is associated with an account, you will receive an email with a link to reset your password.
            </Typography>
        </Box>
    ));
};

import { Box, Button, Fade, Paper, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { EditableCopyViewModel } from "../../Globals/ViewModels/EditableCopyViewModel";
import { ForgotPasswordViewModel } from "./ForgotPasswordViewModel";
import { useObserver } from "mobx-react-lite";
import { EditText } from "../../Globals/Views/Primitives/EditText";
import { Centered } from "../../Globals/Components/Centered";
import { LoginContainer, LoginForm, LoginInput } from "Views/Login/LoginView.style";
import { HHButton } from "Globals/Components/General/Buttons/HHButton";
import Logo from "Content/assets/logoPortrait.svg";

export const ForgotPasswordView = () => {
    const [viewModel] = useState(() => new ForgotPasswordViewModel());
    const [viewModelEditableCopy] = useState(() => new EditableCopyViewModel());

    const doSubmit = async (e: any) => {
        e.preventDefault();
        await viewModel.forgotPasswordCommand.execute();
    };

    return (
        <LoginContainer>
            <Box style={{ marginBottom: "90px" }}>
                <Typography variant="h3">Reset your password</Typography>
            </Box>
            <Box style={{ marginBottom: "34px" }}>
                <img src={Logo} alt="Healthappy logo" />
            </Box>
            <LoginForm onSubmit={doSubmit}>
                {viewModel.errorMessage !== "" && (
                    <Typography variant="caption" style={{ color: "red" }}>
                        {viewModel.errorMessage}
                    </Typography>
                )}

                <LoginInput
                    id={"emailAddress"}
                    displayName={"Email Address"}
                    value={() => viewModel.getValue("emailAddress")}
                    command={viewModel.updateEmailAddressCommand}
                    validationMessage={() => viewModel.getError("emailAddress")}
                    placeholder="Enter your email here"
                    style={{ marginBottom: "15px" }}
                />

                <HHButton text={viewModel.IsLoading ? <>Please wait...</> : <>Reset password</>} type="submit" width="274px" />
            </LoginForm>
        </LoginContainer>
    );
};

import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { ApiResult, FieldType, HttpClient, ViewModelBase } from "@shoothill/core";
import { container } from "tsyringe";

// App
import { AppUrls } from "../../../AppUrls";
import { ContentLite } from "../../../Globals/Models/ContentLite";
import { ContentType } from "Globals/Models/ContentType";

export interface AdminContentLiteTableDataModel {
    id: string;
    colour: string;
    accentColour: string;
    categoryId: string;
    categoryName: string;
    typeId: string;
    lastUpdatedUser: string;
    contentType: string;
    title: string;
    totalPoints: number;
    firstName: string;
    lastName: string;
    createdDate: string;
    lastUpdatedDate: string;
    isVisibleInApp: boolean;
    isDeleted: boolean;
}

export default class AdminContentListViewModel extends ViewModelBase {
    private httpClient = container.resolve(HttpClient);

    public contentsLite: ContentLite[] = [];

    constructor() {
        super(null);
        makeObservable(this, {
            contentsLite: observable,
            loadData: action,
            getTableData: computed,
            deleteContent: action,
        });
        this.loadData("");
    }

    public async loadData(query: string): Promise<void> {
        this.setIsLoading(true);
        const path = AppUrls.Server.Admin.SearchAllContents + "/?search=" + query;
        const apiResult = await this.httpClient.Get<ContentLite[]>(path);
        if (apiResult.wasSuccessful) {
            runInAction(() => {
                this.contentsLite = apiResult.payload;
            });
        }
        this.setIsLoading(false);
    }

    public async deleteContent(id: string): Promise<void> {
        this.setIsLoading(true);
        const path = AppUrls.Server.Admin.DeleteContent + "/?id=" + id;
        const apiResult = await this.httpClient.Delete<ContentLite>(path);
        if (apiResult.wasSuccessful) {
            let contentLite = this.contentsLite.find((contentLite) => contentLite.id === id);
            runInAction(() => {
                if (contentLite != undefined) {
                    this.contentsLite.splice(this.contentsLite.indexOf(contentLite), 1);
                }
            });
        }
        this.setIsLoading(false);
    }

    public async changeVisible(id: string): Promise<void> {
        this.setIsLoading(true);
        const path = AppUrls.Server.Admin.ChangeVisible + "/?id=" + id;
        const apiResult = await this.httpClient.Put<ContentLite>(path);
        if (apiResult.wasSuccessful) {
            let contentLite = this.contentsLite.find((contentLite) => contentLite.id === id);
            runInAction(() => {
                if (contentLite != undefined) {
                    contentLite.isVisibleInApp = !contentLite.isVisibleInApp;
                }
            });
        }
        this.setIsLoading(false);
    }

    public get getTableData() {
        let tableData: AdminContentLiteTableDataModel[] = [];
        this.contentsLite.forEach((contentLite: ContentLite) => {
            tableData.push({
                id: contentLite.id ? contentLite.id : "",
                colour: contentLite.colour,
                accentColour: contentLite.accentColour,
                categoryId: contentLite.categoryId,
                categoryName: contentLite.categoryName,
                typeId: contentLite.typeId,
                contentType: contentLite.contentType,
                title: contentLite.title,
                totalPoints: contentLite.totalPoints,
                lastUpdatedUser: contentLite.lastUpdatedUser,
                firstName: contentLite.firstName,
                lastName: contentLite.lastName,
                createdDate: new Date(contentLite.createdDate).toLocaleDateString("en-GB"),
                lastUpdatedDate: new Date(contentLite.lastUpdatedDate).toLocaleDateString("en-GB"),
                isVisibleInApp: contentLite.isVisibleInApp,
                isDeleted: contentLite.isDeleted,
            });
        });
        return tableData;
    }
}

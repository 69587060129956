import React, { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useRouter } from "../../../Globals/Hooks/UseRouter";
import { Box, Button, Checkbox, FormControl, FormLabel, FormControlLabel, TextField, Typography, FormGroup } from "@mui/material";
import { useObserver } from "mobx-react-lite";
import * as Mui from "@mui/material";

//custom
import { AutoGrid, Spinner } from "../../../Globals";
import { UserFile } from "../../../Globals/Models/UserFile";
import { theme } from "../../../Globals/Styles/AppTheme";
import { AdminPrimaryToolbar, ContentContainer, TablePageContent, ToolbarTitle } from "Globals/Components/General/General.styles";
import { BackToLink } from "Globals/Components/General/BackToLink";
import { EditText } from "../../../Globals/Views/Primitives/EditText";
import { EditNumber } from "../../../Globals/Views/Primitives";
import { FileUploader } from "Globals/Views/Primitives/FileUploader";
import { Select } from "../../../Globals/Views/Primitives/Select";
import { useHistory } from "react-router";
import NewProfileViewModel from "Globals/ViewModels/Admin/NewProfileViewModel";
import { ProfileQuestionAnswer } from "./ProfileQuestionAnswerView";
import { ProfileQuestionAnswerInput } from "./ProfileQuestionAnswerInputView";
import { useColorTheme } from "Globals/Hooks/useColorTheme";
import { AddProfileFieldContainer, AddProfileQuestionAnswerList, FileUploaderContainer, FileUploaderEditText, RoundedEditText } from "./AdminAddProfileView.styles";
import { ButtonContainer, ButtonGroup, HHButton } from "Globals/Components/General/Buttons/HHButton";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import NewProfileQuestionAnswerViewModel from "Globals/ViewModels/Admin/NewProfileQuestionAnswerViewModel";
import { NewProfileQuestionAnswerModel } from "Globals/Models/Domain/Admin/NewProfileQuestionAnswerModel";
import axios from "axios";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { AppUrls } from "../../../AppUrls";
import { HttpClient, ViewModelBase, PostFormWithFile } from "@shoothill/core/";
import { parse } from "path/posix";
import { id } from "date-fns/locale";
import { Loader } from "Globals/Components/Loader";
import { runInAction } from "mobx";
interface ICustomHrProps {
    color: string;
    type: string;
}

const CustomHr = styled.div<ICustomHrProps>`
    height: 1px;
    width: 345px;
    border-bottom: 1px ${(props) => props.type} ${(props) => props.color};
`;

const AnswerEditNumber = styled(EditNumber)`
    .MuiOutlinedInput-root {
        background: ${theme.palette.common.white};
    }
`;

const StyledSelect = styled(Select)`
    .MuiAutocomplete-popupIndicator {
        color: red;
    }
`;

export interface MatchParams {
    questionId?: string;
}

export interface Question {
    CategoryId: string;
    OrderPosition: number;
    MainImageUrl: string;
    QuestionText: string;
    AnswerText: string;
    Score: number;
    CanSelectMultiple: boolean;
    TotalScore: number;
}

export const AdminAddNewProfileQuestion = () => {
    let history = useHistory();
    const { match } = useRouter();
    const { questionId } = match.params as MatchParams;
    const [viewModel] = useState(new NewProfileViewModel());
    const [errorMessage, setErrorMessage] = useState("");
    const [dynamicColor, setDynamicColor] = useState(theme.palette.info.main);
    const [label, setLabel] = useState("");
    const accentColor = useColorTheme(label, "accent");
    const mainColor = useColorTheme(label, "main");
    const [checked, setChecked] = React.useState(false);
    const goBack = () => history.goBack();
    const [imageUrl, setImageUrl] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(true);

    useEffect(() => {
        if (viewModel.updateAddAnswerIsDisabledCommand() === false) {
            setDynamicColor(accentColor);
        } else {
            setDynamicColor(theme.palette.info.main);
        }
    });

    useEffect(() => {
        getProfileQuestionAndAnswersById();
    }, [questionId]);

    const handleAddProfileQuestion = async (e: any) => {
        e.preventDefault();
        if (!viewModel.isModelValid()) {
            setErrorMessage("We've encountered a problem");
        } else {
            setErrorMessage("");
            await viewModel.createNewProfile();
        }
    };

    const handleChange = async (e: any) => {
        setChecked(e.target.checked);
    };

    const getProfileQuestionAndAnswersById = async () => {
        if (questionId) {
            const response = await viewModel.getProfileQuestionAndAnswersAsync(questionId);
            if (response) {
                setIsLoading(false);
                const profileQuestion = response?.payload?.profileQuestion;
                const profileQuestionAnswers = response?.payload?.profileQuestionAnswers;
                if (profileQuestion) {
                    viewModel.setValue("id", profileQuestion?.id);
                    viewModel.setValue("categoryId", profileQuestion?.categoryId);
                    viewModel.setValue("questionOrderPosition", profileQuestion?.orderPosition);
                    viewModel.setValue("question", profileQuestion?.questionText);
                    viewModel.setValue("profileQuestionId", profileQuestion?.id);
                    setChecked(profileQuestion?.canSelectMultiple);

                    if (profileQuestion?.mainImageUrl) {
                        let filesToDisplay: UserFile[] = [];
                        setImageUrl(profileQuestion?.mainImageUrl);
                        let userFile: UserFile = {
                            fileName: profileQuestion?.mainImageUrl.split("/").pop(),
                            fileUrl: profileQuestion?.mainImageUrl,
                        };
                        filesToDisplay.push(userFile);
                        viewModel.setUserFiles(filesToDisplay);
                    }
                }

                if (profileQuestionAnswers && profileQuestionAnswers?.length > 0) {
                    let answers = viewModel.getValue<NewProfileQuestionAnswerViewModel[]>("answers");
                    runInAction(() => {
                        profileQuestionAnswers.forEach((answer: any) => {
                            let answerVM = new NewProfileQuestionAnswerViewModel();
                            answerVM.setValue("id", answer.id);
                            answerVM.setValue("answerOrderPosition", answer.orderPosition);
                            answerVM.setValue("answerText", answer.answerText);
                            answerVM.setValue("answerScore", answer.score);
                            answers.push(answerVM);
                        });
                    });
                    viewModel.setValue("answers", answers);
                }
            }
        } else {
            setIsLoading(false);
        }
    };

    const createNewQuestion = async () => {
        let QuestionAnswers: any[] = [];
        viewModel.getValue<NewProfileQuestionAnswerViewModel[]>("answers").map((questionAnswer: NewProfileQuestionAnswerViewModel, index) => {
            let obj = {
                Id: questionAnswer.getValue("id"),
                OrderPosition: parseInt(questionAnswer.getValue("answerOrderPosition")),
                Score: parseInt(questionAnswer.getValue("answerScore")),
                AnswerText: questionAnswer.getValue("answerText"),
                RegionId: 0,
                IsDeleted: questionAnswer.getValue("isDeleted"),
            };
            QuestionAnswers.push(obj);
        });
        let requestBody = {
            ProfileQuestion: {
                Id: viewModel.getValue("profileQuestionId") === "" ? null : viewModel.getValue("profileQuestionId"),
                CategoryId: viewModel?.getSelectedCategoryOption?.value,
                OrderPosition: viewModel.getValue("questionOrderPosition") !== "" ? parseInt(viewModel.getValue("questionOrderPosition")) : 0,
                MainImageUrl: imageUrl,
                MainImageFileName: "",
                QuestionText: viewModel.getValue("question"),
                TotalScore: parseInt(viewModel.getTotalScore),
                IsVisibleInApp: true,
                CanSelectMultiple: checked,
                RegionId: 0,
            },
            ProfileQuestionAnswers: QuestionAnswers,
        };
        await viewModel.newProfileQuestionAndAnswersAsync(requestBody);
    };

    const moveCard = (dragIndex: number, hoverIndex: number) => {
        viewModel.orderAnswers(dragIndex, hoverIndex);
    };

    const removeAnswer = (index: number) => {
        const answers = viewModel.getValue<NewProfileQuestionAnswerViewModel[]>("answers");
        answers[index].setValue("isDeleted", true);
        answers.forEach((answer: NewProfileQuestionAnswerViewModel, index: number) => {
            console.log(answer.getValue("isDeleted"), index, answer.getValue("answerText"));
        });
        //answers.splice(index, 1);
        //viewModel.setValue("answers", answers);
    };

    return useObserver(() => (
        <>
            <Box style={{ backgroundColor: "white", paddingBottom: "60px" }}>
                <Box display="flex" width="100%">
                    <TablePageContent>
                        <AdminPrimaryToolbar>
                            <Box>
                                <BackToLink onClick={goBack} text={"Back to questions"} />
                                <ToolbarTitle>{questionId ? "Edit question" : "New question"}</ToolbarTitle>
                            </Box>
                            <FormControlLabel labelPlacement="start" control={<Checkbox name="visibleInApp" />} label="Visible in app" />
                        </AdminPrimaryToolbar>

                        {isLoading && <Loader loadingText="Please wait..." />}

                        {!isLoading && (
                            <form onSubmit={handleAddProfileQuestion}>
                                {errorMessage !== "" && (
                                    <Typography variant="caption" style={{ color: "red" }}>
                                        {errorMessage}
                                    </Typography>
                                )}
                                <ContentContainer>
                                    <AutoGrid desktopColumns={"1fr"} style={{ justifyContent: "center", alignItems: "center" }}>
                                        <AddProfileFieldContainer>
                                            <StyledSelect
                                                displayName={"Category"}
                                                options={viewModel.getCategoryOptions}
                                                command={viewModel.updateCategoryCommand}
                                                value={() => {
                                                    setLabel(viewModel.getSelectedCategoryOption.label);
                                                    //console.log(viewModel.getSelectedCategoryOption.label);
                                                    return viewModel.getValue("categoryId");
                                                }}
                                                validationMessage={() => viewModel.getError("categoryId")}
                                                color={accentColor}
                                            />
                                        </AddProfileFieldContainer>
                                        <AddProfileFieldContainer>
                                            <Select
                                                displayName={"Order position"}
                                                options={viewModel.getQuestionOrderPositionOptions}
                                                command={viewModel.updateQuestionOrderPositionCommand}
                                                value={() => viewModel.getValue("questionOrderPosition")}
                                                validationMessage={() => viewModel.getError("questionOrderPosition")}
                                                color={accentColor}
                                                style={{ width: "173px" }}
                                            />
                                        </AddProfileFieldContainer>
                                        <AddProfileFieldContainer>
                                            <FileUploaderContainer color={mainColor}>
                                                <FormLabel>Main image (305 x 188 pixels, max 5MB)</FormLabel>
                                                <FileUploader
                                                    files={viewModel.getUserFiles}
                                                    text={""}
                                                    singleFile={true}
                                                    disabled={false}
                                                    color={dynamicColor}
                                                    onUpload={(userFile: UserFile) => {
                                                        setImageUrl(userFile.fileUrl);
                                                    }}
                                                    onDelete={(userFile: UserFile) => {
                                                        setImageUrl("");
                                                    }}
                                                />
                                                <FileUploaderEditText
                                                    displayName="Question"
                                                    command={viewModel.updateQuestionCommand}
                                                    value={() => viewModel.getValue("question")}
                                                    validationMessage={() => viewModel.getError("question")}
                                                    type={"textarea"}
                                                    rows={3}
                                                    multiline={true}
                                                    placeholder={"The question"}
                                                />
                                            </FileUploaderContainer>
                                        </AddProfileFieldContainer>

                                        <DndProvider backend={HTML5Backend}>
                                            <AddProfileQuestionAnswerList>
                                                {viewModel
                                                    .getValue<NewProfileQuestionAnswerViewModel[]>("answers")
                                                    //.filter((a) => !a.getValue<boolean>("isDeleted"))
                                                    .map((questionAnswer: NewProfileQuestionAnswerViewModel, index) => {
                                                        if (questionAnswer.getValue<boolean>("isDeleted")) return null;
                                                        return (
                                                            <ProfileQuestionAnswer
                                                                moveCard={moveCard}
                                                                id={`card-${index}`}
                                                                index={index}
                                                                key={index}
                                                                parentViewModel={viewModel}
                                                                childViewModel={questionAnswer}
                                                                removeAnswer={() => removeAnswer(index)}
                                                            />
                                                        );
                                                    })}
                                            </AddProfileQuestionAnswerList>
                                        </DndProvider>

                                        {/* New answer input section. */}
                                        <AddProfileFieldContainer style={{ flexDirection: "column" }}>
                                            <RoundedEditText
                                                displayName="Add an answer"
                                                command={viewModel.updateTmpAnswerTitle}
                                                value={() => viewModel.getValue("tmpAnswerTitle")}
                                                validationMessage={() => viewModel.getError("tmpAnswerTitle")}
                                                type={"textarea"}
                                                multiline={true}
                                                placeholder={"An Answer"}
                                            />

                                            <Box style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                                                <Box style={{ display: "grid", gridTemplateColumns: "1fr 1fr", columnGap: "10px", alignItems: "center" }}>
                                                    <FormLabel>{"Score"}</FormLabel>
                                                    <AnswerEditNumber
                                                        displayName=""
                                                        command={viewModel.updateTmpAnswerScore}
                                                        value={() => viewModel.getValue("tmpAnswerScore")}
                                                        validationMessage={() => viewModel.getError("tmpAnswerScore")}
                                                        min={1}
                                                        max={99}
                                                        style={{ width: "50px", margin: "0px" }}
                                                    />
                                                </Box>
                                            </Box>
                                        </AddProfileFieldContainer>

                                        <AddProfileFieldContainer>
                                            <CustomHr color={theme.palette.text.disabled} type="dashed" />
                                        </AddProfileFieldContainer>

                                        <AddProfileFieldContainer>
                                            <Box style={{ display: "grid", gridTemplateColumns: "1fr 140px", columnGap: "10px", alignItems: "center" }} width="345px">
                                                <HHButton
                                                    disabled={viewModel.updateAddAnswerIsDisabledCommand()}
                                                    text={"Add answer"}
                                                    style={{ fontSize: "12px", height: "10px", width: "132px" }}
                                                    color={dynamicColor}
                                                    onClick={async () => {
                                                        let response = await viewModel.addNewAnswer();
                                                        if (response) {
                                                            console.log("answer added");
                                                        }
                                                    }}
                                                />
                                                <Box display="flex" justifyContent="flex-end" alignItems="center">
                                                    <FormLabel style={{ margin: 0 }}>Total score</FormLabel>
                                                    <FormLabel style={{ margin: 0, paddingLeft: "8px" }}>{viewModel.getTotalScore}</FormLabel>
                                                </Box>
                                            </Box>
                                        </AddProfileFieldContainer>
                                        <AddProfileFieldContainer>
                                            <CustomHr color={theme.palette.text.disabled} type="solid" />
                                        </AddProfileFieldContainer>
                                        <AddProfileFieldContainer>
                                            <FormControl component="fieldset">
                                                <FormGroup row aria-label="difficulty-level" sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                                    <FormControlLabel
                                                        value={true}
                                                        control={
                                                            <Checkbox
                                                                defaultChecked={false}
                                                                checked={checked}
                                                                onChange={handleChange}
                                                                checkedIcon={<CheckBoxIcon />}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                            />
                                                        }
                                                        label="Can select multiple answers"
                                                    />
                                                </FormGroup>
                                            </FormControl>
                                        </AddProfileFieldContainer>
                                    </AutoGrid>

                                    <ButtonContainer>
                                        <ButtonGroup>
                                            <HHButton
                                                type="submit"
                                                text={questionId ? "Update" : "Create"}
                                                width="173px"
                                                color={theme.palette.healthAppyBlue.main}
                                                onClick={() => createNewQuestion()}
                                            />
                                            <HHButton type="button" text="Cancel" width="173px" color={theme.palette.info.main} onClick={() => goBack()} />
                                        </ButtonGroup>
                                    </ButtonContainer>
                                </ContentContainer>
                            </form>
                        )}
                    </TablePageContent>
                </Box>
            </Box>
            )
        </>
    ));
};

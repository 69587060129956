// Libraries
import React from "react";
import { useObserver } from "mobx-react-lite";
import { theme } from "../../../Globals/Styles/AppTheme";
// Custom

// Components
import { ButtonsContainer, ModalBase, ModalHeader } from "./ModalComponents";
// Styling & images
import { Button, Typography } from "@mui/material";

import { DeleteSVG } from "../../../Globals/Views/IconSVGs/DeleteSVG";

export interface IDeleteModalProps {
    isActive: boolean;
    confirmClose(confirm: boolean): void;
    onCancel(): void;
    title: string;
    message: string;
}

export const DeleteModal: React.FC<IDeleteModalProps> = (props: IDeleteModalProps) => {
    const warningMessage = (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", maxWidth: "316px" }}>
            <div
                style={{
                    borderRadius: "50%",
                    backgroundColor: `${theme.palette.warning.main}`,
                    fontSize: "70px",
                    color: `${theme.palette.common.white}`,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100px",
                    width: "100px",
                    fontFamily: "'Manrope'",
                    marginBottom: "25px",
                }}
            >
                <span style={{ fontWeight: "bold" }}>!</span>
            </div>
            <div>
                <Typography variant="body2" align="center">
                    {props.message}
                </Typography>
            </div>
        </div>
    );

    return useObserver(() => (
        <>
            <ModalBase open={props.isActive} onClose={() => props.onCancel()}>
                <div className="modalcontainer">
                    <ModalHeader color={theme.palette.warning.main} title={props.title} />
                    <div className="modalclosebtn" onClick={() => props.onCancel()}>
                        <DeleteSVG className="file-delete" />
                    </div>
                    <div className="modalbody">
                        {warningMessage}

                        <ButtonsContainer color={theme.palette.warning.main}>
                            <Button
                                disableElevation
                                id="save"
                                variant="contained"
                                onClick={() => {
                                    props.confirmClose(true);
                                }}
                                style={{ backgroundColor: `${theme.palette.warning.main}`, marginRight: "25px" }}
                            >
                                Yes
                            </Button>

                            <Button
                                disableElevation
                                id="cancel"
                                variant="contained"
                                onClick={() => {
                                    props.onCancel();
                                }}
                            >
                                No
                            </Button>
                        </ButtonsContainer>
                    </div>
                </div>
            </ModalBase>
        </>
    ));
};

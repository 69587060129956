import { makeStyles } from "@mui/styles";
import styled from "@emotion/styled";
import { theme } from "../../Globals/Styles/AppTheme";

export const AppBarContainer = styled.div`
    .MuiToolbar-root {
        min-height: 60px;
    }
`;

export const ListContainer = styled("div")`
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
`;

export const useStyles = makeStyles({
    button: {
        textDecoration: "none",
        color: theme.palette.customText.secondary,
        fontSize: "12px",
        fontWeight: "bold",
        height: "60px",
        width: "fit-content",
        padding: "0px 22px !important",
        borderLeft: `1px solid ${theme.palette.healthAppyBlue.light}`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "transparent",

        "&:last-child": {
            borderRight: `1px solid ${theme.palette.healthAppyBlue.light}`,
        },

        "&:hover": {
            color: theme.palette.common.white,
            transition: "backgroundColor 1s ease-in",
            backgroundColor: `${theme.palette.healthAppyBlue.main} !important`,
            "& svg": {
                fill: `${theme.palette.common.white} !important`,
            },
        },

        "& .MuiListItem-root": {
            padding: "0px !important",
        },
    },
    isActive: {
        color: theme.palette.common.white,
        transition: "backgroundColor 1s ease-in",
        backgroundColor: `${theme.palette.healthAppyBlue.main} !important`,
        "& svg": {
            fill: `${theme.palette.common.white} !important`,
        },
    },
});

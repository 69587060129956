import { Box, Tab } from "@mui/material";
import { TabsCustom, useAdminStyles } from "Globals/Styles/AdminStyling";

import React from "react";
import { RolesList } from "./Roles/RolesList";
import Typography from "@mui/material/Typography";
import { UsersList } from "./Users/UsersList";
import { useObserver } from "mobx-react-lite";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <Typography component="div" role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            <Box>{children}</Box>
        </Typography>
    );
}

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export const Admin: React.FC = () => {
    const classes = useAdminStyles();
    const [value, setValue] = React.useState(0);

    function handleChange(event: React.ChangeEvent<{}>, newValue: number) {
        setValue(newValue);
    }

    return useObserver(() => (
        <>
            <Box>
                <Box display="flex" justifyContent="flex-start" marginLeft="40px">
                    <TabsCustom value={value} onChange={handleChange} aria-label="simple tabs example" className={classes.tabsWidth}>
                        <Tab label="Manage users" {...a11yProps(0)} />
                        <Tab label="Manage roles" {...a11yProps(1)} />
                    </TabsCustom>
                </Box>

                <TabPanel value={value} index={0}>
                    <UsersList />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <RolesList />
                </TabPanel>
            </Box>
        </>
    ));
};

// Libraries
import React, { useRef, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { EditableCopyViewModel } from "Globals/ViewModels/EditableCopyViewModel";
import styled from "@emotion/styled";

// Styling & images
import { GridSpaced } from "Globals/Styles/GridHelperStyling";

export const PrivacyPolicyView: React.FC = () => {
    window.scrollTo(0, 0);
    const { current: viewModel } = useRef(new EditableCopyViewModel());

    const privacy = (
        <>
            <GridSpaced container justify={"center"} margin={"0px auto"} padding={"0px 0px 70px 0px"} additionalcss={"max-width: 1124px; width: 100%;"}>
                <TermsContainer item xs={12} sm={12}>
                    <p>{ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/privacy-policy", 1))}</p>
                    {ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/privacy-policy", 2))}
                </TermsContainer>
            </GridSpaced>
        </>
    );

    return (
        <>
            <div>{privacy}</div>
        </>
    );
};

const TermsContainer = styled(GridSpaced)`
    p {
        font-size: 14px;
        margin-bottom: 15px;
        letter-spacing: -0.35px;
        line-height: 24px;
    }

    h1 {
        text-align: left;
        font-size: 28px;
    }

    h2 {
        text-align: left;
        font-size: 24px;
    }
`;

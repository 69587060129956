import React from "react";
import { AlertProps, Box, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { observer, useObserver } from "mobx-react-lite";
import { DomainStores } from "./Stores";
import { container } from "tsyringe";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const CommonInjects = observer(() => {
    const domainStores = container.resolve(DomainStores);

    return (
        <Box id={"main-snackbar"}>
            <Snackbar open={domainStores.openSnackBar} anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={6000} onClose={() => domainStores.CloseSnackBar()}>
                <Alert onClose={() => domainStores.CloseSnackBar()} severity={domainStores.snackBarSeverity}>
                    {domainStores.snackBarContent}
                </Alert>
            </Snackbar>
        </Box>
    );
});

import { Box } from "@mui/material";
import React, { useState } from "react";
import { useObserver } from "mobx-react-lite";
import Lottie from "lottie-react-web";
import pinJumpAnimation from "./pinjump.json";
import hbAnimation from "./housebuilderlottie.json";

export const LottieView: React.FC = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: pinJumpAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };
    const defaultOptionsHB = {
        loop: true,
        autoplay: true,
        animationData: hbAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };
    return useObserver(() => (
        <>
            <Box p={2} width={"100%"}>
                <Lottie options={defaultOptionsHB} height={400} />
            </Box>
            <Box p={2} width={"400px"}>
                <Lottie options={defaultOptions} height={400} width={400} />
            </Box>
        </>
    ));
};

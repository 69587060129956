import { Box, Button, TextField } from "@mui/material";
import { getHistory } from "@shoothill/core";
import { AppUrls } from "../../../AppUrls";
import { theme } from "../../../Globals/Styles/AppTheme";
import React, { useContext, useState } from "react";
import styled from "@emotion/styled";
import AdminFaqsListViewModel, { AdminFAQTableDataModel } from "../../../Globals/ViewModels/Admin/AdminFAQsListViewModel";
import { NewFAQModal } from "../Modals/NewFAQModal";

import { useObserver } from "mobx-react-lite";
import MaterialTable, { MTableToolbar } from "@material-table/core";
import Typography from "@mui/material/Typography";

//custom
import { TablePageContent } from "../../../Globals/Components/General/General.styles";
import { ToolbarContainer } from "../../../Globals/Components/Table/ToolbarContainer";
import { HHButton } from "Globals/Components/General/Buttons/HHButton";
import { DeleteSVG } from "../../../Globals/Views/IconSVGs/DeleteSVG";
import { DeleteModal } from "../Modals/DeleteModal";
import { AdminSecondaryToolbar, ToolbarTitle } from "Globals/Components/General/General.styles";
import { HHTable } from "Globals/Components/Table/HHTable";

export const AdminFAQsListView: React.FC = () => {
    const [filter, setFilter] = useState("");
    const [viewModel] = useState(new AdminFaqsListViewModel());
    const [newFAQModal, setNewFAQModal] = useState(false);
    const [deleteFAQModal, setDeleteFAQModal] = useState(false);
    const [faqIdToDelete, setFAQIdToDelete] = useState<string | null>(null);

    const modals = () => (
        <>
            {newFAQModal && (
                <NewFAQModal
                    isActive={newFAQModal}
                    confirmClose={() => {
                        viewModel.loadData("");
                        setNewFAQModal(false);
                    }}
                    onCancel={() => setNewFAQModal(false)}
                />
            )}
            {deleteFAQModal && (
                <DeleteModal
                    isActive={deleteFAQModal}
                    confirmClose={() => {
                        if (faqIdToDelete) {
                            viewModel.deleteFAQ(faqIdToDelete);
                        }
                        setDeleteFAQModal(false);
                    }}
                    onCancel={() => {
                        setFAQIdToDelete(null);
                        setDeleteFAQModal(false);
                    }}
                    title="Delete FAQ"
                    message="This will delete the FAQ, are you sure you want to do this?"
                />
            )}
        </>
    );

    const columns = [
        { title: "Order", field: "orderPosition", width: "5%" },
        { title: "Question", field: "question", width: "66%" },
        { title: "Date added", field: "createdDate", width: "9%" },
        {
            title: "Last amended",
            field: "lastUpdatedDate",
            width: "15%",
            render: (rowData: AdminFAQTableDataModel) => (
                <>
                    {rowData.lastUpdatedDate} by {rowData.firstName} {rowData.lastName}
                </>
            ),
        },
    ];

    const actions = [
        (rowData: AdminFAQTableDataModel) => ({
            icon: () => <DeleteSVG />,
            tooltip: "Delete FAQ",
            onClick: () => {
                setFAQIdToDelete(rowData.id);
                setDeleteFAQModal(true);
            },
        }),
    ];

    return useObserver(() => (
        <>
            <Box style={{ padding: "0", margin: 0, width: "100%" }}>
                <AdminSecondaryToolbar>
                    <ToolbarTitle>FAQ</ToolbarTitle>
                    <HHButton onClick={() => setNewFAQModal(true)} text="Add new question" width="304px" />
                </AdminSecondaryToolbar>
                <Box display="flex" width="100%">
                    <TablePageContent>
                        <HHTable
                            onSearchChange={(query: any) => {
                                //     //if (viewModel.customers.length > 600) {
                                viewModel.loadData(query);
                                //     //}
                            }}
                            columns={columns}
                            data={viewModel.getTableData}
                            components={{
                                Toolbar: (props: any) => (
                                    <ToolbarContainer style={{ height: 1, paddingTop: 20 }}>
                                        <MTableToolbar {...props} />
                                    </ToolbarContainer>
                                ),
                            }}
                            // onRowClick={(event: any, rowData: { id: string }, togglePanel: any) => {
                            //     if (!rowData) return;
                            //     getHistory().push(AppUrls.Client.Admin.CustomerUsers.replace(":customerId?", rowData.id));
                            // }}
                            title=""
                            actions={actions}
                            options={{
                                search: false,
                                rowStyle: {
                                    fontSize: "12px",
                                },
                                actionsColumnIndex: -1,
                                tableLayout: "fixed",
                            }}
                            localization={{
                                toolbar: {
                                    searchPlaceholder: "Keyword search",
                                },
                                header: {
                                    actions: "",
                                },
                            }}
                            isLoading={viewModel.IsLoading}
                        />
                    </TablePageContent>
                </Box>
            </Box>
            {modals()}
        </>
    ));
};

import { Box } from "@mui/material";
import React, { useRef, useState } from "react";
import { ExampleViewModel } from "./ExampleViewModel";
import { ExampleModel } from "./ExampleModel";
import { EditText2 } from "../Globals/Views/Primitives/EditText2";
import { useObserver } from "mobx-react-lite";
import { EditText, IKeyState } from "../Globals/Views/Primitives/EditText";
import { GenericTextUpdateCommand } from "../Globals/Commands/GenericTextUpdateCommand";
import { useViewModel } from "../Globals/Hooks/UseViewModel";
import { TestModel } from "./TestModel";

export const TextInputs: React.FC = () => {
    const [viewModel] = useState(() => new ExampleViewModel());

    (window as any).vm = viewModel;

    return useObserver(() => (
        <>
            <Box p={2} width={"400px"}>
                <EditText
                    displayName="First Name"
                    command={viewModel.updateFirstNameCommand}
                    {...useViewModel<TestModel>(viewModel, "firstName", "text")}
                    /*value={() => viewModel.getValue("firstName")}
                    validationMessage={() => viewModel.getError("firstName")}*/
                />
            </Box>
            <Box p={2}>
                <EditText
                    displayName="Last Name"
                    value={() => viewModel.getValue("lastName")}
                    command={viewModel.updateLastNameCommand}
                    validationMessage={() => viewModel.getError("lastName")}
                />
                <br />
                <span>Dirty:{viewModel.getDirty("lastName") ? <span>true</span> : <span>false</span>}</span>
                <br />
                <span>Valid:{viewModel.getValid("lastName") ? <span>true</span> : <span>false</span>}</span>
            </Box>
        </>
    ));
};

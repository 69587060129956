import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { ApiResult, FieldType, generateID, HttpClient, KeyValuePair, ViewModelBase } from "@shoothill/core";
import { container } from "tsyringe";

// App
import { NewProfileModel, NewProfileValidator } from "../../../Globals/Models/Domain/Admin/NewProfileModel";
import { Category } from "../../../Globals/Models/Category";
import { UserFile } from "../../../Globals/Models/UserFile";
import { DomainStores } from "../../../Globals/Stores";
import { RelayCommandAsync, ICommandAsync } from "../../../Globals/Commands";
import { IKeyState } from "../../../Globals";
import NewProfileQuestionAnswerViewModel from "./NewProfileQuestionAnswerViewModel";
import { NewProfileQuestionAnswerModel } from "Globals/Models/Domain/Admin/NewProfileQuestionAnswerModel";
import { AppUrls } from "AppUrls";
import { CategoryColours } from "Globals/Models/CategoryColours";

// import { Link as RouterLink, useLocation, Redirect, useHistory } from "react-router-dom";

export default class NewProfileViewModel extends ViewModelBase<NewProfileModel> {
    private httpClient = container.resolve(HttpClient);
    private domainStores = container.resolve(DomainStores);
    public filesToDisplay: UserFile[] = [];

    constructor() {
        super(new NewProfileModel());
        this.setValidator(new NewProfileValidator());
        (window as any).vm = this;
    }
    private async updateField(fieldName: keyof FieldType<NewProfileModel>, value: any, keyState?: IKeyState) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    public updateProfileQuestionIdCommand: ICommandAsync = new RelayCommandAsync(async (value: string) => {
        await this.updateField("profileQuestionId", value);
    });

    public updateCategoryCommand: ICommandAsync = new RelayCommandAsync(async (value: KeyValuePair) => {
        await this.updateField("categoryId", value.value);
    });

    public updateQuestionCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("question", value, keyState);
    });

    public updateQuestionOrderPositionCommand: ICommandAsync = new RelayCommandAsync(async (value: KeyValuePair) => {
        await this.updateField("questionOrderPosition", value.value);
    });

    public updateTmpAnswerTitle: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("tmpAnswerTitle", value, keyState);
    });

    public updateTmpAnswerScore: ICommandAsync = new RelayCommandAsync(async (value: number, keyState: IKeyState) => {
        await this.updateField("tmpAnswerScore", value, keyState);
    });

    public orderAnswers = async (dragIndex: number, hoverIndex: number) => {
        let tmpArr = this.getValue<NewProfileQuestionAnswerViewModel[]>("answers");
        tmpArr.splice(hoverIndex, 0, tmpArr.splice(dragIndex, 1)[0]);
        await this.updateField("answers", tmpArr);
    };

    public updateAddAnswerIsDisabledCommand = (): boolean => {
        let retVal = true;
        if (this.getSelectedCategoryOption.label !== "Please select" && Number(this.getTotalScore) < 101) {
            retVal = false;
        }
        return retVal;
    };

    public get getTotalScore(): string {
        let retVal: number = 0;
        let answers = this.getValue<NewProfileQuestionAnswerViewModel[]>("answers");
        answers.forEach((answer: NewProfileQuestionAnswerViewModel, index) => {
            retVal += Number(answer.model.answerScore);
        });
        this.setValue("answersTotalScore", retVal);
        this.isFieldValid("answersTotalScore");
        return retVal.toString();
    }

    private checkAnswers = async () => {
        let retval = true;

        let answers = this.getValue<NewProfileQuestionAnswerViewModel[]>("answers");
        answers.some((answer) => {
            if (!answer.isModelValid()) {
                retval = false;
                return true;
            }
        });
        return retval;
    };

    public addNewAnswer = async (): Promise<boolean> => {
        console.log("Add new answers.");
        let answerVM = new NewProfileQuestionAnswerViewModel();
        let answers = this.getValue<NewProfileQuestionAnswerViewModel[]>("answers");

        answerVM.setValue("answerOrderPosition", answers.length + 1);
        answerVM.setValue("answerText", this.getValue<any>("tmpAnswerTitle"));
        answerVM.setValue("answerScore", this.getValue<number>("tmpAnswerScore"));

        answers.push(answerVM);
        this.setValue("answers", answers);
        this.setValue("tmpAnswerTitle", "");
        this.setValue("tmpAnswerScore", 1);

        return true;
    };

    public removeAnswer = async (id: any): Promise<boolean> => {
        if (!this.checkAnswers()) return false;
        let answers = this.getValue<NewProfileQuestionAnswerViewModel[]>("answers");
        const answer: NewProfileQuestionAnswerViewModel | undefined = answers.find((a) => a.getValue("answerId") === id);
        return true;
    };

    public get getAnswersOrderPosition() {
        let answers = this.domainStores.categories.find((e) => e.id === this.getModel.categoryId);
        return answers;
    }

    public get getCategoryColors(): CategoryColours[] {
        let retval: CategoryColours[] = [];
        retval = this.getCategoriesSortedByOrdinal.map((category: Category) => {
            return { id: category.id, name: category.name, colour: category.colour, accentColour: category.accentColour };
        });

        return retval;
    }

    // public deleteAnswer = async (): Promise<boolean> => {};

    // public save = async (userFile: UserFile): Promise<void> => {
    //     try {
    //         let valid = await this.isModelValid();
    //         if (isDeleted) {
    //             this.setIsLoading(true);

    //             runInAction(() => {
    //                 this.model.mainImageFileName = userFile.fileName;
    //                 this.model.mainImageUrl = userFile.fileUrl;
    //             });

    //             let upsertCreditReport: CreditReportDTO = this.model.toDto();
    //             const apiResult = await this.Post<CreditReportDTO>(AppUrls.Server.ApplicationForm.Upsert.CreditReport, upsertCreditReport);

    //             if (apiResult.wasSuccessful) {
    //                 this.model.fromDto(apiResult.payload);
    //                 console.info("CreditReport:Upsert-Successful");

    //                 let armedForcesProofModel = new CreditReportModel();
    //                 armedForcesProofModel.fromDto(apiResult.payload);
    //                 let addressViewModel = new CreditReportViewModel(armedForcesProofModel);
    //                 const filtered = parent.creditReportViewModels.filter((a) => a.model.isDeleted == false);

    //                 runInAction(() => {
    //                     if (isDeleted) {
    //                         parent.creditReportViewModels = filtered;
    //                     } else {
    //                         parent.creditReportViewModels.push(addressViewModel);
    //                     }
    //                 });

    //                 parent.handleInputChange();

    //                 this.model.resetModel();
    //                 return Promise.resolve();
    //             } else {
    //                 console.error("CreditReport:Upsert-Error", apiResult.errors);
    //                 return Promise.reject(apiResult.errors);
    //             }
    //         }
    //     } catch (exception) {
    //         console.error(exception);
    //     } finally {
    //         this.saveStore.setIsSaving(false);
    //         this.setIsLoading(false);
    //     }
    // };

    public createNewProfile = async (): Promise<boolean> => {
        this.setIsLoading(true);
        let isMyModelValid: boolean = await this.isModelValid();
        if (isMyModelValid) {
            let modelToSave = this.getModel;
            // let newContent: NewContent = {
            //     title: modelToSave.title,
            //     contactName: modelToSave.contactName,
            //     contactEmail: modelToSave.contactEmail,
            //     contactNumber: modelToSave.contactNumber,
            // };

            // const apiResult = await this.httpClient.Post<NewCustomer>(AppUrls.Server.Admin.CreateNewCustomer, newCustomer);
            // if (apiResult.wasSuccessful) {
            //     this.history.push("/");
            //     return true;
            // } else {
            //     apiResult.errors.forEach((error) => {
            //         this.domainStores.OpenSnackBar(`Error: ${error.message}`, "error");
            //     });
            //     return false;
            // }
            return true;
        }

        return false;
    };

    public get getCategoriesSortedByOrdinal(): Category[] {
        return this.domainStores.categories.slice().sort((a, b) => (a.ordinal < b.ordinal ? -1 : 1));
    }

    public get getCategoryOptions(): KeyValuePair[] {
        let placeholder = {
            label: "Please select",
            value: "placeholder",
        } as KeyValuePair;

        let options = this.getCategoriesSortedByOrdinal.map((value) => ({ value: value.id, label: value.name })) as KeyValuePair[];
        options.unshift(placeholder);

        return options;
    }

    public get getQuestionOrderPositionOptions(): KeyValuePair[] {
        let placeholder = [] as KeyValuePair[];

        (() => {
            for (let i = 1; i <= 10; i++) {
                placeholder.push({ label: i.toString(), value: i.toString() } as KeyValuePair);
            }
        })();

        return [{ label: "Please select", value: "placeholder" }, ...placeholder] as KeyValuePair[];
    }

    public get getUserFiles(): UserFile[] {
        return this.filesToDisplay;
    }

    public setUserFiles = async (userFile: UserFile[]): Promise<boolean> => {
        this.filesToDisplay = userFile;
        return true;
    };

    public get getSelectedCategoryOption(): KeyValuePair {
        let category = this.domainStores.categories.find((e) => e.id === this.getModel.categoryId);
        if (category) {
            let selectedOption = {
                label: category.name,
                value: category.id,
            } as KeyValuePair;

            return selectedOption;
        }

        return { label: "Please select", value: "placeholder" } as KeyValuePair;
    }

    public async newProfileQuestionAndAnswersAsync(data: any): Promise<ApiResult<any>> {
        const apiResult = await this.httpClient.Post<any>(AppUrls.Server.Admin.CreateNewQuestionAnswer, data);
        this.history.push("/admin/profiles");
        return apiResult;
    }

    public async getProfileQuestionAndAnswersAsync(Id: string): Promise<ApiResult<any>> {
        const apiResult = await this.httpClient.Get<any>(AppUrls.Server.Admin.GetQuestionAnswer + "/" + Id);
        return apiResult;
    }

    public async updateProfileSummariesAsync(data: any): Promise<ApiResult<any>> {
        const apiResult = await this.httpClient.Post<any>(AppUrls.Server.Admin.UpdateProfileSummary, data);
        this.history.push("/admin/profiles");
        return apiResult;
    }

    public async getProfileSummaryByCategoryIdAsync(categoryId: string): Promise<ApiResult<any>> {
        const apiResult = await this.httpClient.Get<any>(AppUrls.Server.Admin.GetProfileSummaryByCategoryId + "/" + categoryId);
        return apiResult;
    }
}

import { ForgotPasswordModel, ForgotPasswordValidator, LoginModel } from "Globals/Models";
import { action, makeObservable, observable, runInAction } from "mobx";

import { AppUrls } from "AppUrls";
import { ApiResult, FieldType, HttpClient, Validation, ViewModelBase } from "@shoothill/core";
import { container } from "tsyringe";
import { ICommandAsync, RelayCommandAsync } from "../../Globals/Commands";
import { IKeyState } from "../../Globals";

export class ForgotPasswordViewModel extends ViewModelBase<ForgotPasswordModel> {
    private httpClient = container.resolve(HttpClient);
    public errorMessage: string = "";

    constructor() {
        super(new ForgotPasswordModel());
        this.setValidator(new ForgotPasswordValidator());
        makeObservable(this, {
            errorMessage: observable,
        });
    }

    public forgotPasswordCommand: ICommandAsync = new RelayCommandAsync(
        async () => {
            this.setIsLoading(true);

            const apiResult: any = await this.httpClient.Post<ForgotPasswordModel>(AppUrls.Server.Account.ForgotPassword, this.model);
            this.setIsLoading(false);
            if (apiResult !== null) {
                if (apiResult.wasSuccessful) {
                    this.history.push(`/forgotpassword-success`);
                } else {
                    if (apiResult && apiResult.errors && apiResult.errors.length > 0) {
                        this.errorMessage = "* " + apiResult.errors[0].message;
                    } else {
                        this.errorMessage = "* Unknown error has occurred.";
                    }
                }
            }
        },
        () => {
            return Promise.resolve(this.isModelValid());
        },
    );
    public updateEmailAddressCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        this.setValue("emailAddress", value);
        this.isFieldValid("emailAddress");
    });
}

// Libraries
import { generateID, isEmptyOrWhitespace, isNullOrUndefined, KeyValuePair } from "@shoothill/core";
import { observer } from "mobx-react-lite";
import React from "react";
import { FormControl, Radio, FormControlLabel, FormLabel, RadioGroup } from "@mui/material";
import { ICommandAsync } from "../../Commands";

interface IProps {
    value: () => string;
    className?: string;
    displayName?: string;
    getOptionLabel?: (option: any) => string;
    groupBy?: (option: any) => string;
    gutterBottom?: boolean;
    options: KeyValuePair[];
    placeholder?: string;
    validationMessage?: () => string;

    command: ICommandAsync;
}

export const RadioInput: React.FC<IProps> = observer((props: IProps) => {
    /* const getClasses = () => {
        return clsx({
            [props.className!]: !isEmptyOrWhitespace(props.className),
            ["select"]: true,
            ["selectGutterBottom"]: hasGutterBottom(),
        });
    };*/

    const getId = (): string => {
        return "selectControlId";
    };

    const getPlaceholder = (): string => {
        return !isEmptyOrWhitespace(props.placeholder) ? props.placeholder! : "";
    };

    const getValidationMessage = (): string => {
        return isEmptyOrWhitespace(props.validationMessage?.() as string) ? "" : (props.validationMessage?.() as string);
    };

    const hasGutterBottom = (): boolean => {
        return isNullOrUndefined(props.gutterBottom) ? false : props.gutterBottom!;
    };

    const isDisabled = (): boolean => {
        return isNullOrUndefined(props.command.canExecute) ? false : !props.command.canExecute();
    };

    const hasError = (): boolean => {
        return !isEmptyOrWhitespace(props.validationMessage?.() as string);
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>, value: any): void => {
        if (props.command.execute) props.command.execute(value);
    };

    return (
        <FormControl component="fieldset">
            <FormLabel component="legend">Gender</FormLabel>
            <RadioGroup aria-label="gender" defaultValue={props.value} onChange={onChange} name={props.displayName}>
                {props.options.map((item: KeyValuePair, index: number) => (
                    <FormControlLabel key={generateID(props.displayName)} value={item.value} control={<Radio />} label={item.label} />
                ))}
            </RadioGroup>
        </FormControl>
    );

    // #endregion Public Interface
});

import React from "react";
import { Box, Paper, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { AutoGrid, AutoCell } from "Globals/Components/Grid";

const StyledBox = styled(Box)`
    border: 2px solid rgb(233, 171, 88);
    border-radius: 5px;
    background-color: rgba(233, 171, 88, 0.5);
    padding: 1em;
    color: #d9480f;
`;
const StyledCell = styled(AutoCell)`
    border: 2px solid rgb(233, 171, 88);
    border-radius: 5px;
    background-color: rgba(233, 171, 88, 0.5);
    padding: 1em;
    color: #d9480f;
`;
//https://styled-css-grid.js.org/
export const GridLayouts: React.FC = () => {
    return (
        <>
            <Box height={100} width="99%" bgcolor="background.paper">
                <Paper>
                    <Typography variant="h5">Basic Grid</Typography>
                    <AutoGrid dc={"repeat(12, 1fr)"}>
                        <StyledBox>1/12</StyledBox>
                        <StyledBox>2/12</StyledBox>
                        <StyledBox>3/12</StyledBox>
                        <StyledBox>4/12</StyledBox>
                        <StyledBox>5/12</StyledBox>
                        <StyledBox>6/12</StyledBox>
                        <StyledBox>7/12</StyledBox>
                        <StyledBox>8/12</StyledBox>
                        <StyledBox>9/12</StyledBox>
                        <StyledBox>10/12</StyledBox>
                        <StyledBox>11/12</StyledBox>
                        <StyledBox>12/12</StyledBox>
                    </AutoGrid>
                </Paper>
                <br />
                <Paper>
                    <Typography variant="h5">Responsive Grid</Typography>
                    <AutoGrid dc="repeat(auto-fit,minmax(400px,1fr))">
                        <StyledBox>A</StyledBox>
                        <StyledBox>B</StyledBox>
                        <StyledBox>C</StyledBox>
                        <StyledBox>D</StyledBox>
                    </AutoGrid>
                </Paper>
                <br />
                <Paper>
                    <Typography variant="h5">Holy Grail Grid</Typography>
                    <AutoGrid dc={"100px 1fr 100px"} dr={"minmax(45px,auto) 1fr minmax(45px,auto)"}>
                        <StyledCell ds={3}>
                            <h1>Header</h1>
                        </StyledCell>
                        <StyledCell>Menu</StyledCell>
                        <StyledCell>Content</StyledCell>
                        <StyledCell>Ads</StyledCell>
                        <StyledCell ds={3}>Footer</StyledCell>
                    </AutoGrid>
                </Paper>
                <br />
                <Paper>
                    <Typography variant="h5">Grid Areas</Typography>
                    <AutoGrid
                        dc={"100px 1fr 100px"}
                        dr={"5fr 5fr 5fr"}
                        /* prettier-ignore */
                        da={[
                          "header header  header",
                          "menu   content ads   ",
                          "footer footer  footer"
                        ]}
                    >
                        <StyledCell da="header">Header</StyledCell>
                        <StyledCell da="content">Content</StyledCell>
                        <StyledCell da="menu">Menu</StyledCell>
                        <StyledCell da="ads">Ads</StyledCell>
                        <StyledCell da="footer">Footer</StyledCell>
                    </AutoGrid>
                </Paper>
                <br />
                <Paper>
                    <Typography variant="h5">Sub Grid</Typography>
                    <AutoGrid dc={"1fr 1fr 1fr 1fr"} rowGap={"10px"}>
                        <AutoGrid dc={"1fr"} rowGap={"10px"}>
                            <StyledBox>1</StyledBox>
                            <StyledBox>2</StyledBox>
                            <StyledBox>3</StyledBox>
                            <StyledBox>4</StyledBox>
                        </AutoGrid>
                        <StyledBox>Two</StyledBox>
                        <StyledBox>Three</StyledBox>
                        <StyledBox>Four</StyledBox>
                    </AutoGrid>
                </Paper>

                <Paper>
                    <Typography variant={"h5"}>Auto Grid</Typography>
                    <AutoGrid mc={"1fr"} dc={"1fr 1fr"}>
                        <StyledBox>Column 1</StyledBox>
                        <StyledBox>Column 2</StyledBox>
                        <StyledBox>Column 3</StyledBox>
                        <StyledBox>Column 4</StyledBox>
                    </AutoGrid>
                </Paper>

                <Paper>
                    <Typography variant={"h5"}>Auto Grid With Areas</Typography>
                    <AutoGrid ma={["top", "middle", "bottom"]} da={["top", "middle", "bottom"]}>
                        <AutoCell mobileArea={"bottom"} desktopArea={"top"}>
                            <StyledBox>Top</StyledBox>
                        </AutoCell>
                        <AutoCell mobileArea={"middle"} desktopArea={"middle"}>
                            <StyledBox>Middle</StyledBox>
                        </AutoCell>
                        <AutoCell mobileArea={"top"} desktopArea={"bottom"}>
                            <StyledBox>Bottom</StyledBox>
                        </AutoCell>
                    </AutoGrid>
                </Paper>
                <br />
                <br />
                <br />
                <br />
                <br />
            </Box>
        </>
    );
};

import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "../../Globals/Validation";
import { RegisterUserModel } from "./RegisterUserModel";

export class RegisterUserDetailsModel extends ModelBase<RegisterUserDetailsModel> {
    public age: number | string = "";
    public companyName: string = "";
    public position: string = "";
    public companyWebsite: string = "";
    public companyRegNo: string = "";
    public companyVatNo: string = "";
    public address1: string = "";
    public address2: string = "";
    public city: string = "";
    public county: string = "";
    public postCode: string = "";
    public gender: string = "";
    public dob: Date = new Date();
    public saveAddress: boolean = false;

    constructor() {
        super();
        makeObservable(this, {
            age: observable,
            companyName: observable,
            position: observable,
            companyWebsite: observable,
            companyRegNo: observable,
            companyVatNo: observable,
            address1: observable,
            address2: observable,
            city: observable,
            county: observable,
            postCode: observable,
            gender: observable,
            dob: observable,
            saveAddress: observable,
        });
    }
}

export class RegisterUserDetailsValidator extends Validator<RegisterUserDetailsModel> {
    constructor() {
        super();

        this.ruleFor("companyName").emailAddress().maxLength(50);
    }
}

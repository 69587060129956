import { Box, FormControl, MenuItem, Select } from "@mui/material";
import React, { useRef, useState } from "react";
import { KeyValuePair } from "@shoothill/core";
import { ExampleViewModel } from "./ExampleViewModel";
import { useObserver } from "mobx-react-lite";
import { RadioInput } from "../Globals/Views/Primitives/RadioInput";
import { useViewModel } from "../Globals/Hooks/UseViewModel";

export const RadioInputExample: React.FC = () => {
    const { current: viewModel } = useRef(new ExampleViewModel());

    return useObserver(() => (
        <>
            <Box p={2} width={"400px"}>
                <RadioInput
                    displayName={"Gender"}
                    options={
                        [
                            { label: "Male", value: "male" },
                            { label: "Female", value: "female" },
                            { label: "Prefer not to say", value: "any" },
                        ] as KeyValuePair[]
                    }
                    {...useViewModel(viewModel, "gender", "radio")}
                />
                <label>Gender Selected: {viewModel.getValue("gender")}</label>
            </Box>
            <Box p={2} width={"400px"}>
                <RadioInput displayName={"Teams"} options={viewModel.getTeams} value={() => viewModel.getValue("team")} command={viewModel.updateTeamRadioCommand} />
                <label>Team Selected: {viewModel.getValue("team")}</label>
            </Box>
        </>
    ));
};

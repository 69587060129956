import { Box, Button, Modal } from "@mui/material";
import styled from "@emotion/styled";
import { theme } from "../../../Globals/Styles/AppTheme";
import NewFAQViewModel from "../../../Globals/ViewModels/Admin/NewFAQViewModel";
import { EditText } from "../../../Globals/Views/Primitives/EditText";
import React, { useEffect, useContext, useState } from "react";
import { useObserver } from "mobx-react-lite";
import Typography from "@mui/material/Typography";
import { AutoGrid } from "../../../Globals";
import { DeleteSVG } from "../../../Globals/Views/IconSVGs/DeleteSVG";
import { ButtonsContainer, ModalBase, ModalHeader } from "./ModalComponents";

interface IProps {
    isActive: boolean;
    confirmClose(confirm: boolean): void;
    onCancel(): void;
}

export const NewFAQModal: React.FC<IProps> = (props) => {
    const [viewModel] = useState(() => new NewFAQViewModel());
    const [errorMessage, setErrorMessage] = useState("");
    useEffect(() => {}, []);

    return useObserver(() => (
        <>
            <ModalBase open={props.isActive} onClose={() => props.onCancel()}>
                <div>
                    <ModalHeader color={theme.palette.healthAppyBlue.main} title={"Add new question"} />
                    <div className="modalclosebtn" onClick={() => props.onCancel()}>
                        <DeleteSVG className="file-delete" />
                    </div>
                    <Box className="modalbody">
                        <Box className="content" style={{ width: " 100%" }}>
                            {errorMessage !== "" && (
                                <Typography variant="caption" style={{ color: "red" }}>
                                    {errorMessage}
                                </Typography>
                            )}
                            <AutoGrid desktopColumns={"1fr"}>
                                <EditText
                                    displayName="Question"
                                    command={viewModel.updateQuestionCommand}
                                    value={() => viewModel.getValue("question")}
                                    validationMessage={() => viewModel.getError("question")}
                                    type={"text"}
                                />
                            </AutoGrid>
                            <AutoGrid desktopColumns={"1fr"}>
                                <EditText
                                    displayName="Answer"
                                    command={viewModel.updateAnswerCommand}
                                    value={() => viewModel.getValue("answer")}
                                    validationMessage={() => viewModel.getError("answer")}
                                    type={"textarea"}
                                    rows={4}
                                    multiline={true}
                                    className="modaltextarea"
                                />
                            </AutoGrid>
                        </Box>
                        <ButtonsContainer color={theme.palette.healthAppyBlue.main}>
                            <Button
                                disableElevation
                                variant="contained"
                                onClick={async () => {
                                    let close = await viewModel.createNewFAQ();
                                    if (close == true) {
                                        props.confirmClose(true);
                                    }
                                }}
                                style={{ backgroundColor: `${theme.palette.healthAppyBlue.main}` }}
                            >
                                Create
                            </Button>

                            <Button disableElevation variant="contained" onClick={() => props.onCancel()}>
                                Cancel
                            </Button>
                        </ButtonsContainer>
                    </Box>
                </div>
            </ModalBase>
        </>
    ));
};

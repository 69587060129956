import { createStyles, styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { theme } from "../../Styles/AppTheme";
import Chip from "@mui/material/Chip";
import { FormControl } from "@mui/material";

export interface IHHProps {
    customcolour: string;
}

export const ChipsContainer = styled("div")`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px 30px;
`;

export const HHChip = styled(Chip)<IHHProps>`
    width: 173px;
    height: 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    background: ${(props: IHHProps) => (props.customcolour === "transparent" ? theme.palette.info.light : props.customcolour)};
    color: white;
    font-size: 12px;

    .MuiSvgIcon-root {
        height: 15px;
        width: 15px;
        :hover {
            opacity: 0.7;
        }
    }
`;

export const HHFormControl = styled(FormControl)<IHHProps>`
    .MuiAutocomplete-root {
        padding: 0 !important;
        :hover {
            background: ${(props: IHHProps) => props.customcolour};
            color: ${(props: IHHProps) => (props.customcolour === "transparent" ? theme.palette.text.primary : theme.palette.common.white)};
        }
    }

    .MuiOutlinedInput-root {
        height: 28px;
        background-color: ${theme.palette.common.white};
        border-radius: 0;
        padding: 0 !important;
        display: flex;
        flex-wrap: wrap;

        &:hover {
            background: ${(props: IHHProps) => props.customcolour};
            color: ${(props: IHHProps) => (props.customcolour === "transparent" ? theme.palette.text.primary : theme.palette.common.white)};
            svg {
                color: ${(props: IHHProps) => (props.customcolour === "transparent" ? theme.palette.text.primary : theme.palette.common.white)};
            }
        }
    }

    .MuiSelect-outlined {
        height: 28px !important;
        padding: 0;
        padding-left: 9px;
        border-radius: 0;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: bold;
        transition: color ease-in-out 0.1s, background ease-in-out 0.1s;

        :hover {
            background: ${(props: IHHProps) => props.customcolour};
            color: ${(props: IHHProps) => (props.customcolour === "transparent" ? theme.palette.text.primary : theme.palette.common.white)};
        }

        .MuiMenuItem-root {
            :hover {
                background: ${(props: IHHProps) => props.customcolour} !important;
                color: ${(props: IHHProps) => (props.customcolour === "transparent" ? theme.palette.text.primary : theme.palette.common.white)};
            }
        }

        .MuiAutocomplete-inputFocused {
            transition: color ease-in-out 0.1s, background ease-in-out 0.1s;
            :hover {
                background: ${(props: IHHProps) => props.customcolour};
                color: ${(props: IHHProps) => (props.customcolour === "transparent" ? theme.palette.text.primary : theme.palette.common.white)};
            }
        }
    }
    .MuiAutocomplete-input {
        padding-top: 3px !important;
    }
`;

import styled from "@emotion/styled";
import { jsx, css, keyframes } from "@emotion/react";
import { Button, Box, Paper, Typography, TypographyProps } from "@mui/material";
import React, { MouseEventHandler } from "react";
import { Spinner } from "./Spinner";

export const WhiteText = styled(Typography)<TypographyProps>`
    color: white;
`;
export const WhiteTextBold = styled(Typography)<TypographyProps>`
    color: white;
    font-weight: 700;
`;
export type SuperDivProps = {
    pb?: string;
    pt?: string;
    mt?: string;
    mb?: string;
    inline?: boolean;
    color?: string;
};
export const SuperDiv = styled.div<SuperDivProps>`
    padding-bottom: ${(props) => props.pb};
    padding-top: ${(props) => props.pt};
    margin-top: ${(props) => props.mt};
    margin-bottom: ${(props) => props.mb};
    color: ${(props) => props.color};
    display: ${(props) => (props.inline ? "inline" : "block")};
`;
/*//https://github.com/azz/styled-css-grid
const autoRows = ({ minRowHeight = "20px" }) => `minmax(${minRowHeight}, auto)`;
const frGetter = (value: number | string) => (typeof value === "number" ? `repeat(${value}, 1fr)` : value);
const gap = ({ gap = "8px" }) => gap;
const flow = ({ flow = "row" }) => flow;
const formatAreas = (areas: string[]) => areas.map((area) => `"${area}"`).join(" ");
export type CSSGridProps = {
    className?: string;
    columns?: string | number;
    gap?: string;
    columnGap?: string;
    rowGap?: string;
    height?: string;
    minRowHeight?: string;
    flow?: string;
    rows?: string | number;
    areas?: string[];
    justifyContent?: string;
    alignContent?: string;
};
export const CSSGrid = styled(SuperDiv)<Partial<CSSGridProps>>`
    display: grid;
    height: ${({ height = "auto" }) => height};
    grid-auto-flow: ${flow};
    grid-auto-rows: ${autoRows};
    ${({ rows }) => rows && `grid-template-rows: ${frGetter(rows)}`};
    grid-template-columns: ${({ columns = 12 }) => frGetter(columns)};
    grid-gap: ${gap};
    ${({ columnGap }) => columnGap && `column-gap: ${columnGap}`};
    ${({ rowGap }) => rowGap && `row-gap: ${rowGap}`};
    ${({ areas }) => areas && `grid-template-areas: ${formatAreas(areas)}`};
    ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent}`};
    ${({ alignContent }) => alignContent && `align-content: ${alignContent}`};
`;*/

/*export type CellProps = {
    className?: string;
    width?: number;
    height?: number;
    top?: number | string;
    left?: number | string;
    middle?: boolean;
    center?: boolean;
    area?: string;
    marginTop?: string;
};
export const Cell = styled(SuperDiv)<Partial<CellProps>>`
    height: 100%;
    margin-top: ${(props) => props.marginTop};
    min-width: 0;
    grid-column-end: ${({ width = 1 }) => `span ${width}`};
    grid-row-end: ${({ height = 1 }) => `span ${height}`};
    ${({ left }) => left && `grid-column-start: ${left}`};
    ${({ top }) => top && `grid-row-start: ${top}`};
    ${({ center }) => center && `text-align: center`};
    ${({ area }) => area && `grid-area: ${area}`};
    ${/!* prettier-ignore *!/ ({ middle }) => middle && `
    display: inline-flex;
    flex-flow: column wrap;
    justify-content: center;
    justify-self: stretch;
  `};
`;*/
export const StaticBGImg = styled.img`
    /* Set rules to fill background */
    min-height: 100%;
    min-width: 1024px;
    z-index: -1;

    /* Set up proportionate scaling */
    width: 100%;
    height: auto;

    /* Set up positioning */
    position: fixed;
    top: 0;
    left: 0;
    @media screen and (max-width: 1024px) {
        /* Specific to this particular image */
        left: 50%;
        margin-left: -512px; /* 50% */
    }
`;
export type TypographyXProps = {
    customcolour?: string;
    fontSize?: string | number;
};
export const TypographyX = styled(Typography)<TypographyXProps & TypographyProps>`
    color: ${(props) => (props.customcolour ? props.customcolour : "black")};
    font-size: ${(props) => (props.fontSize ? props.fontSize : "")};
`;

export const AppHeader: any = styled(Box)`
    height: 0;
    min-height: 100px;
    @media screen and (min-width: 1040px) {
        min-height: 100px;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    background-position: 0 40%;
    max-width: 100%;
    margin: 0 auto;
`;

export const Uppercase = styled.div`
    * {
        text-transform: uppercase;
    }
`;

export interface StyledProps {
    pb?: string;
    pt?: string;
    mt?: string;
    mb?: string;
    inline?: boolean;
    color?: string;
}
//&& bumps up the importance of the style to override global styles. The more you use the higher it will be
export const Header4 = styled.h4<StyledProps>`
    text-shadow: rgb(255, 255, 255) 2px 2px 5px;
    text-transform: uppercase;
    padding-bottom: ${(props) => props.pb};
    padding-top: ${(props) => props.pt};
    margin-top: ${(props) => props.mt};
    margin-bottom: ${(props) => props.mb};
    color: ${(props) => props.color};
    margin: 0;
    font-size: 52px;
    font-weight: 700;
    display: ${(props) => (props.inline ? "inline" : "block")};
`;

export const FancyBox = styled(Box)`
    display: inline-block;
    transition: background-color 0.5s ease-in-out;
    &:hover {
        background-color: #f2893e;
        /* animation: color 4s ease-in-out; */
    }
`;

export const PaperFullHeight = styled(Paper)`
    height: 90vh;
    padding-left: 10px;
`;

export const LinkBox = styled(Box)`
    padding: 5px;
    cursor: pointer;
`;

export const RightSide = styled(Box)`
    overflow: auto;
    width: 100%;
    height: 90vh;
`;

export const BounceAnimation = keyframes`
	0% { margin-bottom: 0; }
	50% { margin-bottom: 15px }
	100% { margin-bottom: 0 }
`;

export const DotWrapper = styled.div`
    display: flex;
    align-items: flex-end;
`;

export const Dot = styled.div<any>`
    background-color: black;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    /* Animation */
    animation: ${BounceAnimation} 0.5s linear infinite;
    animation-delay: ${(props) => props.delay};
`;

export const HeaderStyles: any = styled(Box)`
    height: 0;
    min-height: 80px;
    @media screen and (min-width: 1040px) {
        min-height: 80px;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    background-position: 0 40%;
    max-width: 100%;
    margin: 0 auto;
`;

export const LeftBox: any = styled(Box)`
    margin: 20px 22px 20px 61px;
    @media (max-width: 1040px) {
        margin: 10px auto;
        max-width: 295px;
    }
`;

export const RightBox: any = styled(LeftBox)`
    margin: 20px 61px 20px 22px;
`;

export const BRFormBox: any = styled(Box)`
    width: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 16px;
    border: none;
    margin: 30px auto 200px;
    @media screen and (max-width: 1024px) {
        margin: 0px auto 60px;
    }
`;

// Libs
import { ModelBase } from "@shoothill/core";
import { Validator } from "../../../../Globals/Validation";
import { makeObservable, observable } from "mobx";
import { NewProfileQuestionAnswerModel } from "./NewProfileQuestionAnswerModel";
import NewProfileQuestionAnswerViewModel from "Globals/ViewModels/Admin/NewProfileQuestionAnswerViewModel";

export class NewProfileModel extends ModelBase<NewProfileModel> {
    public id: string | null = null;
    public profileQuestionId: string = "";
    public categoryId: string = "";
    public orderPosition: string = "";
    public mainImageUrl: string = "";
    public mainImageFileName: string = "";
    public question: string = "";
    public questionOrderPosition: string = "";

    public tmpAnswerTitle: any = [];
    public tmpAnswerScore: number = 1;

    // JJ: Ideally this would just be a get function
    public answersTotalScore: number = 0;

    // Nested children.
    public answers: NewProfileQuestionAnswerViewModel[] = [];

    constructor() {
        super();
        makeObservable(this, {
            id: observable,
            profileQuestionId: observable,
            categoryId: observable,
            orderPosition: observable,
            mainImageUrl: observable,
            mainImageFileName: observable,
            question: observable,
            questionOrderPosition: observable,
            tmpAnswerTitle: observable,
            tmpAnswerScore: observable,
            answersTotalScore: observable,
            answers: observable,
        });
    }
}

export class NewProfileValidator extends Validator<NewProfileModel> {
    constructor() {
        super();
        this.ruleFor("answersTotalScore").lessThanOrEqualTo(100).withMessage("Total score cannot exceed 100 points");
    }
}

import React, { useRef, useState, useEffect } from "react";
import * as Mui from "@mui/material";

import { RoleModel } from "Globals/Models";
import RoleViewModel from "Views/Admin/Roles/RoleViewModel";
import { AutoGrid } from "Globals/Components/Grid";

import { EditText } from "../../../Globals/Views/Primitives/EditText";
import styled from "@emotion/styled";
import { useRouter } from "../../../Globals/Hooks/UseRouter";
import { useObserver } from "mobx-react-lite";
import { Box } from "@mui/material";

export const Container = styled(Mui.Box)``;
export const DetailContainer = styled(Mui.Box)`
    position: relative;
`;

export const RoleDetails: React.FC = () => {
    const [roleViewModel] = useState(() => new RoleViewModel());
    const { history, match } = useRouter();
    const [newRole, setNewRole] = useState(false);

    let { id } = match.params as any;

    useEffect(() => {
        if (id === "0") {
            setNewRole(true);
        }
    }, []);

    useEffect(() => {
        if (history.location.pathname === "/roleslist/role/0") {
            setNewRole(true);
        }
    }, [history.location.pathname]);

    return useObserver(() => (
        <Container>
            <Mui.Box marginTop="45px" marginBottom="10px" display="flex">
                <Mui.Box flexGrow={1}>
                    <Mui.Typography variant="h1" color="textPrimary">
                        Role
                    </Mui.Typography>
                </Mui.Box>
            </Mui.Box>
            <hr />
            <DetailPanel newRole={newRole} id={id} roleViewModel={roleViewModel} />
        </Container>
    ));
};

interface DetailPanelProps {
    newRole: boolean;
    id: string;
    roleViewModel: RoleViewModel;
}
export const DetailPanel: React.FC<DetailPanelProps> = (props) => {
    const { roleViewModel } = props;
    let role: RoleModel = new RoleModel("");

    //const [editable, seteditable] = useState(false);
    const [loader, setLoader] = useState(false);
    const { history } = useRouter();
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        window.scrollTo(0, 0);
        if (props.id !== "0") {
            let currentRole = roleViewModel.getRole(props.id);
            if (currentRole) {
                roleViewModel.setRole(currentRole, false);
            } else {
                roleViewModel.loadRolesAsync().then(() => {
                    let currentRole = roleViewModel.getRole(props.id);
                    if (currentRole) {
                        roleViewModel.setRole(currentRole, false);
                    }
                });
            }
        } else {
            roleViewModel.setRole(role, true);
        }
    }, []);

    const doSubmit = async (e: any) => {
        e.preventDefault();
        if (!(await roleViewModel.isModelValid())) {
            setErrorMessage("Problem");
        } else {
            setErrorMessage("");
            setLoader(true);
            roleViewModel.postRoleDetailAsync().then(() => {
                setLoader(false);
                history.push("/admin");
            });
        }
    };

    return useObserver(() => (
        <form onSubmit={doSubmit} id="detailsPanelForm">
            <DetailContainer>
                {errorMessage !== "" && (
                    <Mui.Typography variant="caption" style={{ color: "red" }}>
                        {errorMessage}
                    </Mui.Typography>
                )}
                <Mui.Box marginTop="-70px" display="flex" textAlign="right">
                    <Mui.Box flexGrow={1}>
                        <Mui.Box display="flex">
                            <Mui.Box justifyContent="flex-end" flexGrow={1} paddingRight="20px">
                                <Mui.Button id="save" variant="contained" color="primary" type="submit" disabled={loader}>
                                    {!loader ? "Save" : "Saving"}
                                </Mui.Button>
                                <span style={{ paddingRight: "20px" }} />
                                <Mui.Button
                                    id="cancel"
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => {
                                        history.push("/admin");
                                    }}
                                >
                                    Cancel
                                </Mui.Button>
                            </Mui.Box>
                        </Mui.Box>
                    </Mui.Box>
                </Mui.Box>
                <Box pt={5} pb={5}>
                    <AutoGrid desktopColumns={"400px"}>
                        <EditText displayName="Name" value={() => roleViewModel.getValue("name")} command={roleViewModel.updateRoleNameCommand} />
                    </AutoGrid>
                </Box>
            </DetailContainer>
        </form>
    ));
};

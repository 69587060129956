import { Box, Button, TextField } from "@mui/material";
import { getHistory } from "@shoothill/core";
import { AppUrls } from "../../../AppUrls";
import { theme } from "../../../Globals/Styles/AppTheme";
import React, { useContext, useState } from "react";
import styled from "@emotion/styled";
import AdminContentListViewModel, { AdminContentLiteTableDataModel } from "../../../Globals/ViewModels/Admin/AdminContentListViewModel";

import { NewContentModal } from "../Modals/NewContentModal";
import { useObserver } from "mobx-react-lite";
import Checkbox from "@mui/material/Checkbox";
import MaterialTable, { MTableToolbar } from "@material-table/core";
import Typography from "@mui/material/Typography";
import { useRouter } from "../../../Globals/Hooks/UseRouter";

//custom
import { TablePageContent } from "../../../Globals/Components/General/General.styles";
import { ToolbarContainer } from "../../../Globals/Components/Table/ToolbarContainer";
import { DeleteSVG } from "../../../Globals/Views/IconSVGs/DeleteSVG";
import { useHistory } from "react-router";
import { DeleteModal } from "../Modals/DeleteModal";
import { HHTable, DisplayCheckbox } from "Globals/Components/Table/HHTable";
import { HHButton } from "Globals/Components/General/Buttons/HHButton";
import { AdminSecondaryToolbar, ToolbarTitle } from "Globals/Components/General/General.styles";
import { SearchSummary } from "Globals/Components/Table/SearchSummary";
// import { CustomerTable } from "../Customers";

export interface MatchParams {
    customerId: string;
}

export interface ICategoryCellProps {
    color: string;
}

export const CategoryCell = styled.div<ICategoryCellProps>`
    position: relative;
    display: flex;
    flex: 1 0 0;
    flex-direction: column;

    align-items: center;
    text-align: center;
    vertical-align: middle;
    border-radius: 5px;

    padding: 5px 0;
    font-size: 12px;
    font-weight: bold;
    color: ${theme.palette.common.white};
    background-color: ${(props) => props.color};
`;

export const AdminContentListView: React.FC = () => {
    const [filter, setFilter] = useState("");
    const [viewModel] = useState(new AdminContentListViewModel());
    const [newContentModal, setNewContentModal] = useState(false);
    const [deleteContentModal, setDeleteContentModal] = useState(false);
    const [contentIdToDelete, setContentIdToDelete] = useState<string | null>(null);
    const [contentIdToEdit, setContentIdToEdit] = useState<string | null>(null);
    let history = useHistory();

    const modals = () => (
        <>
            {deleteContentModal && (
                <DeleteModal
                    isActive={deleteContentModal}
                    confirmClose={() => {
                        if (contentIdToDelete) {
                            viewModel.deleteContent(contentIdToDelete);
                        }
                        setDeleteContentModal(false);
                    }}
                    onCancel={() => {
                        setContentIdToDelete(null);
                        setDeleteContentModal(false);
                    }}
                    title="Delete Content"
                    message="This will delete the content, are you sure you want to do this?"
                />
            )}
        </>
    );

    const columns = [
        {
            title: "Category",
            field: "categoryName",
            render: (rowData: AdminContentLiteTableDataModel) => (
                <CategoryCell style={{ textAlign: "center" }} color={rowData.accentColour}>
                    <div>{rowData.categoryName}</div>
                </CategoryCell>
            ),
            width: "10%",
        },
        { title: "Type", field: "contentType", width: "10%" },
        { title: "Title", field: "title", width: "37%" },
        { title: "Total points", field: "totalPoints", width: "5%" },
        {
            title: "Author",
            field: "totalPoints",
            render: (rowData: AdminContentLiteTableDataModel) => (
                <>
                    {rowData.firstName} {rowData.lastName}
                </>
            ),
            width: "10%",
        },
        { title: "Date added", field: "createdDate", width: "6%" },
        {
            title: "Last amended",
            field: "lastUpdatedDate",
            render: (rowData: AdminContentLiteTableDataModel) => (
                <>
                    {rowData.lastUpdatedUser} on
                    <br />
                    {rowData.lastUpdatedDate}
                </>
            ),
            width: "12%",
        },
        {
            title: "Visible in app",
            field: "isVisibleInApp",
            render: (rowData: AdminContentLiteTableDataModel) => (
                <>
                    <DisplayCheckbox
                        onClick={async (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            await viewModel.changeVisible(rowData.id);
                            return false;
                        }}
                        checked={rowData.isVisibleInApp}
                    />
                </>
            ),
            width: "5%",
        },
    ];

    const actions = [
        (rowData: AdminContentLiteTableDataModel) => ({
            icon: () => (
                <Box>
                    <DeleteSVG />
                </Box>
            ),
            tooltip: "Delete Content",
            onClick: () => {
                setContentIdToDelete(rowData.id);
                setDeleteContentModal(true);
            },
        }),
    ];

    // const onRowClick = (rowData: AdminContentListViewModel) => {
    //     history.push(AppUrls.Client.Admin.Content.replace(":contentId?", rowData.id));
    // };

    return useObserver(() => (
        <>
            <Box style={{ backgroundColor: "white" }}>
                <AdminSecondaryToolbar>
                    <ToolbarTitle> Content</ToolbarTitle>
                    <HHButton text="Add new content" onClick={() => getHistory().push(AppUrls.Client.Admin.AddContent.replace(":contentId?", ""))} width="304px" />
                </AdminSecondaryToolbar>
                <Box display="flex" width="100%">
                    <TablePageContent>
                        <HHTable
                            onSearchChange={(query: any) => {
                                //     //if (viewModel.customers.length > 600) {
                                viewModel.loadData(query);
                                //     //}
                            }}
                            columns={columns}
                            data={viewModel.getTableData}
                            components={{
                                Toolbar: (props: any) => (
                                    <>
                                        <ToolbarContainer>
                                            <SearchSummary data={viewModel.getTableData} resultType="article" />
                                            <MTableToolbar {...props} />
                                        </ToolbarContainer>
                                    </>
                                ),
                            }}
                            // onRowClick={(event, rowData, togglePanel) => {
                            //     if (!rowData) return;
                            //     getHistory().push(AppUrls.Client.Admin.Applicant.replace(":applicantId?", rowData.id));
                            // }}
                            title=""
                            actions={actions}
                            onRowClick={(event: any, rowData: AdminContentLiteTableDataModel, togglePanel: any) => {
                                if (!rowData) return;
                                if (rowData.id) {
                                    getHistory().push(AppUrls.Client.Admin.AddContent.replace(":contentId?", rowData.id));
                                    return;
                                }
                            }}
                            options={{
                                rowStyle: {
                                    fontSize: "12px",
                                },
                                actionsColumnIndex: -1,
                                tableLayout: "fixed",
                                pageSizeOptions: [10, 15, 20, 50, 100],
                            }}
                            localization={{
                                toolbar: {
                                    searchPlaceholder: "Keyword search",
                                },
                                header: {
                                    actions: "",
                                },
                            }}
                            isLoading={viewModel.IsLoading}
                        />
                    </TablePageContent>
                </Box>
            </Box>
            {modals()}
        </>
    ));
};

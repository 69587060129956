import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { ApiResult, FieldType, HttpClient, ViewModelBase } from "@shoothill/core";
import { container } from "tsyringe";

// App
import { AppUrls } from "../../../AppUrls";
import { FAQ } from "../../../Globals/Models/FAQ";

export interface AdminFAQTableDataModel {
    id: string;
    orderPosition: number;
    question: string;
    answer: string;
    firstName: string;
    lastName: string;
    createdByUserId: string;
    createdDate: string;
    lastUpdatedByUserId: string;
    lastUpdatedDate: string;
    isDeleted: boolean;
}

export default class AdminFAQsListViewModel extends ViewModelBase {
    private httpClient = container.resolve(HttpClient);

    public faqs: FAQ[] = [];
    constructor() {
        super(null);
        makeObservable(this, {
            faqs: observable,
            loadData: action,
            getTableData: computed,
        });
        this.loadData("");
    }

    public async loadData(query: string): Promise<void> {
        this.setIsLoading(true);
        const path = AppUrls.Server.Admin.SearchAllFAQs + "/?search=" + query;
        const apiResult = await this.httpClient.Get<FAQ[]>(path);
        if (apiResult.wasSuccessful) {
            runInAction(() => {
                this.faqs = apiResult.payload;
            });
        }
        this.setIsLoading(false);
    }

    public async deleteFAQ(id: string): Promise<void> {
        this.setIsLoading(true);
        const path = AppUrls.Server.Admin.DeleteFAQ + "/?id=" + id;
        const apiResult = await this.httpClient.Get<FAQ>(path);
        if (apiResult.wasSuccessful) {
            let faq = this.faqs.find((faq) => faq.id === id);
            runInAction(() => {
                if (faq != undefined) {
                    this.faqs.splice(this.faqs.indexOf(faq), 1);
                }
            });
        }
        this.setIsLoading(false);
    }

    public get getTableData() {
        let tableData: AdminFAQTableDataModel[] = [];
        this.faqs.forEach((faq) => {
            tableData.push({
                id: faq.id ? faq.id : "",
                orderPosition: faq.orderPosition,
                question: faq.question,
                answer: faq.answer,
                firstName: faq.firstName,
                lastName: faq.lastName,
                createdByUserId: faq.createdByUserId,
                createdDate: new Date(faq.createdDate).toLocaleDateString("en-GB"),
                lastUpdatedByUserId: faq.lastUpdatedByUserId,
                lastUpdatedDate: new Date(faq.lastUpdatedDate).toLocaleDateString("en-GB"),
                isDeleted: faq.isDeleted,
            });
        });
        return tableData;
    }
}

import * as MobX from "mobx";

import { FieldType, sortByString, HttpClient, ApiResult, ViewModelBase, Validation } from "@shoothill/core";
import { LoginModel, RoleModel } from "Globals/Models";
import { action, computed, makeObservable, observable } from "mobx";

import { RoleStore } from "Globals/Stores/Domain/Admin";
import { container } from "tsyringe";
import { RelayCommandAsync, ICommandAsync } from "Globals/Commands";
import { IKeyState } from "Globals";

export default class RoleViewModel extends ViewModelBase<RoleModel> {
    private roleStore = container.resolve(RoleStore);
    private httpClient = container.resolve(HttpClient);

    public roleCount: number = 0;
    public ascOrder: boolean = true;
    //public Valid: boolean = false;

    constructor() {
        super(new RoleModel(""));
        makeObservable(this, {
            roleCount: observable,
            ascOrder: observable,
            //Valid: observable,
            set: action,
            loadRolesAsync: action,
            getRoles: computed,
            getRoleCount: computed,
            getIsLoadingData: computed,
            setOrderAsc: action,
            getOrderAsc: computed,
        });
    }

    public updateRoleNameCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("name", value, keyState);
    });

    private async updateField(fieldName: keyof FieldType<RoleModel>, value: any, keyState: IKeyState) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    public setRole(role: RoleModel, newRole: boolean) {
        this.setValue("id", newRole ? "" : role.id);
        this.setValue("name", newRole ? "" : role.name);
    }

    public get(fieldName: any) {
        return this.getValue(fieldName);
    }

    public set(fieldName: any, value: string | number | boolean | Date) {
        this.setValue(fieldName, value as string);
    }

    public async loadRolesAsync(): Promise<ApiResult<RoleModel[]>> {
        const apiResult = await this.httpClient.Get<RoleModel[]>("/api/roles/getall");

        if (apiResult.wasSuccessful) {
            this.roleStore.setRoles(apiResult.payload);
            MobX.runInAction(() => (this.roleCount = this.roleStore.getRoleCount));
        }
        return apiResult;
    }

    get getRoles(): RoleModel[] {
        let roles = this.roleStore.getRoles.slice();
        roles.sort((a: RoleModel, b: RoleModel) => {
            if (this.ascOrder) return sortByString(a.name, b.name);
            else return sortByString(b.name, a.name);
        });
        return roles;
    }

    public getRole = (id: string) => {
        if (id) {
            return this.roleStore.getRoles.find((u: any) => u.id === id);
        } else {
            return new RoleModel("");
        }
    };

    get getRoleCount(): number {
        return this.roleCount;
    }

    get getIsLoadingData(): boolean {
        return this.roleStore.getIsLoadingData;
    }

    public setOrderAsc() {
        this.ascOrder = !this.ascOrder;
    }

    get getOrderAsc(): boolean {
        return this.ascOrder;
    }

    public async postRoleDetailAsync() {
        const apiResult = await this.httpClient.Post<RoleModel>(`/api/roles/Upsert`, this.getModel);
        if (apiResult.wasSuccessful) {
            let existingRole = this.roleStore.getRoles.find((u: any) => u.id === this.getValue("id"));
            if (existingRole) {
                const index = this.roleStore.getRoles.indexOf(existingRole, 0);
                if (index > -1) {
                    this.roleStore.getRoles.splice(index, 1);
                }
            }
            const newRole = new RoleModel(apiResult.payload.id);
            newRole.toModel(apiResult.payload);
            this.roleStore.getRoles.push(newRole);
        }
        return apiResult.payload;
    }
}

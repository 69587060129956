import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import clsx from "clsx";
import { observer, useObserver } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText } from "@mui/material";
import { ICommandAsync } from "../../Commands";

interface Props {
    command: ICommandAsync;
    className?: string;
    displayName: string;
    validationMessage?: () => string;
    value: () => boolean;
}

export const CheckBox: React.FC<Props> = observer((props) => {
    useEffect(() => {}, []);

    const getClasses = () => {
        return clsx({
            [props.className!]: !isEmptyOrWhitespace(props.className),
            ["checkBox"]: true,
        });
    };

    const getValue = (): boolean | undefined => {
        return props.value() ? true : false;
    };

    const getValidationMessage = (): string => {
        if (props.validationMessage) {
            return isEmptyOrWhitespace(props.validationMessage?.() as string) ? "" : (props.validationMessage?.() as string);
        }
        return "";
    };

    const hasError = (): boolean => {
        if (props.validationMessage) {
            return !isEmptyOrWhitespace(props.validationMessage?.() as string);
        }
        return false;
    };

    const isDisabled = (): boolean => {
        return isNullOrUndefined(props.command.canExecute) ? false : !props.command.canExecute();
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (props.command.execute) props.command.execute(event.target.checked);
    };

    return (
        <FormControl className={getClasses()} error={hasError()}>
            <FormControlLabel control={<Checkbox checked={props.value()} onChange={onChange} />} disabled={isDisabled()} label={props.displayName} />
            {hasError() && <FormHelperText>{getValidationMessage()}</FormHelperText>}
        </FormControl>
    );
});

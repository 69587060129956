import { makeObservable, observable, runInAction, action } from "mobx";
import { AccountStatus, ApiResult, FieldType, HttpClient, ViewModelBase } from "@shoothill/core";
import { LoginModel, LoginValidator } from "./LoginModel";
import { ICommandAsync, RelayCommandAsync } from "../../Globals/Commands";
import { IKeyState } from "Globals/Views/Primitives/EditText";
import { AppUrls } from "../../AppUrls";
import { AccountStore } from "../../Globals/Stores/Domain";
import { singleton, container } from "tsyringe";

@singleton()
export class LoginViewModel extends ViewModelBase<LoginModel> {
    private accountStore = container.resolve(AccountStore);
    private httpClient = container.resolve(HttpClient);

    public apiCallErrorMessage: string = "";

    constructor() {
        super(new LoginModel());
        console.log("Login View Model Created");

        this.setValidator(new LoginValidator());

        makeObservable(this, {
            apiCallErrorMessage: observable,
            login: action,
        });
    }

    public updateEmailCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("emailAddress", value, keyState);
    });
    public updatePasswordCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("password", value, keyState);
        if (keyState.enterKeyPressed) {
            const apiResult = await this.loginCommand.execute();
        }
    });

    public loginCommand: ICommandAsync = new RelayCommandAsync(
        async () => {
            await this.login();
        },
        () => {
            return Promise.resolve(this.isModelValid());
        },
    );

    public logoutCommand: ICommandAsync = new RelayCommandAsync(async () => {
        await this.accountStore.Logout(true);
    });

    private updateField(fieldName: keyof FieldType<LoginModel>, value: any, keyState: IKeyState) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    public login = async (): Promise<ApiResult<AccountStatus>> => {
        this.setIsLoading(true);
        let apiResult = await this.httpClient.Post<AccountStatus>(AppUrls.Server.Account.Login, this.getModel);
        if (apiResult !== null) {
            if (apiResult.wasSuccessful) {
                await this.accountStore.getLoginState(apiResult.payload);
                //const { from } = (location.state as any) || { from: { pathname: "/" } };
                let from = { pathname: "/" };
                if (from) {
                    this.history.push(from.pathname);
                }
            } else {
                runInAction(() => {
                    if (apiResult && apiResult.errors && apiResult.errors.length > 0) {
                        this.apiCallErrorMessage = "* " + apiResult.errors[0].message;
                    } else {
                        this.apiCallErrorMessage = "* Unknown error has occurred.";
                    }
                });
            }
        }
        this.setIsLoading(false);
        return apiResult;
    };
}
//Validator.prototype.isNumeric = Validator.prototype.isDecimal;

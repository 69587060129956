// Libraries
import { isEmptyOrWhitespace, isNullOrUndefined, KeyValuePair } from "@shoothill/core";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { Autocomplete, FormControl, FormLabel, TextField, Typography } from "@mui/material";
import { useAutocomplete } from "@mui/base/AutocompleteUnstyled";
import { ICommandAsync } from "../../Commands";
import CheckIcon from "@mui/icons-material/Check";
import { createStyles } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Popper } from "@mui/material";
//custom
import { theme } from "../../Styles/AppTheme";
import { HHFormControl } from "./Select.styles";
import { Chevron, ChevronContainer } from "../../../Content/assets/Chevron";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandMore from "@mui/icons-material/ExpandMore";
import styled from "@emotion/styled";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

interface IProps {
    className?: string;
    displayName?: string;
    getOptionLabel?: (option: any) => string;
    groupBy?: (option: any) => string;
    gutterBottom?: boolean;
    options: KeyValuePair[];
    placeholder?: string;
    validationMessage?: () => string;
    value: () => string;
    color?: string;
    style?: React.CSSProperties;
    renderOption?: any;
    disabled?: boolean;
    isDropdown?: boolean;
    command: ICommandAsync;
}

export const Select: React.FC<IProps> = observer((props: IProps) => {
    const color = props.color ? props.color : "inherit";
    const [errorText, setErrorText] = useState("");

    const getClasses = () => {
        return clsx({
            [props.className!]: !isEmptyOrWhitespace(props.className),
            ["select"]: true,
            ["selectGutterBottom"]: hasGutterBottom(),
        });
    };

    const getId = (): string => {
        return "selectControlId";
    };

    const getPlaceholder = (): string => {
        return !isEmptyOrWhitespace(props.placeholder) ? props.placeholder! : "";
    };

    // const getOptionLabel = (): string => {
    //     return !isNullOrUndefined(props.getOptionLabel) ? (props.getOptionLabel!) : "";
    // };

    const getValidationMessage = (): string => {
        return isEmptyOrWhitespace(props.validationMessage?.() as string) ? "" : (props.validationMessage?.() as string);
    };

    const hasGutterBottom = (): boolean => {
        return isNullOrUndefined(props.gutterBottom) ? false : props.gutterBottom!;
    };

    const isDisabled = (): boolean => {
        return isNullOrUndefined(props.command.canExecute) ? false : !props.command.canExecute();
    };

    const hasError = (): boolean => {
        return !isEmptyOrWhitespace(props.validationMessage?.() as string);
    };

    const onChange = (event: SyntheticEvent<Element, Event>, value: any): void => {
        if (props.command.execute) props.command.execute(value);
    };

    const useStyles = makeStyles(
        createStyles({
            root: {
                zIndex: 1500,

                "& .MuiAutocomplete-listbox": {
                    fontSize: 14,
                    "& :hover": {
                        background: `${color === "transparent" ? theme.palette.info.light : color} !important`,
                        color: `${color === "transparent" ? theme.palette.text.primary : theme.palette.common.white}`,
                    },
                },
            },
            helperText: {
                marginLeft: 0,
                fontSize: "12px",
            },
        }),
    );

    const CustomPopper = (props: any) => {
        const classes = useStyles();
        return <Popper {...props} className={classes.root} placement="bottom" />;
    };

    const getValue = (): any => {
        const result = props.options.find((a) => a.value === props.value()) as KeyValuePair;
        return result ? result : null;
    };

    const handleStyle = (): any => {
        return props.style ? { width: "375px", cursor: "pointer", ...props.style } : { width: "375px" };
    };

    const classes = useStyles();

    return (
        <HHFormControl style={handleStyle()} customcolour={color}>
            <FormLabel>{props.displayName}</FormLabel>
            <Autocomplete
                disabled={isDisabled()}
                value={getValue()}
                options={props.options}
                disableClearable={true}
                groupBy={props.groupBy}
                onChange={onChange}
                renderInput={(params: any) => (
                    <TextField
                        {...params}
                        InputLabelProps={{ shrink: false }}
                        InputProps={{
                            ...params.InputProps,
                        }}
                        /*inputProps={{
                            style: { paddingTop: 0 },
                        }}*/
                        error={hasError()}
                        helperText={getValidationMessage()}
                        placeholder={getPlaceholder()}
                        FormHelperTextProps={{
                            classes: {
                                root: classes.helperText,
                            },
                        }}
                    />
                )}
                popupIcon={<KeyboardArrowDownRoundedIcon />}
                ListboxProps={{}}
                PopperComponent={CustomPopper}
                getOptionLabel={(option) => option.label}
            />
        </HHFormControl>
    );

    // #endregion Public Interface
});

/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect } from "react";
import { container } from "tsyringe";
import { AccountStore } from "../../Globals/Stores/Domain";

export const LogoutView: React.FC = () => {
    const accountStore = container.resolve(AccountStore);

    useEffect(() => {
        accountStore.Logout();
    }, []);

    return <></>;
};

import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { ApiResult, FieldType, HttpClient, KeyValuePair, ViewModelBase } from "@shoothill/core";
import { container } from "tsyringe";

// App
import { NewProfileQuestionAnswerModel, NewProfileQuestionAnswerValidator } from "../../../Globals/Models/Domain/Admin/NewProfileQuestionAnswerModel";
import { DomainStores } from "../../../Globals/Stores";
import { RelayCommandAsync, ICommandAsync } from "../../../Globals/Commands";
import { IKeyState } from "../../../Globals";
export default class NewProfileQuestionAnswerViewModel extends ViewModelBase<NewProfileQuestionAnswerModel> {
    private httpClient = container.resolve(HttpClient);
    private domainStores = container.resolve(DomainStores);

    constructor() {
        super(new NewProfileQuestionAnswerModel());
        this.setValidator(new NewProfileQuestionAnswerValidator());
    }

    public updateAnswerTextCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("answerText", value, keyState);
    });

    public updateAnswerScoreCommand: ICommandAsync = new RelayCommandAsync(async (value: number, keyState: IKeyState) => {
        await this.updateField("answerScore", value, keyState);
    });

    public updateOrderPositionCommand: ICommandAsync = new RelayCommandAsync(async (value: number, keyState: IKeyState) => {
        await this.updateField("answerOrderPosition", value, keyState);
    });

    private async updateField(fieldName: keyof FieldType<NewProfileQuestionAnswerModel>, value: any, keyState?: IKeyState) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }
}

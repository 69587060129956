import { FieldType, KeyValuePair } from "@shoothill/core";

export function useViewModel<T>(
    viewModel: any,
    fieldName: keyof FieldType<T> | string,
    type: "text" | "textarea" | "select" | "multiselect" | "checkbox" | "radio",
    validationOnBlur: boolean = false,
): any {
    const common = {
        get validationMessage(): () => string {
            return () => viewModel.getError(fieldName) as string;
        },
    };
    if (type === "text" || type === "textarea" || type === "radio") {
        const props = {
            get value(): () => string {
                return () => viewModel.getValue(fieldName) as string;
            },
        };
        return { ...common, ...props };
    } else if (type === "select") {
        const props = {};
        return { ...common, ...props };
    } else if (type === "multiselect") {
        const props = {
            get value(): () => string[] {
                return () => viewModel.getValue(fieldName) as string[];
            },
        };
        return { ...common, ...props };
    } else if (type === "checkbox") {
        const props = {
            get value(): () => boolean {
                return () => viewModel.getValue(fieldName) as boolean;
            },
        };
        return { ...common, ...props };
    }
}

import { useState } from "react";
import { useRouter } from "../../../Globals/Hooks/UseRouter";
import { useHistory } from "react-router";
import { Box, Typography } from "@mui/material";
import { AdminProfilesListView } from "./AdminProfilesListView";
import { AdminProfileSummariesView } from "./AdminProfileSummariesView";

//custom
import { ITabProps, Tabs } from "../../../Globals/Components/Tabs/Tabs";
import { render } from "react-dom";
import { AdminProfileToolbar } from "./AdminProfile.styles";
import { ToolbarTitle } from "../../../Globals/Components/General/General.styles";

export const AdminProfileView: React.FC = () => {
    const { match } = useRouter();
    const TAB_QUESTIONS = "Questions";
    const TAB_SUMMARIES = "Summaries";
    const tabOptions: ITabProps[] = [
        { key: TAB_QUESTIONS, title: "Questions" },
        { key: TAB_SUMMARIES, title: "Summaries" },
    ];
    const [selectedTab, setSelectedTab] = useState(TAB_QUESTIONS);

    let history = useHistory();

    const goBack = () => history.goBack();

    const onTabClick = (tab: ITabProps) => {
        setSelectedTab(tab.key);
    };

    const renderContent = () => {
        switch (selectedTab) {
            case TAB_QUESTIONS:
                return <AdminProfilesListView />;
            case TAB_SUMMARIES:
                return <AdminProfileSummariesView />;
        }
    };

    return (
        <>
            <Box>
                <AdminProfileToolbar>
                    <Box>
                        <ToolbarTitle>{selectedTab === "Questions" ? "Profile - Questions" : "Profile - Summaries"}</ToolbarTitle>
                    </Box>
                    <Box style={{ display: "flex", alignItems: "end", height: "100%", paddingBottom: "14px" }}>
                        <Tabs tabs={tabOptions} selected={selectedTab} onTabClick={onTabClick} />
                    </Box>
                </AdminProfileToolbar>
                {renderContent()}
            </Box>
        </>
    );
};

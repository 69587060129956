export const DeleteSVG = (props: any) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
        <g id="delete" transform="translate(-1246 -268)">
            <rect id="Rectangle_1532" data-name="Rectangle 1532" width="30" height="30" rx="15" transform="translate(1246 268)" fill="#333" />
            <g id="Group_3845" data-name="Group 3845" transform="translate(906 -793)">
                <path id="Path_260" data-name="Path 260" d="M0,0,10,10" transform="translate(349.908 1070.908)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1.5" />
                <path
                    id="Path_261"
                    data-name="Path 261"
                    d="M0,0,10,10"
                    transform="translate(349.908 1080.908) rotate(-90)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeWidth="1.5"
                />
            </g>
        </g>
    </svg>
);

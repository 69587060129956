import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { ApiResult, FieldType, HttpClient, ViewModelBase } from "@shoothill/core";
import { container } from "tsyringe";

// App
import { AppUrls } from "../../../AppUrls";
import { ProfileLite } from "../../../Globals/Models/ProfileLite";

export interface AdminProfileLiteTableDataModel {
    id: string;
    colour: string;
    accentColour: string;
    categoryId: string;
    categoryName: string;
    order: number;
    question: string;
    totalScore: number;
    firstName: string;
    lastName: string;
    createdDate: string;
    lastUpdatedDate: string;
    isVisibleInApp: boolean;
    isDeleted: boolean;
}

export default class AdminProfileListViewModel extends ViewModelBase {
    private httpClient = container.resolve(HttpClient);

    public profilesLite: ProfileLite[] = [];

    constructor() {
        super(null);
        makeObservable(this, {
            profilesLite: observable,
            loadData: action,
            getTableData: computed,
            deleteProfile: action,
        });
        this.loadData("");
    }

    public async loadData(query: string): Promise<void> {
        this.setIsLoading(true);
        const path = AppUrls.Server.Admin.SearchAllProfiles + "/?search=" + query;
        const apiResult = await this.httpClient.Get<ProfileLite[]>(path);
        if (apiResult.wasSuccessful) {
            runInAction(() => {
                this.profilesLite = apiResult.payload;
                console.log(this.profilesLite);
            });
        }
        this.setIsLoading(false);
    }

    public async deleteProfile(id: string): Promise<void> {
        this.setIsLoading(true);
        const path = AppUrls.Server.Admin.DeleteProfileQuestion + "/?id=" + id;
        const apiResult = await this.httpClient.Get<ProfileLite>(path);
        if (apiResult.wasSuccessful) {
            let profileLite = this.profilesLite.find((profileLite) => profileLite.id === id);
            runInAction(() => {
                if (profileLite != undefined) {
                    this.profilesLite.splice(this.profilesLite.indexOf(profileLite), 1);
                }
            });
        }
        this.setIsLoading(false);
    }

    public get getTableData() {
        let tableData: AdminProfileLiteTableDataModel[] = [];
        this.profilesLite.forEach((profileLite) => {
            tableData.push({
                id: profileLite.id ? profileLite.id : "",
                colour: profileLite.colour,
                accentColour: profileLite.accentColour,
                categoryId: profileLite.categoryId,
                categoryName: profileLite.categoryName,
                order: profileLite.order,
                question: profileLite.question,
                totalScore: profileLite.totalScore,
                firstName: profileLite.firstName,
                lastName: profileLite.lastName,
                createdDate: new Date(profileLite.createdDate).toLocaleDateString("en-GB"),
                lastUpdatedDate: new Date(profileLite.lastUpdatedDate).toLocaleDateString("en-GB"),
                isVisibleInApp: profileLite.isVisibleInApp,
                isDeleted: profileLite.isDeleted,
            });
        });
        return tableData;
    }
}

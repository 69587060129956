import styled from "@emotion/styled";
import { EditText } from "Globals/Views/Primitives/EditText";
import { theme } from "../../../Globals/Styles/AppTheme";

interface IFileUploaderContainerProps {
    color: string;
}

export const FileUploaderContainer = styled.div<IFileUploaderContainerProps>`
    background: ${(props) => (props.color ? props.color : theme.palette.info.light)};
    border-radius: 15px;
    padding: 10px 20px;
    width: 375px;
`;

export const FileUploaderEditText = styled(EditText)`
    width: 100%;
    margin-top: 15px;
    .MuiOutlinedInput-root {
        background: ${theme.palette.common.white};
    }
`;

export const RoundedEditText = styled(EditText)`
    .MuiOutlinedInput-root {
        border-radius: 70px;
        background: ${theme.palette.common.white};
        overflow: hidden;
        textarea {
            text-align: center;
            padding: 30px;
        }
    }
`;

export const AddProfileFieldContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    // margin: 0 61px;
`;

export const AddProfileQuestionAnswerList = styled.div`
    width: 375px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;
    justify-content: center;
`;

import { FormControl, FormLabel, TextField } from "@mui/material";
import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";
import { ICommandAsync } from "../../Commands";
import clsx from "clsx";
import styled from "@emotion/styled";

interface IKeyState {
    enterKeyPressed: boolean;
    backspaceKeyPressed: boolean;
    deleteKeyPressed: boolean;
}

const StyledInputElement = styled("input")`
    width: 320px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.5;
    padding: 5px 9px;
    border-radius: 0px;
    transition: all 150ms ease;
`;

interface IProps {
    id?: string;
    value: () => string;
    style?: any;
    className?: string;
    displayName: string;
    gutterBottom?: boolean;
    inputProps?: any;
    validationMessage?: () => string;
    min: number;
    max: number;
    command: ICommandAsync;
}

export const EditNumber: React.FC<IProps> = observer((props) => {
    const keyState = useRef<IKeyState>({ enterKeyPressed: false, backspaceKeyPressed: false, deleteKeyPressed: false });
    const ref = React.useRef<HTMLInputElement>(null);
    const [selection, setSelection] = React.useState<[number | null, number | null] | null>(null);
    //EN: This is to fix a weird react issue where the cursor keeps jumping to the end of the input
    React.useLayoutEffect(() => {
        if (selection && ref.current) {
            //[ref.current.selectionStart, ref.current.selectionEnd] = selection;
            setTimeout(() => {
                ref!.current!.selectionStart = selection[0];
                ref!.current!.selectionEnd = selection[1];
            }, 1);
        }
    }, [selection]);

    const getClasses = () => {
        return clsx({
            [props.className!]: !isEmptyOrWhitespace(props.className),
            ["editNumber"]: true,
            ["editNumberGutterBottom"]: hasGutterBottom(),
        });
    };
    useEffect(() => {});

    const getId = (): string => {
        return props.id!;
    };

    const getValidationMessage = (): string => {
        return isEmptyOrWhitespace(props.validationMessage?.() as string) ? "" : (props.validationMessage?.() as string);
    };

    const hasError = (): boolean => {
        if (props.validationMessage) return !isEmptyOrWhitespace(props.validationMessage!());
        return false;
    };

    const hasGutterBottom = (): boolean => {
        return isNullOrUndefined(props.gutterBottom) ? false : props.gutterBottom!;
    };

    const isDisabled = (): boolean => {
        return isNullOrUndefined(props.command.canExecute) ? false : !props.command.canExecute();
    };

    const onChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        setSelection([event.target.selectionStart, event.target.selectionEnd]);
        let val = parseFloat(event.target.value);
        if (val === NaN) return;
        await props.command.execute(event.target.value, keyState.current);
    };

    const onKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>): Promise<void> => {
        let keyId: number = event.keyCode;

        switch (keyId) {
            case 8:
                keyState.current.backspaceKeyPressed = true;
                keyState.current.enterKeyPressed = false;
                keyState.current.deleteKeyPressed = false;
                break;
            case 13:
                keyState.current.backspaceKeyPressed = false;
                keyState.current.enterKeyPressed = true;
                keyState.current.deleteKeyPressed = false;
                break;
            case 46:
                keyState.current.backspaceKeyPressed = false;
                keyState.current.enterKeyPressed = false;
                keyState.current.deleteKeyPressed = true;
                break;
            default:
                break;
        }
        if (keyId === 8 || keyId === 13 || keyId === 46) {
            await props.command.execute(props.value(), keyState.current);
            //await CommandInvoker<{ value: string; keyState: IKeyState }>(props.command, { value: props.value(), keyState: keyState.current });
        }
    };

    return (
        <FormControl className={props.className} style={props.style}>
            <FormLabel>{props.displayName}</FormLabel>
            <TextField
                inputRef={ref}
                InputProps={{
                    //disableUnderline: true,
                    ...props.inputProps,
                }}
                size={"small"}
                InputLabelProps={{ shrink: false }}
                disabled={isDisabled()}
                error={hasError()}
                helperText={getValidationMessage()}
                id={getId()}
                //label={props.displayName}
                onChange={onChange}
                //onKeyDown={onKeyDown}
                //type={"number"}
                value={props.value()}
                inputProps={{ inputMode: "numeric", max: props.max, min: props.min }}
            />
        </FormControl>
    );
});

import React, { ReactChild, ReactChildren } from "react";
import { Box, Modal, Typography } from "@mui/material";

//custom
import styled from "@emotion/styled";
import { theme } from "../../../Globals/Styles/AppTheme";

export const ModalBase: any = styled(Modal)`
    padding: 0px;
    margin: 10% auto;
    flex-direction: column;
    display: flex;
    width: 679px;
    outline: none;


    .modalcontainer {
      position: relative;
      border-radius: 0px 5px 5px 5px !important; //TODO check why this doesn't work
     
    }


    .modalhead,
    .modalbody {
        
        outline: none;
    }

    .modalhead {
        position: absolute;
        top:  -45px;
        height: 45px;
        border-radius: 0px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 13px 0px;
        width: 170px;



        h2 {
            margin: 0 auto;
            font-size: 1.8em;
            text-transform: uppercase;
            font-weight: 500;
        }
    }
    .modalbody {
       display:flex;
       flex-direction: column;
       align-items: center;
       justify-content:center;
       padding: 20px;
        
      position:
        border-radius: 0px 5px 5px 5px !important;
        background-color: ${theme.palette.common.white};
        outline: none;
    }    
    
    .modalclosebtn {
      position: absolute;
      right: -50px;
      cursor: pointer;
    }

    .pac-container {
           z-index: 1301;
    }

    //MUI overrides

    .modaltextarea{
      .MuiFormControl-root {
        height:auto;
        .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
          height: auto;
        }
      }
    }

    .MuiFormControl-root {
      margin-bottom: 10px;
      .MuiOutlinedInput-root  {
        height: 28px;
      }
    }

    .MuiInputBase-root{
      border-radius: 0px;
    }

    .MuiFormLabel-root {
      color: ${theme.palette.text.primary};
      font-weight:bold;
      font-size: 14px;
      margin-bottom: 5px;
  }

  .MuiAutocomplete-root {
    height: 28px;
    .MuiFormControl-root {
      height: 28px;
      
      .MuiOutlinedInput-root.MuiInputBase-sizeSmall{
      height: 28px; 
      
      }
    }
  
  }
`;

export const ModelButtonsContainer = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    button {
        border-radius: 17px;
        display: flex;
        flex: 1 50%;
        height: 30px;
        width: 173px;
        font-size: 14px;
        font-weight: bold;
        text-transform: capitalize;
        color: ${theme.palette.common.white};
    }
    button:first-type {
        background-color: ${theme.palette.info.main};
        margin-right: 30px;
    }
`;

interface IModalHeaderProps {
    title: string;
    color?: any;
}

export const ModalHeader = (props: IModalHeaderProps) => {
    const color = props.color ? props.color : "#333";
    return (
        <div className="modalhead" style={{ backgroundColor: `${color}` }}>
            <Typography variant="body2">{props.title}</Typography>
        </div>
    );
};

interface IButtonsContainer {
    color?: any;
    children: React.ReactNode;
}

export const ButtonsContainer = (props: IButtonsContainer) => {
    const color = props.color ? props.color : "#333";
    return (
        <>
            <div style={{ backgroundColor: `${color}`, width: "640px", height: "1px", margin: "18px 0px 19px" }}>{""}</div>
            <ModelButtonsContainer>{props.children}</ModelButtonsContainer>
        </>
    );
};

import { Typography, Paper, Button, Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { FormType } from "@shoothill/core";
import { useObserver } from "mobx-react-lite";
import { TypographyX } from "Globals/Components";
import { Stores } from "../Globals/Stores";
import { container } from "tsyringe";
import { useFormLockConcurrency } from "../Globals/Hooks";

const fontBaseSize = 16;
export const Text = React.memo(() => {
    const stores = container.resolve(Stores);

    const [h1size, seth1size] = useState(0);
    const [h2size, seth2size] = useState(0);
    const [h3size, seth3size] = useState(0);
    const [h4size, seth4size] = useState(0);
    const [h5size, seth5size] = useState(0);
    const [h6size, seth6size] = useState(0);
    const [subtitle1size, setsubtitle1size] = useState(0);
    const [subtitle2size, setsubtitle2size] = useState(0);
    const [body1size, setbody1size] = useState(0);
    const [body2size, setbody2size] = useState(0);
    const [buttonTextsize, setbuttonTextsize] = useState(0);
    const [captionTextsize, setcaptionTextsize] = useState(0);
    const h1ref = useRef(null);
    const h2ref = useRef(null);
    const h3ref = useRef(null);
    const h4ref = useRef(null);
    const h5ref = useRef(null);
    const h6ref = useRef(null);
    const subtitle1ref = useRef(null);
    const subtitle2ref = useRef(null);
    const body1ref = useRef(null);
    const body2ref = useRef(null);
    const buttonTextref = useRef(null);
    const captionTextref = useRef(null);
    useFormLockConcurrency("text", [FormType.Ping], true);
    const getEmSize = (el: any) => {
        try {
            let cs = getComputedStyle(el, "");
            let fx = parseFloat(cs!.fontSize!);
            return Number(fx);
        } catch {}
        return 0;
    };
    const getSizes = () => {
        seth1size(getEmSize(h1ref.current));
        seth2size(getEmSize(h2ref.current));
        seth3size(getEmSize(h3ref.current));
        seth4size(getEmSize(h4ref.current));
        seth5size(getEmSize(h5ref.current));
        seth6size(getEmSize(h6ref.current));
        setsubtitle1size(getEmSize(subtitle1ref.current));
        setsubtitle2size(getEmSize(subtitle2ref.current));
        setbody1size(getEmSize(body1ref.current));
        setbody2size(getEmSize(body2ref.current));
        setbuttonTextsize(getEmSize(buttonTextref.current));
        setcaptionTextsize(getEmSize(captionTextref.current));
    };
    useEffect(() => {
        getSizes();
        window.addEventListener("resize", () => {
            getSizes();
        });
    }, []);

    return useObserver(() => (
        <>
            <Typography variant="h4">
                <i>* Text sizes in brackets are @ 1366 width with Font base size of {fontBaseSize}px</i>
            </Typography>
            <Box color={"white"} bgcolor={"#131313"} p={1}>
                Padding 1
            </Box>
            <Box color={"white"} bgcolor={"#232323"} p={2}>
                Padding 2
            </Box>
            <Box color={"white"} bgcolor={"#333333"} p={3}>
                Padding 3
            </Box>
            <Box color={"white"} bgcolor={"#444444"} p={4}>
                Padding 4
            </Box>
            <Box color={"white"} bgcolor={"#555555"} p={5}>
                Padding 5
            </Box>
            <br />
            <br />
            <Paper>
                <div>
                    <Button
                        onClick={() => {
                            let _ = axios.post(`/api/example/ping`);
                        }}
                        disabled={stores.CoreStore.FormLockConcurrencyHubStore.getIsFormLocked(FormType.Ping)}
                    >
                        {stores.CoreStore.FormLockConcurrencyHubStore.getIsFormLocked(FormType.Ping)
                            ? `Ping Locked By ${stores.CoreStore.FormLockConcurrencyHubStore.getLocker(FormType.Ping)}`
                            : "Ping"}
                    </Button>
                    <Button
                        onClick={() => {
                            let _ = axios.get(`/api/example/getping`);
                        }}
                    >
                        {"?"}
                    </Button>
                    <Typography color={"textPrimary"} variant="h1" gutterBottom style={{ fontSize: "32px" }}>
                        h1. Fixed Font Heading ({fontBaseSize * 2}px)
                    </Typography>
                    <Typography color={"textPrimary"} ref={h1ref} variant="h1" gutterBottom>
                        h1. Heading ({h1size}px)
                    </Typography>
                    <Typography color={"textPrimary"} ref={h2ref} variant="h2" gutterBottom>
                        h2. Heading ({h2size}px)
                    </Typography>
                    <Typography color={"textPrimary"} ref={h3ref} variant="h3" gutterBottom>
                        h3. Heading ({h3size}px)
                    </Typography>
                    <Typography color={"textPrimary"} ref={h4ref} variant="h4" gutterBottom>
                        h4. Heading ({h4size}px)
                    </Typography>
                    <Typography color={"textPrimary"} ref={h5ref} variant="h5" gutterBottom>
                        h5. Heading ({h5size}px)
                    </Typography>
                    <Typography ref={h6ref} variant="h6" gutterBottom>
                        h6. Heading ({h6size}px)
                    </Typography>
                    <Typography color={"textPrimary"} ref={subtitle1ref} variant="subtitle1" gutterBottom>
                        subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. ({subtitle1size}px)
                    </Typography>
                    <Typography color={"textPrimary"} ref={subtitle2ref} variant="subtitle2" gutterBottom>
                        subtitle2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. ({subtitle2size}px)
                    </Typography>
                    <Typography color={"textPrimary"} ref={body1ref} variant="body1" gutterBottom>
                        body1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. ({body1size}px)
                    </Typography>
                    <Typography color={"textPrimary"} ref={body2ref} variant="body2" gutterBottom>
                        body2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. ({body2size}px)
                    </Typography>
                    <Typography ref={buttonTextref} color={"textPrimary"} variant="button" display="block" gutterBottom>
                        button text ({buttonTextsize}px)
                    </Typography>
                    <Typography ref={captionTextref} color={"textPrimary"} variant="caption" display="block" gutterBottom>
                        caption text ({captionTextsize}px)
                    </Typography>
                    <Typography color={"textPrimary"} variant="overline" display="block" gutterBottom>
                        overline text
                    </Typography>
                    <TypographyX css={""} customcolour={"red"} fontSize={"30px"} variant={"h4"}>
                        Custom Typography Component
                    </TypographyX>
                    <br />
                    <br />
                    <br />
                </div>
            </Paper>
        </>
    ));
});

import { ModelBase } from "@shoothill/core";
import { Validator } from "Globals/Validation";
import { makeObservable, observable } from "mobx";
import { RegisterUserDetailsViewModel } from "./RegisterUserDetailsViewModel";

export class RegisterUserModel extends ModelBase<RegisterUserModel> {
    public lastName: string = "";
    public firstName: string = "";
    public phoneNumber: number | string = "";
    public emailAddress: string = "";
    public password: string = "";
    public confirmPassword: string = "";
    public userDetails: RegisterUserDetailsViewModel = new RegisterUserDetailsViewModel();
    public terms: boolean = false;
    public tod: Date = new Date();

    constructor() {
        super();
        makeObservable(this, {
            lastName: observable,
            firstName: observable,
            phoneNumber: observable,
            emailAddress: observable,
            password: observable,
            confirmPassword: observable,
            userDetails: observable,
            terms: observable,
            tod: observable,
        });
    }
}

export class RegisterUserValidator extends Validator<RegisterUserModel> {
    constructor() {
        super();

        this.ruleFor("lastName").emailAddress().maxLength(50);
        this.ruleFor("firstName").emailAddress().maxLength(50);
        this.ruleFor("emailAddress").emailAddress().maxLength(100);
        this.ruleFor("password").notEmpty().maxLength(100);
        this.ruleFor("confirmPassword")
            .notEmpty()
            .must({ predicate: (confirmPassword, model) => confirmPassword === model.password, message: (value, model) => "Passwords must match" })
            .when((model) => model.password.length > 0);
    }
}

// Libraries
import { isEmptyOrWhitespace, isNullOrUndefined, KeyValuePair } from "@shoothill/core";
import { observer, useObserver } from "mobx-react-lite";
import React from "react";
import { Autocomplete, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select } from "@mui/material";
import { ICommandAsync } from "../../Commands";

interface IProps {
    value: () => string[];
    className?: string;
    displayName?: string;
    getOptionLabel?: (option: any) => string;
    groupBy?: (option: any) => string;
    gutterBottom?: boolean;
    options: KeyValuePair[];
    placeholder?: string;
    validationMessage?: () => string;
    command: ICommandAsync;
}

export const MultiSelect2: React.FC<IProps> = observer((props: IProps) => {
    /* const getClasses = () => {
        return clsx({
            [props.className!]: !isEmptyOrWhitespace(props.className),
            ["select"]: true,
            ["selectGutterBottom"]: hasGutterBottom(),
        });
    };*/

    const getId = (): string => {
        return "selectControlId";
    };

    const getPlaceholder = (): string => {
        return !isEmptyOrWhitespace(props.placeholder) ? props.placeholder! : "";
    };

    const getValidationMessage = (): string => {
        return isEmptyOrWhitespace(props.validationMessage?.() as string) ? "" : (props.validationMessage?.() as string);
    };

    const hasGutterBottom = (): boolean => {
        return isNullOrUndefined(props.gutterBottom) ? false : props.gutterBottom!;
    };

    const isDisabled = (): boolean => {
        return isNullOrUndefined(props.command.canExecute) ? false : !props.command.canExecute();
    };

    const hasError = (): boolean => {
        return !isEmptyOrWhitespace(props.validationMessage?.() as string);
    };

    const onChange = (event: any, value: any): void => {
        if (props.command.execute) props.command.execute(event.target.value);
    };

    const onChecked = (value: any): boolean => {
        let retval = props.value().find((a) => a == value) !== null;
        return retval;
    };

    return (
        <FormControl style={{ width: "300px" }}>
            <InputLabel disabled={isDisabled()} htmlFor={getId()} shrink>
                {props.displayName}
            </InputLabel>
            <Select
                multiple
                disabled={isDisabled()}
                onChange={onChange}
                value={props.value()}
                renderValue={(selected: any[]) => {
                    let retval: any = [];
                    if (selected && props.options) {
                        for (let item of selected) {
                            let a = props?.options?.find((a) => a.value == item)?.label;
                            retval.push(a);
                        }
                    }
                    return retval.join(", ");
                }}
            >
                {props.options.map((item: KeyValuePair) => (
                    <MenuItem key={item.label.toString()} value={item.value}>
                        <Checkbox checked={props.value().indexOf(item.value) > -1} />
                        <ListItemText primary={item.label} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
});

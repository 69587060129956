import React, { PropsWithChildren, useEffect } from "react";
import { Box, BoxProps } from "@mui/material";
import { useObserver } from "mobx-react-lite";
import { autorun } from "mobx";
import { CoreStoreInstance } from "@shoothill/core";

type AutoCellProps = {
    mobileArea?: string;
    ma?: string;
    tabletArea?: string;
    ta?: string;
    desktopArea?: string;
    da?: string;
    children?: any;
    desktopSpan?: number;
    ds?: number;
    tabletSpan?: number;
    ts?: number;
    mobileSpan?: number;
    ms?: number;
    onClick?: any;
} & PropsWithChildren<BoxProps>;

export const AutoCell: React.FC<AutoCellProps> = (props: AutoCellProps) => {
    const [area, setArea] = React.useState<string>(props.desktopArea!);
    const [span, setSpan] = React.useState<number>(props.desktopSpan!);
    const [view, setView] = React.useState("desktop");
    let { desktopArea, tabletArea, mobileArea, desktopSpan, tabletSpan, mobileSpan, ...boxProps } = props;

    useEffect(() => {
        desktopArea = desktopArea ?? props.da;
        tabletArea = tabletArea ?? props.ta;
        mobileArea = mobileArea ?? props.ma;
        desktopSpan = desktopSpan ?? props.ds;
        tabletSpan = tabletSpan ?? props.ts;
        mobileSpan = mobileSpan ?? props.ms;

        autorun(() => {
            //	console.log(StoresInstance.domain.screenWidth);
            if (CoreStoreInstance.isMobile) {
                setArea(mobileArea!);
                setSpan(mobileSpan!);
                setView("mobile");
            } else if (CoreStoreInstance.isTablet) {
                setArea(tabletArea!);
                setSpan(tabletSpan!);
                setView("tablet");
            } else if (CoreStoreInstance.isDesktop) {
                setArea(desktopArea!);
                setSpan(desktopSpan!);
                setView("desktop");
            }
        });
    }, []);

    return useObserver(() => (
        <>
            <Box {...boxProps} onClick={props.onClick ?? undefined} gridArea={area} gridColumn={`span ${span}`}>
                {props.children}
            </Box>
        </>
    ));
};
AutoCell.defaultProps = {
    justifyContent: "normal",
    alignContent: "normal",
};

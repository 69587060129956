import { Box, Button, TextField } from "@mui/material";
import { getHistory } from "@shoothill/core";
import { AppUrls } from "../../../AppUrls";
import { theme } from "../../../Globals/Styles/AppTheme";
import React, { useContext, useState } from "react";
import styled from "@emotion/styled";
import AdminProfileListViewModel, { AdminProfileLiteTableDataModel } from "../../../Globals/ViewModels/Admin/AdminProfileListViewModel";

import { NewProfileModal } from "../Modals/NewProfileModal";
import { useObserver } from "mobx-react-lite";
import Checkbox from "@mui/material/Checkbox";
import MaterialTable, { MTableToolbar } from "@material-table/core";
import Typography from "@mui/material/Typography";
import { useRouter } from "../../../Globals/Hooks/UseRouter";

//custom
import { TablePageContent } from "../../../Globals/Components/General/General.styles";
import { ToolbarContainer } from "../../../Globals/Components/Table/ToolbarContainer";
import { DeleteSVG } from "../../../Globals/Views/IconSVGs/DeleteSVG";
import { useHistory } from "react-router";
import { DeleteModal } from "../Modals/DeleteModal";
import { HHButton } from "Globals/Components/General/Buttons/HHButton";
import { SearchSummary } from "Globals/Components/Table/SearchSummary";
import { AdminSecondaryToolbar, ToolbarTitle } from "Globals/Components/General/General.styles";
import { HHTable, DisplayCheckbox } from "Globals/Components/Table/HHTable";

export interface ICategoryCellProps {
    color: string;
}

export const CategoryCell = styled.div<ICategoryCellProps>`
    position: relative;
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    width: 148px;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    border-radius: 5px;

    padding: 5px 0;
    font-size: 12px;
    font-weight: bold;
    color: ${theme.palette.common.white};
    background-color: ${(props) => props.color};
    opacity: 1;
`;

export const AdminProfilesListView: React.FC = () => {
    const [filter, setFilter] = useState("");
    const [viewModel] = useState(new AdminProfileListViewModel());
    const [deleteProfileModal, setDeleteProfileModal] = useState(false);
    const [profileIdToDelete, setProfileIdToDelete] = useState<string | null>(null);
    let history = useHistory();

    const modals = () => (
        <>
            {deleteProfileModal && (
                <DeleteModal
                    isActive={deleteProfileModal}
                    confirmClose={() => {
                        if (profileIdToDelete) {
                            viewModel.deleteProfile(profileIdToDelete);
                        }
                        setDeleteProfileModal(false);
                    }}
                    onCancel={() => {
                        setProfileIdToDelete(null);
                        setDeleteProfileModal(false);
                    }}
                    title="Delete Profile"
                    message="This will delete the profile question, are you sure you want to do this?"
                />
            )}
        </>
    );

    const columns = [
        {
            title: "Category",
            field: "categoryName",
            render: (rowData: AdminProfileLiteTableDataModel) => (
                <CategoryCell color={rowData.accentColour}>
                    <div>{rowData.categoryName}</div>
                </CategoryCell>
            ),
            width: "150px",
        },
        { title: "Order", field: "order", width: "5%" },
        { title: "Question", field: "question", width: "40%" },
        { title: "Total score", field: "totalScore", width: "5%" },
        { title: "Date added", field: "createdDate", width: "8%" },
        {
            title: "Last amended",
            field: "lastUpdatedDate",
            render: (rowData: AdminProfileLiteTableDataModel) => (
                <>
                    {rowData.lastUpdatedDate} by {rowData.firstName} {rowData.lastName}
                </>
            ),
            width: "15%",
        },
        {
            title: "Visible in app",
            field: "isVisibleInApp",
            render: (rowData: AdminProfileLiteTableDataModel) => (
                <>
                    <DisplayCheckbox disableRipple checked={rowData.isVisibleInApp} disabled />
                </>
            ),
            width: "5%",
        },
    ];

    const actions = [
        (rowData: AdminProfileLiteTableDataModel) => ({
            icon: () => <DeleteSVG />,
            tooltip: "Delete Profile question",
            onClick: () => {
                setProfileIdToDelete(rowData.id);
                setDeleteProfileModal(true);
            },
        }),
    ];

    return useObserver(() => (
        <>
            <Box style={{ backgroundColor: "white" }}>
                <AdminSecondaryToolbar style={{ justifyContent: "right" }}>
                    <HHButton text="Add new question" onClick={() => getHistory().push(AppUrls.Client.Admin.AddProfile.replace(":questionId?", ""))} width="304px" />
                </AdminSecondaryToolbar>
                <Box display="flex" width="100%">
                    <TablePageContent>
                        <HHTable
                            onSearchChange={(query: any) => {
                                //     //if (viewModel.customers.length > 600) {
                                viewModel.loadData(query);
                                //     //}
                            }}
                            columns={columns}
                            data={viewModel.getTableData}
                            components={{
                                Toolbar: (props: any) => (
                                    <>
                                        <ToolbarContainer>
                                            <SearchSummary data={viewModel.getTableData} resultType="question" />
                                            <MTableToolbar {...props} />
                                        </ToolbarContainer>
                                    </>
                                ),
                            }}
                            onRowClick={(event: any, rowData: AdminProfileLiteTableDataModel, togglePanel: any) => {
                                if (!rowData) return;
                                if (rowData.id) {
                                    getHistory().push(AppUrls.Client.Admin.AddProfile.replace(":questionId?", rowData.id));
                                }
                            }}
                            title=""
                            actions={actions}
                            options={{
                                rowStyle: {
                                    fontSize: "12px",
                                },
                                actionsColumnIndex: -1,
                            }}
                            localization={{
                                toolbar: {
                                    searchPlaceholder: "Keyword search",
                                },
                                header: {
                                    actions: "",
                                },
                            }}
                            isLoading={viewModel.IsLoading}
                        />
                    </TablePageContent>
                </Box>
            </Box>
            {modals()}
        </>
    ));
};

import { Grid } from "@mui/material";
import styled from "@emotion/styled";

export interface IComponentProps {
    padding?: string;
    margin?: string;
    fontcolor?: string;
    backgroundcolor?: string;
    additionalcss?: string;
}

export const GridSpaced: any = styled(Grid)<IComponentProps>`
    padding: ${(props) => (props.padding !== undefined ? props.padding : "unset")};
    margin: ${(props) => (props.margin !== undefined ? props.margin : "unset")};
    background-color: ${(props) => (props.backgroundcolor !== undefined ? props.backgroundcolor : "unset")};
    ${(props) => props.additionalcss};
`;

export const DivWrapper: any = styled.div<IComponentProps>`
    width: 100%;
    min-height: 100vh;
    height: auto;
    background-size: cover;
    background-position: center center;
    background-color: ${(props) => (props.backgroundcolor !== undefined ? props.backgroundcolor : "unset")};
    margin: ${(props) => (props.margin !== undefined ? props.margin : "unset")};
    padding: ${(props) => (props.padding !== undefined ? props.padding : "unset")};
    background-repeat: no-repeat;
    ${(props) => props.additionalcss};
`;

export const DivWrapperNoVH: any = styled(DivWrapper)`
    min-height: unset;
`;

export const DivWrapperBGFixed: any = styled(DivWrapper)`
    background-attachment: fixed;
`;

export const FooterWrapper: any = styled(DivWrapper)<IComponentProps>`
    min-height: 1vh;
    padding-top: 1px;
    padding-bottom: 28px;

    @media (max-width: 767px) {
        padding-bottom: 18px;
    }

    ${(props) => props.additionalcss};
`;

export const DialogueWrapper: any = styled.div<IComponentProps>`
    border: 10px solid #129bdb;
    border-radius: 30px;
    text-align: center;
    background: #e7f6fe 0% 0% no-repeat padding-box;
    color: #464545;
    padding: ${(props) => (props.padding !== undefined ? props.padding : "20px")};
    margin: ${(props) => (props.margin !== undefined ? props.margin : "0 auto")};
    width: 100%;
    @media (max-width: 767px) {
        width: 335px;
        padding: 10px 20px;
    }
    ${(props) => props.additionalcss};
`;

export const MapWrapper: any = styled(DialogueWrapper)<IComponentProps>`
    border: 5px solid #129bdb;
    border-radius: 15px;
    overflow: hidden;
    width: 660px;
    height: 260px;
    padding: ${(props) => (props.padding !== undefined ? props.padding : "20px")};
    margin: ${(props) => (props.margin !== undefined ? props.margin : "0 auto")};
    @media (max-width: 767px) {
        width: 100%;
        border: none;
        border-radius: unset;
        padding: 0px;
        border-top: solid 5px #129bdb;
        border-bottom: solid 5px #129bdb;
        height: 256px;
    }
`;

interface ITitleWrapperProps extends IComponentProps {
    thinSubheading?: boolean;
}

export const TitleWrapper = styled.div<ITitleWrapperProps>`
    text-align: center;
    color: #464545;
    padding: ${(props) => (props.padding !== undefined ? props.padding : "20px")};
    margin: ${(props) => (props.margin !== undefined ? props.margin : "0 auto")};
    width: 100%;
    background-color: ${(props) => (props.backgroundcolor !== undefined ? props.backgroundcolor : "unset")};
    color: ${(props) => (props.fontcolor !== undefined ? props.fontcolor : "#ffffff")};
    span {
        color: #8cd2f7;
        font-weight: 700;
        letter-spacing: -0.35px;
    }

    h1 {
        margin: 15px 0px 27px;
        font-size: 32px;
        @media (max-width: 767px) {
            font-size: 18px;
            span {
                display: block;
                text-align: center;
            }
            max-width: 280px;
            margin: 0 auto 15px;
        }
    }

    h2 {
        font-weight: ${(props) => (props.thinSubheading ? 100 : "bold")};
        font-size: 24px;
        margin: 0px 0px 20px;
        @media (max-width: 767px) {
            font-size: 14px;
            margin: 0 auto 11px;
            max-width: 243px;
        }
    }

    p {
        font-size: 14px;
        @media (max-width: 767px) {
            font-size: 12px;
            width: 270px;
            span {
                display: block;
                text-align: center;
                width: 100%;
            }
            margin: 0px auto 11px;
        }
    }
    ${(props) => props.additionalcss};
`;

export const TitleWrapper2 = styled(TitleWrapper)`
    h2 {
        margin: 15px 0 0;
        font-size: 32px;
        @media (max-width: 767px) {
            font-size: 18px;
            span {
                display: block;
                text-align: center;
            }
            max-width: 324px;
            margin: 0 auto 30px;
        }
    }

    h3 {
        font-weight: ${(props) => (props.thinSubheading ? 100 : "bold")};
        font-size: 24px;
        margin: 0px 0px 20px;
        @media (max-width: 767px) {
            font-size: 14px;
            max-width: 234px;
            margin: 0 auto 11px;
        }
    }
`;

export const H3WithProp: any = styled.h3<IComponentProps>`
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    @media (max-width: 767px) {
        font-size: 14px;
        max-width: 234px;
        margin: 0 auto 11px;
    }
    padding: ${(props) => (props.padding !== undefined ? props.padding : "20px")};
    margin: ${(props) => (props.margin !== undefined ? props.margin : "0 auto")};
    ${(props) => props.additionalcss};
`;

export const SignUpWrapper: any = styled.div<IComponentProps>`
    border: 10px solid #129bdb;
    border-radius: 30px;
    text-align: center;
    background: #e7f6fe 0% 0% no-repeat padding-box;
    color: #464545;
    font-size: 16px;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    font-weight: 500;
    padding: 20px;
    margin: 0 auto;
    width: 100%;
    span {
        color: #129bdb;
        font-size: 24px;
        font-weight: 700;
        letter-spacing: -1.2px;
    }
    ${(props) => props.additionalcss};
`;

export const StartWrapper: any = styled.div<IComponentProps>`
    border: 10px solid #129bdb;
    border-radius: 30px;
    background: #e7f6fe 0% 0% no-repeat padding-box;
    color: #464545;
    font-size: 32px;
    font-weight: 500;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    letter-spacing: -1.2px;
    padding: 30px;
    span {
        color: #129bdb;
    }
    ${(props) => props.additionalcss};
`;

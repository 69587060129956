import { HttpClient, ViewModelBase } from "@shoothill/core";
import { container } from "tsyringe";
import { action, makeObservable, observable, runInAction } from "mobx";
import { ProfilesModel } from "./ProfilesModel";
import { CategoryModel } from "./CategoryModel";
import { CategoryViewModel } from "./CategoryViewModel";

export class ProfilesViewModel extends ViewModelBase<ProfilesModel> {
    private httpClient = container.resolve(HttpClient);
    public currentPageIndex: number = 0;

    constructor() {
        super(new ProfilesModel());
        makeObservable(this, {
            currentPageIndex: observable,
            setCurrentPageIndex: action,
            loadProfilesData: action,
        });
        const doAsync = async () => {
            await this.loadProfilesData();
        };
        const _ = doAsync();
    }

    public setCurrentPageIndex(index: number) {
        this.currentPageIndex = index;
    }

    public onboardingData = () => {
        console.log("Return profiles data");
    };

    public loadProfilesData = async () => {
        console.log("Load profile data");
        let response = await this.httpClient.Get<ProfilesModel>("/api/category/GetAllCategoryProfileAndRelated");

        let model = new ProfilesModel();
        for (let item of response.payload.categories) {
            let categoryViewModel = new CategoryViewModel(item);
            model.categories.push(categoryViewModel);
        }
    };
}

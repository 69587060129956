import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "../../Globals/Validation";

export class LoginModel extends ModelBase<LoginModel> {
    public emailAddress: string = "";
    public password: string = "";
    public rememberMe: boolean = false;
    public firstName: string = "";

    constructor() {
        super();
        makeObservable(this, {
            emailAddress: observable,
            password: observable,
            rememberMe: observable,
            firstName: observable,
        });
    }
}

export class LoginValidator extends Validator<LoginModel> {
    constructor() {
        super();

        this.ruleFor("emailAddress").emailAddress().maxLength(100);
        this.ruleFor("password").notEmpty().withMessage("Password cannot be empty").maxLength(100).withMessage("Password cannot exceed 100 characters");
    }
}

import { Box, Button, Checkbox, FormControl, FormGroup, FormLabel, InputLabel, MenuItem, Radio, TextField } from "@mui/material";
import { getHistory, KeyValuePair } from "@shoothill/core";
import { AppUrls } from "../../../AppUrls";
import { theme } from "../../../Globals/Styles/AppTheme";
import React, { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
//import { NewContentModal } from "../../../Views/Admin/Modal/NewContentModal";
import NewContentViewModel from "../../../Globals/ViewModels/Admin/NewContentViewModel";
import { useObserver } from "mobx-react-lite";
import { AutoGrid, EditText, GoogleAddress, MainSubmitButton, Show } from "../../../Globals";
import { FormControlLabel, Grid, Typography } from "@mui/material";
import { useRouter } from "../../../Globals/Hooks/UseRouter";
import { Editor } from "@tinymce/tinymce-react";

//custom
import { RadioGroup } from "../../../Globals";
import { ButtonContainer, ButtonGroup, HHButton } from "../../../Globals/Components/General/Buttons/HHButton";
import { ToolbarContainer } from "../../../Globals/Components/Table/ToolbarContainer";
import { useHistory } from "react-router";
import { MultiSelect } from "../../../Globals/Views/Primitives/MultiSelect";
import { Select } from "../../../Globals/Views/Primitives/Select";
import NewCustomerUserViewModel from "Globals/ViewModels/Admin/NewCustomerUserViewModel";
import { BackToLink } from "Globals/Components/General/BackToLink";
import { AdminPrimaryToolbar, ContentContainer, TablePageContent, ToolbarTitle } from "Globals/Components/General/General.styles";
import { ChipsContainer, HHChip } from "Globals/Views/Primitives/Select.styles";
import ClearIcon from "@mui/icons-material/Clear";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { FileUploader } from "Globals/Views/Primitives/FileUploader";
import { useColorTheme } from "Globals/Hooks/useColorTheme";
import { CustomDatePicker } from "Globals/Views/Primitives/CustomDatePicker";
import { CheckBox } from "Globals/Views/Primitives/CheckBox";
import { render } from "react-dom";
import { UserFile } from "Globals/Models";

// View model
import { FileUploaderViewModel } from "../../../Globals/ViewModels/FileUploaderViewModel";

export interface MatchParams {
    contentId: string | null;
}

export const AdminAddContentView: React.FC = () => {
    const { match } = useRouter();
    const { contentId } = match.params as MatchParams;

    const [label, setLabel] = useState("");
    const [viewModel] = useState(new NewContentViewModel());
    const [formSubmitErrorMsg, setFormSubmitErrorMsg] = useState("");

    const [imageUrl, setImageUrl] = React.useState("");
    const [sponsorimageUrl, setSponsorImageUrl] = React.useState("");
    const [value, setValue] = React.useState(false);
    const editorRef = React.useRef<any>(null);

    let history = useHistory();
    const goBack = () => history.push("/admin/content");
    const color = useColorTheme(label, "accent");

    React.useEffect(() => {
        if (contentId) {
            viewModel.getContent(contentId);
        }
    }, []);

    const handleAddContent = async (e: any) => {
        e.preventDefault();
        contentUpdate();
        if (!viewModel.isModelValid()) {
            setFormSubmitErrorMsg("We've encountered a problem");
        } else {
            setFormSubmitErrorMsg("");
            let didSucceed = await viewModel.upsertContent();
            if (didSucceed) {
                //alert("Saved successfully.");
            }
        }
    };

    const handleRadioInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        return e.target.value === "true";
    };

    const ErrorMsg = styled.span`
        font-size: 14px;
        color: ${theme.palette.error.main};
    `;

    const [fileUploaderViewModel] = useState(new FileUploaderViewModel(viewModel.getContentFiles));

    const renderOccurrenceInput = () => {
        const renderOccurrenceSubInputs = () => {
            switch (viewModel.getValue("occurrenceType")) {
                case "day":
                    viewModel.setValue("isCalendarBonus", true);
                    return (
                        <Select
                            displayName={"Day"}
                            options={viewModel.getOccurrenceByDayOptions}
                            command={viewModel.updateOccurrenceByDayCommand}
                            value={() => viewModel.getValue("occurrenceDay")}
                            validationMessage={() => viewModel.getError("occurrenceDay")}
                            color={color}
                            style={{ width: "175px" }}
                        />
                    );
                case "date":
                    return (
                        <CustomDatePicker
                            label={"Date"}
                            //viewModel={viewModel}
                            //value={viewModel.getValue("startDate")}
                            placeholder="DD/MM/YYYY"
                            value={() => viewModel.getValue("startDate")}
                            //fieldName={"startDate"}
                            //onChange={(newValue: Date) => {
                            //    viewModel.setValue("startDate", newValue);
                            //}}
                            validationMessage={() => viewModel.getError("startDate")}
                            command={viewModel.updateStartDateCommand}
                            width="175px"
                        />
                    );
                default:
                    return null;
            }
        };

        return (
            <>
                <Box style={{ width: "375px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Select
                        displayName={"Occurrence"}
                        options={viewModel.getOccurrenceOptions}
                        command={viewModel.updateOccurrenceTypeCommand}
                        value={() => viewModel.getValue("occurrenceType")}
                        validationMessage={() => viewModel.getError("occurrenceType")}
                        color={color}
                        style={{ width: "175px" }}
                    />

                    {renderOccurrenceSubInputs()}
                </Box>
            </>
        );
    };

    const renderCompanySelect = () => {
        return (
            <>
                <MultiSelect
                    multiple={true}
                    displayName={"Company (Select companies that can view this content)"}
                    options={viewModel.getCustomerOptions}
                    value={() => viewModel.getValue("customers")}
                    command={viewModel.updateMultiCustomersCommand}
                    color={color}
                    placeholder="Please select an option"
                    validationMessage={() => viewModel.getError("customers")}
                />

                <ChipsContainer>
                    {viewModel.getCustomerNames().map((option: KeyValuePair, index: number) => {
                        return (
                            <HHChip
                                key={option.value}
                                label={option.label}
                                clickable={false}
                                variant="filled"
                                onDelete={() => viewModel.deleteOption(option)}
                                deleteIcon={<ClearIcon style={{ color: "white" }} />}
                                customcolour={color}
                            />
                        );
                    })}
                </ChipsContainer>
            </>
        );
    };

    const renderDifficultyInput = () => {
        return (
            <>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Difficulty level</FormLabel>
                    <FormGroup row aria-label="difficulty-levels" sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <FormControlLabel
                            value="low"
                            control={
                                <Checkbox
                                    checked={viewModel.getValue("isEasy")}
                                    onChange={(e) => {
                                        viewModel.setValue("isEasy", e.target.checked);
                                        if (e.target.checked) {
                                            viewModel.setValue("difficulty", viewModel.getValue<number>("difficulty") + 1);
                                        } else {
                                            viewModel.setValue("difficulty", viewModel.getValue<number>("difficulty") - 1);
                                        }
                                    }}
                                    checkedIcon={<CheckBoxIcon />}
                                    icon={<CheckBoxOutlineBlankIcon />}
                                />
                            }
                            label="Low"
                        />
                        <FormControlLabel
                            value="medium"
                            control={
                                <Checkbox
                                    checked={viewModel.getValue("isMedium")}
                                    onChange={(e) => {
                                        viewModel.setValue("isMedium", e.target.checked);
                                        if (e.target.checked) {
                                            viewModel.setValue("difficulty", viewModel.getValue<number>("difficulty") + 1);
                                        } else {
                                            viewModel.setValue("difficulty", viewModel.getValue<number>("difficulty") - 1);
                                        }
                                    }}
                                    checkedIcon={<CheckBoxIcon />}
                                    icon={<CheckBoxOutlineBlankIcon />}
                                />
                            }
                            label="Medium"
                        />
                        <FormControlLabel
                            value="hard"
                            control={
                                <Checkbox
                                    checked={viewModel.getValue("isHard")}
                                    onChange={(e) => {
                                        viewModel.setValue("isHard", e.target.checked);
                                        if (e.target.checked) {
                                            viewModel.setValue("difficulty", viewModel.getValue<number>("difficulty") + 1);
                                        } else {
                                            viewModel.setValue("difficulty", viewModel.getValue<number>("difficulty") - 1);
                                        }
                                    }}
                                    checkedIcon={<CheckBoxIcon />}
                                    icon={<CheckBoxOutlineBlankIcon />}
                                />
                            }
                            label="High"
                            sx={{ marginRight: "-5px" }}
                        />
                    </FormGroup>
                </FormControl>
                <ErrorMsg style={{ marginTop: "-20px" }}>{viewModel.getError("difficulty")}</ErrorMsg>
            </>
        );
    };

    const renderBonusPointsInput = () => {
        return (
            <>
                <FormControl component="fieldset">
                    <FormLabel style={{ marginBottom: "0px" }} component="legend">
                        Have bonus?
                    </FormLabel>
                    <Box style={{ width: "60%%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <RadioGroup
                            /*disabled={viewModel.getValue("occurrenceType") === "day"}*/
                            row
                            aria-label="calendar-bonus"
                            name="row-radio-buttons-group"
                            defaultValue="false"
                            sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridColumn: "30px", width: "150px" }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                viewModel.setValue("isCalendarBonus", handleRadioInputValue(e));
                            }}
                        >
                            <FormControlLabel
                                value={true}
                                control={
                                    <Radio
                                        checked={viewModel.getValue<boolean>("isCalendarBonus") ? true : false}
                                        checkedIcon={<RadioButtonCheckedIcon />}
                                        icon={<RadioButtonUncheckedIcon />}
                                    />
                                }
                                label="Calendar"
                            />
                            <FormControlLabel
                                value={false}
                                control={
                                    <Radio
                                        checked={viewModel.getValue<boolean>("isCalendarBonus") ? false : true}
                                        checkedIcon={<RadioButtonCheckedIcon />}
                                        icon={<RadioButtonUncheckedIcon />}
                                    />
                                }
                                label="Engagement"
                            />
                        </RadioGroup>

                        <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            {/*<Typography variant="body2">Bonus points</Typography>*/}
                            <Select
                                displayName={"Points"}
                                options={viewModel.getBonusPointsOptions}
                                command={viewModel.updateBonusPointsCommand}
                                value={() => viewModel.getValue("bonusPoints")}
                                validationMessage={() => viewModel.getError("bonusPoints")}
                                color={color}
                                style={{ width: "75px" }}
                                disabled={!viewModel.getValue("isCalendarBonus")}
                            />
                        </Box>
                    </Box>
                </FormControl>
            </>
        );
    };

    const renderPointsInput = () => {
        return (
            <>
                <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Select
                        displayName={"Type"}
                        placeholder={"Please select a type"}
                        options={viewModel.getTypesSortedByOrdinal}
                        command={viewModel.updateTypeCommand}
                        value={() => viewModel.getValue("contentTypeId")}
                        validationMessage={() => viewModel.getError("contentTypeId")}
                        color={color}
                        style={{ width: "270px" }}
                        //getOptionLabel={(option) => option.label}
                    />

                    <Select
                        displayName={"Points"}
                        //placeholder={"Please select points..."}
                        options={viewModel.getPointsOptions}
                        command={viewModel.updatePointsCommand}
                        value={() => viewModel.getValue("points")}
                        validationMessage={() => viewModel.getError("points")}
                        color={color}
                        style={{ width: "75px" }}
                    />
                </Box>
            </>
        );
    };

    const contentUpdate = () => {
        if (editorRef.current) {
            viewModel.setValue<string>("contentHTML", editorRef.current.getContent());
        }
    };

    const renderTinyMCE = () => {
        return (
            <>
                <Editor
                    ref={editorRef}
                    apiKey="g828m3lar5y087wnket2v38pbvjugg2m1091afqssqvjj8ze"
                    onInit={(evt, editor) => {
                        editorRef.current = editor;
                    }}
                    initialValue={viewModel.getValue("contentHTML")}
                    init={{
                        height: 1000,
                        menubar: true,
                        /* enable title field in the Image dialog*/
                        image_title: true,
                        plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste image code help wordcount",
                        ],
                        toolbar:
                            "undo redo | formatselect | " +
                            "bold italic backcolor | alignleft aligncenter " +
                            "alignright alignjustify | bullist numlist outdent indent | " +
                            "removeformat | help",
                        file_picker_callback: function (cb, value, meta) {
                            let input = document.createElement("input");
                            input.setAttribute("type", "file");
                            input.setAttribute("accept", "image/*");
                            input.onchange = async function (e: any) {
                                let files: FileList | null = e.target.files;
                                if (files) {
                                    const file = files[0];

                                    let uploaded = await fileUploaderViewModel.upload(e);

                                    cb(uploaded?.fileUrl, { title: uploaded?.fileName });
                                }
                            };
                            input.click();
                        },
                        content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                />
            </>
        );
    };

    return useObserver(() => (
        <>
            <Box style={{ backgroundColor: "white", paddingBottom: "60px" }}>
                <Box display="flex" width="100%">
                    <TablePageContent>
                        <AdminPrimaryToolbar>
                            <Box>
                                <BackToLink onClick={goBack} text={"Back to content"} />
                                <ToolbarTitle>{contentId ? viewModel.getValue("title") : "New content"}</ToolbarTitle>
                            </Box>
                            <FormControlLabel
                                className="toolbar__checkbox"
                                labelPlacement="start"
                                control={<CheckBox displayName="Visible In App" value={() => viewModel.getValue("isVisibleInApp")} command={viewModel.updateIsVisibleInApp} />}
                                label=""
                            />
                        </AdminPrimaryToolbar>
                        <form onSubmit={handleAddContent}>
                            <Box style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                {formSubmitErrorMsg !== "" && <ErrorMsg>{formSubmitErrorMsg}</ErrorMsg>}
                            </Box>

                            <ContentContainer>
                                <Box className="new-content">
                                    <AutoGrid desktopColumns={"1fr"}>
                                        <Select
                                            displayName={"Category"}
                                            placeholder={"Please select a category"}
                                            options={viewModel.getCategoryOptions}
                                            command={viewModel.updateCategoryCommand}
                                            value={() => {
                                                setLabel(viewModel.getSelectedCategoryOption.label);
                                                return viewModel.getValue("categoryId");
                                            }}
                                            color={color}
                                            //getOptionLabel={(option) => option.label}
                                            validationMessage={() => viewModel.getError("categoryId")}
                                        />

                                        {renderCompanySelect()}

                                        {renderDifficultyInput()}

                                        <FileUploader
                                            files={viewModel.getMainImg}
                                            singleFile={true}
                                            text="Main image (305 x 188 pixels, max 2 MB)"
                                            onUpload={(file: UserFile) => {
                                                viewModel.setValue("mainImageUrl", file.fileUrl);
                                                viewModel.setValue("mainImageName", file.fileName);
                                                setImageUrl(file.fileUrl);
                                            }}
                                            onDelete={() => {
                                                viewModel.setValue("mainImageUrl", "");
                                                viewModel.setValue("mainImageName", "");
                                            }}
                                            color={theme.palette.info.main}
                                        />
                                        <ErrorMsg style={{ marginTop: "-10px" }}>{viewModel.getError("mainImageUrl")}</ErrorMsg>

                                        <FileUploader
                                            files={viewModel.getSponsorImg}
                                            singleFile={true}
                                            text="Sponsor image (if required)"
                                            onUpload={(file: UserFile) => {
                                                viewModel.setValue("sponsorImageUrl", file.fileUrl);
                                                viewModel.setValue("sponsorImageName", file.fileName);
                                                setSponsorImageUrl(file.fileUrl);
                                            }}
                                            onDelete={() => {
                                                viewModel.setValue("sponsorImageUrl", "");
                                                viewModel.setValue("sponsorImageName", "");
                                            }}
                                            color={theme.palette.info.main}
                                        />

                                        <EditText
                                            displayName="Title"
                                            command={viewModel.updateTitleCommand}
                                            value={() => viewModel.getValue("title")}
                                            validationMessage={() => viewModel.getError("title")}
                                            multiline={false}
                                            placeholder={"Title goes here"}
                                            className="editInput"
                                        />

                                        <EditText
                                            displayName="Subtitle"
                                            command={viewModel.updateSubtitleCommand}
                                            value={() => viewModel.getValue("subTitle")}
                                            validationMessage={() => viewModel.getError("subTitle")}
                                            multiline={true}
                                            type={"textarea"}
                                            rows={4}
                                            placeholder={"Your subtitle text goes here"}
                                        />

                                        <hr />
                                        {renderPointsInput()}
                                        <hr />
                                        {renderBonusPointsInput()}
                                        <hr />

                                        <GoogleAddress
                                            id="googleAddress"
                                            displayName="Location"
                                            command={viewModel.updateLocationCommand}
                                            value={() => viewModel.getValue("address")}
                                            placeholder="Type ahead address search"
                                            validationMessage={() => viewModel.getError("address")}
                                            setLocation={viewModel.setLocation}
                                            setAddress={(address: string) => viewModel.setValue("address", address)}
                                        />

                                        {viewModel.getValue<boolean>("isCalendarBonus") && renderOccurrenceInput()}

                                        <Box pt={1}>{renderTinyMCE()}</Box>
                                    </AutoGrid>
                                </Box>
                                <ButtonContainer>
                                    <ButtonGroup>
                                        <HHButton
                                            type="submit"
                                            text={contentId ? "Update content" : "Add new content"}
                                            width="173px"
                                            color={theme.palette.healthAppyBlue.main}
                                            // onClick={() => createNewContent()}
                                        />
                                        <HHButton type="button" text="Cancel" width="173px" color={theme.palette.info.main} onClick={() => goBack()} />
                                    </ButtonGroup>
                                </ButtonContainer>
                            </ContentContainer>
                        </form>
                    </TablePageContent>
                </Box>
            </Box>
        </>
    ));
};

import React, { useRef, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { ExampleViewModel } from "./ExampleViewModel";

export const CustomDatePicker: React.FC = () => {
    const { current: viewModel } = useRef(new ExampleViewModel());
    const [value, setValue] = React.useState<Date | null>(null);

    return useObserver(() => (
        <>
            {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    label="Basic example"
                    value={value}
                    onChange={(newValue) => {
                        setValue(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>*/}
            {/*<EditableInput<ExampleModel> type={"date"} viewModel={viewModel} fieldName={"date"} label={"Pick a date"} />*/}
            <label>Date Picked: {viewModel.getValue("date")}</label>
        </>
    ));
};

import { ModelBase, ViewModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { CategoryModel } from "./CategoryModel";

export class CategoryViewModel extends ViewModelBase<CategoryModel> {
    constructor(init?: any) {
        super(new CategoryModel(init));
        makeObservable(this, {});
    }
}

import { action, computed } from "mobx";
import { ViewModelBase, FieldType, KeyValuePair, Validation } from "@shoothill/core";
import { ExampleModel } from "./ExampleModel";
import { ICommandAsync, RelayCommandAsync } from "Globals/Commands";
import { IKeyState } from "../Globals/Views/Primitives/EditText";

export class ExampleViewModel extends ViewModelBase<ExampleModel> {
    public updateFirstNameCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        this.updateField("firstName", value);
    });
    public updateLastNameCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        this.updateField("lastName", value);
    });
    public updateTeamCommand: ICommandAsync = new RelayCommandAsync(async (value: KeyValuePair) => {
        this.updateField("team", value.value);
    });
    public updateTeamRadioCommand: ICommandAsync = new RelayCommandAsync(async (value: any) => {
        this.updateField("team", value);
    });
    public updateMultiTeamsCommand: ICommandAsync = new RelayCommandAsync(async (value: string[]) => {
        this.updateField("teams", value);
    });

    constructor() {
        super(new ExampleModel());
    }

    public get getTeams() {
        return [
            {
                value: "0",
                label: "Blackpool",
            },
            {
                value: "1",
                label: "Man City",
            },
            {
                value: "2",
                label: "Shrewsbury",
            },
            {
                value: "3",
                label: "Wolves",
            },
        ] as KeyValuePair[];
    }

    public getTeamNames = () => {
        let retval: KeyValuePair[] = [];
        console.log(this.getValue<number[]>("teams"));
        this.getValue<number[]>("teams").forEach((index: number) => {
            console.log(this.getTeams);
            retval.push(this.getTeams[index]);
        });
        return retval;
    };

    public deleteTeam = (team: KeyValuePair) => {
        console.log(team);
        let newTeams = this.getValue<number[]>("teams").filter((a) => a != team.value);
        this.setValue("teams", newTeams);
        console.log(newTeams);
    };

    private updateField(fieldName: keyof FieldType<ExampleModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    /*  public isModelValid(): boolean {
        return true;
    }

    public isFieldValid(fieldName: keyof FieldType<ExampleModel>, value: any): Validation {
        let errorMessage: string = "";
        let isValid: boolean = false;
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return [isValid, errorMessage];
    }*/

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}

import * as React from "react";

export const FileSVG = (props: any) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="24" viewBox="0 0 30 24">
        <g id="image" transform="translate(-1 -4)">
            <path
                id="Path_5652"
                data-name="Path 5652"
                d="M28,4H4A3,3,0,0,0,1,7V25a3,3,0,0,0,3,3H28a3,3,0,0,0,3-3V7a3,3,0,0,0-3-3Zm1,12.58L25.41,13a2,2,0,0,0-2.82,0l-7.66,7.66-3.56-2.84a2,2,0,0,0-2.75.24L3,24.48V7A1,1,0,0,1,4,6H28a1,1,0,0,1,1,1Z"
                fill="#959595"
            />
            <circle id="Ellipse_193" data-name="Ellipse 193" cx="3" cy="3" r="3" transform="translate(12 8)" fill="#959595" />
        </g>
    </svg>
);

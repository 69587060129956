import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { CSSProperties } from "react";
import { theme } from "../../../Styles/AppTheme";

export const ButtonContainer = styled.div`
    width: 100vw;
    height: 60px;
    box-shadow: 0px -3px 3px #00000029;
    border: 1px solid ${theme.palette.healthAppyBlue.main};
    position: fixed;
    bottom: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${theme.palette.common.white};
`;

export const ButtonGroup = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
`;

interface IHHButtonProps {
    text?: any;
    onClick?: any | void;
    type?: "button" | "submit" | "reset";
    width?: string;
    color?: string;
    style?: CSSProperties;
    disabled?: boolean;
}

export const HHButton = (props: IHHButtonProps) => {
    const { text, onClick, type, width, color, style, disabled } = props;

    const StyledRoundedButton = styled(Button)`
        box-sizing: content-box;
        font-family: "Manrope";
        font-weight: normal;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: bold;
        border-radius: 17px;
        height: 30px;
        background-color: ${color ? color : theme.palette.healthAppyBlue.dark};
        color: ${theme.palette.common.white};
        text-transform: none;
        border: none;

        :hover {
            background-color: ${color ? color : theme.palette.healthAppyBlue.dark};
            opacity: 0.7;
            cursor: pointer;
        }

        .MuiButton-root {
            font-size: 14px;
        }
    `;
    const buttonWidth = width ? width : "100px";

    const defaultStyle = { width: buttonWidth };
    return (
        <>
            <StyledRoundedButton onClick={onClick} type={type} style={{ ...defaultStyle, ...style }} disabled={disabled}>
                <div>{text}</div>
            </StyledRoundedButton>
        </>
    );
};

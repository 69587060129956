import React, { useEffect, useRef, useState } from "react";
import * as Mui from "@mui/material";

import { KeyValuePair } from "@shoothill/core";
import RoleViewModel from "Views/Admin/Roles/RoleViewModel";
import { UserModel } from "Globals/Models";
import UserViewModel from "Views/Admin/Users/UserViewModel";
import { AutoGrid } from "Globals/Components/Grid";

import { EditText } from "Globals/Views/Primitives/EditText";
import { Select } from "Globals/Views/Primitives/Select";
import styled from "@emotion/styled";
import { useRouter } from "Globals/Hooks/UseRouter";
import { useObserver } from "mobx-react-lite";
import { container } from "tsyringe";
import { DomainStores } from "Globals/Stores";
import { RoleStore } from "Globals/Stores/Domain/Admin";

export const ContainerBox = styled(Mui.Box)``;
export const DetailContainer = styled(Mui.Box)`
    position: relative;
`;

export const UserDetails: React.FC = () => {
    const { current: userViewModel } = useRef(new UserViewModel());
    const { history, match } = useRouter();
    const [newUser, setNewUser] = useState(false);

    let { id } = match.params as any;

    useEffect(() => {
        if (id === "0") {
            setNewUser(true);
        }
    }, []);

    useEffect(() => {
        if (history.location.pathname === "/userlist/user/0") {
            setNewUser(true);
        }
    }, [history.location.pathname]);

    return useObserver(() => (
        <ContainerBox>
            <Mui.Box marginTop="45px" marginBottom="10px" display="flex">
                <Mui.Box flexGrow={1}>
                    <Mui.Typography variant="h1" color="textPrimary">
                        User
                    </Mui.Typography>
                </Mui.Box>
            </Mui.Box>
            <hr />
            <DetailPanel newUser={newUser} id={id} userViewModel={userViewModel} />
            <Mui.Button variant="contained" color="primary" onClick={() => userViewModel.resetFailedLoginAttempts()}>
                Reset Failed Login Attempts Count
            </Mui.Button>
            <div>{userViewModel.IsLoading ? "Resetting..." : ""}</div>
            <div className="error">{userViewModel.resetLoginAttemptsError}</div>
        </ContainerBox>
    ));
};

interface DetailPanelProps {
    newUser: boolean;
    id: string;
    userViewModel: UserViewModel;
}
export const DetailPanel: React.FC<DetailPanelProps> = (props) => {
    const roleViewModel = container.resolve(RoleViewModel);
    const domainStores = container.resolve(DomainStores);
    const roleStore = container.resolve(RoleStore);
    const { userViewModel } = props;
    let user: UserModel = new UserModel("");

    //const [editable, seteditable] = useState(false);
    const [loader, setLoader] = useState(false);
    const { history } = useRouter();
    const [errorMessage, setErrorMessage] = useState("");

    const getUserRoles = async () => {
        const rolesResponse = await roleViewModel.loadRolesAsync();

        if (rolesResponse.wasSuccessful) {
            const response = await userViewModel.getUserRoles();

            if (!response.wasSuccessful) {
                if (response.errors) {
                    setErrorMessage(response.errors[0].message);
                } else {
                    setErrorMessage("There was a problem getting this user's roles.");
                }
            }
        } else {
            if (rolesResponse.errors) {
                setErrorMessage(rolesResponse.errors[0].message);
            } else {
                setErrorMessage("There was a problem getting the roles.");
            }
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        if (props.id !== "0") {
            let currentUser = userViewModel.getUser(props.id);
            if (currentUser) {
                userViewModel.setUser(currentUser, false);
                getUserRoles();
            } else {
                userViewModel.loadUsersAsync().then(() => {
                    let currentUser = userViewModel.getUser(props.id);
                    if (currentUser) {
                        userViewModel.setUser(currentUser, false);
                        getUserRoles();
                    }
                });
            }
        } else {
            userViewModel.setUser(user, true);
        }
    }, []);

    const doSubmit = async (e: any) => {
        e.preventDefault();
        if (!(await userViewModel.isModelValid())) {
            setErrorMessage("Problem");
        } else {
            setErrorMessage("");
            setLoader(true);
            userViewModel.postUserDetailAsync().then(() => {
                setLoader(false);
                domainStores.OpenSnackBar("User saved successfully", "success");
                history.push("/admin");
            });
        }
    };

    return useObserver(() => (
        <ContainerBox>
            <Mui.Box p={3}>
                <Mui.Box marginTop="30px" display="flex" textAlign="right">
                    <Mui.Box flexGrow={1}>
                        <Mui.Box display="flex" marginTop="20px">
                            <Mui.Box justifyContent="flex-end" flexGrow={1} paddingRight="20px">
                                <Mui.Button id="save" variant="contained" color="primary" type="submit" disabled={loader}>
                                    {!loader ? "Save" : "Saving"}
                                </Mui.Button>
                                <span style={{ paddingRight: "20px" }} />
                                <Mui.Button
                                    id="cancel"
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => {
                                        history.push("/admin");
                                    }}
                                >
                                    Cancel
                                </Mui.Button>
                            </Mui.Box>
                        </Mui.Box>
                    </Mui.Box>
                </Mui.Box>
                <form
                    onSubmit={doSubmit}
                    id="detailsPanelForm"
                    style={{
                        width: "99%",
                        boxShadow: "0 14px 28px rgba(0,0,0,0.15), 0 10px 10px rgba(0,0,0,0.12) ",
                        borderRadius: "15px",
                        margin: "20px auto 50px",
                        border: "none",
                        padding: "20px",
                    }}
                >
                    <DetailContainer>
                        {errorMessage !== "" && (
                            <Mui.Typography variant="caption" style={{ color: "red" }}>
                                {errorMessage}
                            </Mui.Typography>
                        )}

                        <AutoGrid desktopColumns={"1fr 1fr"} rowGap="20px">
                            <EditText displayName="Username" value={() => userViewModel.getValue("email")} command={userViewModel.updateUserNameCommand} />
                            <EditText displayName="Password" type={"password"} value={() => userViewModel.getValue("password")} command={userViewModel.updatePasswordCommand} />
                            <EditText displayName="First Name" value={() => userViewModel.getValue("firstName")} command={userViewModel.updateFirstNameCommand} />
                            <EditText displayName="Last Name" value={() => userViewModel.getValue("lastName")} command={userViewModel.updateLastNameCommand} />

                            <Select
                                displayName="Roles"
                                value={() => userViewModel.getValue("role")}
                                options={roleStore.getRoles.map((role) => ({ value: role, label: role.name })) as KeyValuePair[]}
                                command={userViewModel.updateSelectRolesCommand}
                            />
                        </AutoGrid>
                    </DetailContainer>
                </form>
            </Mui.Box>
        </ContainerBox>
    ));
};

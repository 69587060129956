import { Box, FormControl, MenuItem, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { KeyValuePair } from "@shoothill/core";
import { ExampleViewModel } from "./ExampleViewModel";
import { ExampleModel } from "./ExampleModel";
import { useObserver } from "mobx-react-lite";
import { useViewModel } from "../Globals/Hooks/UseViewModel";
import { Select } from "../Globals/Views/Primitives/Select";
import { TestModel } from "./TestModel";
import { MultiSelect2 } from "../Globals/Views/Primitives/MultiSelect2";

export const SelectInput: React.FC = () => {
    const { current: viewModel } = useRef(new ExampleViewModel());

    return useObserver(() => (
        <>
            <Box p={2} width={"400px"}>
                <Select value={() => viewModel.getValue("team")} displayName={"Choose a team..."} options={viewModel.getTeams} command={viewModel.updateTeamCommand} />
                <label>Worst Team Selected value is: {viewModel.getValue("team")}</label>
                <br />
                <label>Worst Team Selected label is: {viewModel.getTeams[viewModel.getValue<number>("team")]?.label ?? ""}</label>
            </Box>
            <Typography variant={"poster"}>Multi-Select</Typography>
            <Box p={2} width={"400px"}>
                <MultiSelect2 options={viewModel.getTeams} value={() => viewModel.getValue("teams")} command={viewModel.updateMultiTeamsCommand} />

                {viewModel.getTeamNames().map((kvp: KeyValuePair, index: number) => {
                    return (
                        <Box key={"ms" + index}>
                            {kvp.label}
                            <Box onClick={() => viewModel.deleteTeam(kvp)}>Delete</Box>
                        </Box>
                    );
                })}
            </Box>
        </>
    ));
};

import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Box, Typography, CardContent, TextareaAutosize, TextField, Grid, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useObserver } from "mobx-react-lite";
import { AdminProfileSummaryContainer } from "./AdminProfile.styles";
import { HHButton } from "../../../Globals/Components/General/Buttons/HHButton";
import NewProfileViewModel from "Globals/ViewModels/Admin/NewProfileViewModel";
import { theme } from "Globals/Styles/AppTheme";
import { useHistory } from "react-router";
const SummaryDetails = styled(Box)`
    // height: 100px;
    // display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    gap: 30px;
    margin-bottom: 25px;
`;

interface ICustomHrProps {
    color: string;
    type: string;
}

const CustomHr = styled.div<ICustomHrProps>`
    height: 1px;
    width: 100%;
    border-bottom: 1px ${(props) => props.type} ${(props) => props.color};
`;

const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 25px;
`;

const ButtonGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 375px;
`;

const CategoryCard = styled.div`
    background: #efefef;
    padding: 20px;
`;

const CategoryCardInner = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 10px;
    color: rgb(0, 0, 0);
`;

const ItemRatingLable = styled.h4`
    padding: 12px;
    width: 100px;
    display: inline-block;
    text-align: center;
    font-size: 14px;
`;

const EditProfilLable = styled.h5`
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 14px;
`;

const ScoreRange = styled.div`
    font-size: 14px;
    margin-bottom: 10px;
    color: #000;
`;

export interface ProfileSummary {
    categoryId: string;
    scoreRangeLow: number;
    scoreRangeHigh: number;
    summaryText: string;
    rating: string;
    id: string;
}

export const AdminProfileSummariesView: React.FC = () => {
    const [viewModel] = useState(new NewProfileViewModel());
    const [categoryId, setCategoryId] = React.useState(viewModel.getCategoryColors[0]?.id);
    const [isLoading, setIsLoading] = React.useState(true);
    const [profileSummary, setProfileSummary] = useState<ProfileSummary[]>([]);
    let history = useHistory();
    const goBack = () => history.push("/admin/profiles");
    useEffect(() => {
        if (categoryId !== "") {
            getProfileSummariesByCategoryId();
        }
    }, [categoryId]);

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setCategoryId(newValue);
    };

    const getProfileSummariesByCategoryId = async () => {
        setIsLoading(true);
        const response = await viewModel.getProfileSummaryByCategoryIdAsync(categoryId);
        setProfileSummary(response.payload);
        setIsLoading(false);
    };

    const updateProfileSummaries = async () => {
        await viewModel.updateProfileSummariesAsync(profileSummary);
    };

    return useObserver(() => (
        <>
            <Box style={{ backgroundColor: "white" }}>
                <AdminProfileSummaryContainer>
                    <SummaryDetails component="form">
                        <Box sx={{ width: "100%", typography: "body1" }}>
                            <TabContext value={categoryId}>
                                <Box sx={{ borderBottom: 1, borderColor: "divider", marginTop: "25px", marginBottom: "25px" }}>
                                    <TabList onChange={handleTabChange}>
                                        {viewModel.getCategoryColors.map((tab) => (
                                            <Tab
                                                label={tab.name}
                                                style={{ backgroundColor: `${tab.colour}`, textTransform: "none", width: `${100 / viewModel.getCategoryColors.length}%` }}
                                                value={tab.id}
                                            />
                                        ))}
                                    </TabList>
                                </Box>
                                <TabPanel value={categoryId} style={{ padding: 0 }}>
                                    <Grid container spacing={3}>
                                        {profileSummary.map((item: ProfileSummary, index) => (
                                            <Grid item xs={4} key={index}>
                                                <Grid>
                                                    <ItemRatingLable
                                                        style={{
                                                            backgroundColor: `${viewModel.getCategoryColors.find((x) => x.id === categoryId)?.colour}`,
                                                        }}
                                                    >
                                                        {item.rating}
                                                    </ItemRatingLable>
                                                </Grid>
                                                <CategoryCard>
                                                    <EditProfilLable>Edit Profile</EditProfilLable>
                                                    <Grid>
                                                        <CardContent style={{ padding: 0, paddingBottom: 10 }}>
                                                            <TextareaAutosize
                                                                value={item.summaryText}
                                                                minRows={12}
                                                                id={`summaryText-${index}`}
                                                                onChange={(event) => {
                                                                    const temp = profileSummary;
                                                                    temp[index].summaryText = event.target.value;
                                                                    setProfileSummary(JSON.parse(JSON.stringify(temp)));
                                                                }}
                                                                style={{ borderWidth: 1, borderStyle: "solid", borderColor: "#D4D4D4", padding: 8, width: "100%" }}
                                                            />
                                                        </CardContent>
                                                    </Grid>
                                                    <ScoreRange>Score range(max points 12)</ScoreRange>
                                                    <CategoryCardInner>
                                                        <TextField
                                                            value={item.scoreRangeLow}
                                                            type="number"
                                                            id={`scoreRangeLow-${index}`}
                                                            onChange={(event) => {
                                                                const temp = profileSummary;
                                                                temp[index].scoreRangeLow = parseInt(event.target.value.toString());
                                                                setProfileSummary(JSON.parse(JSON.stringify(temp)));
                                                            }}
                                                            style={{
                                                                width: 65,
                                                                textAlign: "center",
                                                                fontSize: 14,
                                                                borderRadius: 0,
                                                                background: "#fff",
                                                                borderWidth: 1,
                                                                borderStyle: "solid",
                                                                borderColor: "#D4D4D4",
                                                                margin: "5px",
                                                            }}
                                                        />
                                                        to
                                                        <TextField
                                                            value={item.scoreRangeHigh}
                                                            type="number"
                                                            id={`scoreRangeHigh-${index}`}
                                                            onChange={(event) => {
                                                                const temp = profileSummary;
                                                                temp[index].scoreRangeHigh = parseInt(event.target.value.toString());
                                                                setProfileSummary(JSON.parse(JSON.stringify(temp)));
                                                            }}
                                                            style={{
                                                                margin: "5px",
                                                                width: 65,
                                                                textAlign: "center",
                                                                fontSize: 14,
                                                                borderRadius: 0,
                                                                background: "#fff",
                                                                borderWidth: 1,
                                                                borderStyle: "solid",
                                                                borderColor: "#D4D4D4",
                                                            }}
                                                        />
                                                        points
                                                    </CategoryCardInner>
                                                </CategoryCard>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </TabPanel>
                            </TabContext>
                        </Box>
                    </SummaryDetails>
                    {/* <Box height={100}></Box> */}
                    <CustomHr color={theme.palette.healthAppyBlue.main} type="solid" />
                    <ButtonContainer>
                        <ButtonGroup>
                            <HHButton width="173px" text="Save" onClick={updateProfileSummaries} style={{ margin: "10px" }} />
                            <HHButton width="173px" text="Cancel" color={theme.palette.info.main} style={{ margin: "10px" }} onClick={() => goBack()} />
                        </ButtonGroup>
                    </ButtonContainer>
                </AdminProfileSummaryContainer>
            </Box>
        </>
    ));
};

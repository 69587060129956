import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { ApiResult, FieldType, HttpClient, ViewModelBase } from "@shoothill/core";
import { container } from "tsyringe";

// App
import { AppUrls } from "AppUrls";
import { Customer } from "Globals/Models/Customer";
import { ContentLite } from "../../Models";

export interface AdminCustomerTableDataModel {
    id: string;
    name: string;
    contactName: string;
    contactEmail: string;
    contactNumber: string;
    numberOfUsers: number;
    createdDate: string;
    lastUpdatedDate: string;
    isDeleted: boolean;
}

export default class AdminCustomersListViewModel extends ViewModelBase {
    private httpClient = container.resolve(HttpClient);

    public customers: Customer[] = [];
    constructor() {
        super(null);
        makeObservable(this, {
            customers: observable,
            loadData: action,
            getTableData: computed,
        });
        this.loadData("");
    }

    public async loadData(query: string): Promise<void> {
        this.setIsLoading(true);
        const path = AppUrls.Server.Admin.SearchAllCustomers + "/?search=" + query;
        const apiResult = await this.httpClient.Get<Customer[]>(path);
        if (apiResult.wasSuccessful) {
            runInAction(() => {
                this.customers = apiResult.payload;
            });
        }
        this.setIsLoading(false);
    }

    public async deleteCustomer(id: string): Promise<void> {
        this.setIsLoading(true);
        const path = AppUrls.Server.Admin.DeleteCustomer + "/?id=" + id;
        const apiResult = await this.httpClient.Delete<Customer>(path);
        if (apiResult.wasSuccessful) {
            let customer = this.customers.find((customer) => customer.id === id);
            runInAction(() => {
                if (customer != undefined) {
                    this.customers.splice(this.customers.indexOf(customer), 1);
                }
            });
        }
        this.setIsLoading(false);
    }

    public get getTableData() {
        let tableData: AdminCustomerTableDataModel[] = [];
        this.customers.forEach((customer) => {
            tableData.push({
                id: customer.id ? customer.id : "",
                name: customer.name,
                contactName: customer.contactName,
                contactEmail: customer.contactEmail,
                contactNumber: customer.contactNumber,
                numberOfUsers: customer.numberOfUsers,
                createdDate: new Date(customer.createdDate).toLocaleDateString("en-GB"),
                lastUpdatedDate: new Date(customer.lastUpdatedDate).toLocaleDateString("en-GB"),
                isDeleted: customer.isDeleted,
            });
        });
        return tableData;
    }
}

import React, { useState, useContext } from "react";
import { Link as RouterLink, useLocation, Redirect, useHistory } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import { Button, Drawer, AppBar, List, Box, ListItem } from "@mui/material";
import { useObserver } from "mobx-react-lite";
import Logo from "Content/assets/logoLandscape.svg";
import { AppUrls } from "AppUrls";
import { AutoGrid } from "Globals/Components/Grid";
import { Else, Show } from "Globals/Components";
import { container } from "tsyringe";
import { AccountStore } from "Globals/Stores/Domain";
import { GlobalHistory } from "../../index";

//custom
import { MenuLogoutIcon } from "Content/assets/MenuLogoutIcon";
import { useStyles, ListContainer, AppBarContainer } from "./Header.styles";

interface LinkButonProps {
    linkText?: string;
    textStyle?: string | undefined;
    linkImage?: any;
    to?: any;
    onClick?: any;
}

export const LinkButton: React.FC<LinkButonProps> = (props: LinkButonProps) => {
    const { linkText, textStyle, linkImage, to, onClick } = props;
    let component: any = RouterLink;
    const classes = useStyles();
    const location = useLocation();

    const isSameRoute = () => {
        if (to) {
            return location.pathname.includes(to);
        }
    };

    const content = !linkImage ? (
        <ListContainer>
            <Typography
                style={
                    textStyle === "uppercase"
                        ? { fontSize: "12px", fontWeight: "bold", textDecoration: "none", textTransform: "uppercase" }
                        : { fontSize: "12px", fontWeight: "bold", textDecoration: "none", textTransform: "capitalize" }
                }
                color={"inherit"}
            >
                {linkText}
            </Typography>
        </ListContainer>
    ) : (
        <ListContainer className="border-right">
            <MenuLogoutIcon />
        </ListContainer>
    );

    if (to === null || to === undefined) {
        component = Box;
    }

    return (
        <ListItem onClick={onClick} classes={{ root: classes.button, selected: classes.isActive }} component={component} to={to} selected={isSameRoute()}>
            {content}
        </ListItem>
    );
};

export const Header: React.FC = () => {
    const accountStore = container.resolve(AccountStore);
    const history = useHistory();

    const handleLogOut = (): void => {
        accountStore.Logout();
        history.push(AppUrls.Client.Account.Login);
    };

    const loggedInList = (
        <>
            <Show if={accountStore.isInRole("admin")}>
                <LinkButton linkText={"Customers"} to={AppUrls.Client.Admin.AdminHome} />
                <LinkButton linkText={"Content"} to={AppUrls.Client.Admin.Content} />
                <LinkButton linkText={"Profiles"} to={AppUrls.Client.Admin.Profile} />
                <LinkButton textStyle={"uppercase"} linkText={"FAQ"} to={AppUrls.Client.Admin.FAQ} />
            </Show>
            <LinkButton linkText={""} linkImage={MenuLogoutIcon} onClick={() => handleLogOut()} />
        </>
    );

    const renderContent = () => {
        if (accountStore.IsLoggedIn === false) {
            return <Redirect to={AppUrls.Client.Account.Login} />;
        } else {
            return (
                <AppBarContainer>
                    <AppBar style={{ height: "60px" }} position={"sticky"} color={"secondary"}>
                        <Toolbar style={{ height: "60px" }}>
                            <Box flexGrow={1} paddingTop={"5px"} style={{ cursor: "pointer" }}>
                                <img src={Logo} alt="Healthappy" onClick={() => GlobalHistory.push("/")} />
                            </Box>
                            {loggedInList}
                        </Toolbar>
                    </AppBar>
                </AppBarContainer>
            );
        }
    };

    return useObserver(() => <>{renderContent()}</>);
};

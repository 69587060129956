// Libs
import styled, { StyledComponent } from "@emotion/styled";
import React, { useState, useEffect } from "react";
import { useObserver } from "mobx-react-lite";
import * as Mui from "@mui/material";
import CancelRounded from "@mui/icons-material/CancelRounded";

//SVG's
import { CrossSVG } from "../IconSVGs/CrossSVG";
import { FileSVG } from "../IconSVGs/FileSVG";

// Types
import { UserFile } from "../../../Globals/Models/UserFile";

// View model
import { FileUploaderViewModel } from "../../../Globals/ViewModels/FileUploaderViewModel";

//custom
import { theme } from "../../../Globals/Styles/AppTheme";
import { userInfo } from "os";
import Box from "@mui/system/Box";
import { Loader } from "Globals/Components/Loader";

export interface IProps {
    text: string;
    loading?: boolean;
    disabled?: boolean;
    files: UserFile[];
    onUpload: (file: UserFile) => any;
    onDelete: (file: UserFile) => any;
    singleFile?: boolean;
    color: string;
    errorText?: () => any;
}

interface IUploaderProps {
    color: string;
}

//Styles
export const Uploader = styled.div<IUploaderProps>`
    background-color: ${theme.palette.common.white};

    padding: 0px;
    border-radius: 0px;
    flex-direction: column;

    legend {
        font-size: 14px;
        font-weight: bold;
        color: ${theme.palette.text.primary};
        margin-bottom: 5px;
    }

    .upload-drop-area {
        background-color: transparent;
        border-radius: 0px;
        border: 1px ${(props) => (props.color ? props.color : theme.palette.info.main)} dashed;
        padding: 5px 0px 5px;
        position: relative;
        max-width: 375px;
        min-height: 90px;
        max-height: 288px;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .img-development {
            max-height: 278px;
        }
    }
    img {
        margin: auto;
        //height: 112px;

        /* @media (max-width: 767px) {
            height: 72px;
        } */
    }

    input {
        opacity: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
    }

    input,
    input::-webkit-file-upload-button {
        cursor: pointer;
    }

    .upload-placeholder {
        color: #7f7f7f;
        font-size: 12px;
        width: 100%;
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
    }

    .upload-error {
        color: red;
        font-size: 12px;
        width: 100%;
        text-align: right;
    }

    .files-uploaded {
        li {
            display: flex;
            align-items: center;
            padding: 15px;
            list-style-type: none;
            border-bottom: 1px #ced4da solid;
            a {
                display: flex;
                align-items: center;
                cursor: pointer;
                margin-right: 30px;
                .file-name {
                    white-space: nowrap;
                    overflow: hidden;
                    width: 350px;
                    text-overflow: ellipsis;
                    font-size: 12px;
                }
                > * {
                    margin-right: 10px;
                }
            }
        }
    }
`;

const AnswerBtn = styled.button`
    display: inline-block;
    border: none;
    padding: 0;
    margin: 0;
    text-decoration: none;
    background: none;
    line-height: 1;
    cursor: pointer;
    text-align: center;
    transition: transform 250ms ease-in-out, transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;

    svg {
        height: 30px;
        width: 30px;
    }

    :hover,
    :focus {
        transform: scale(1.1);
    }

    :active {
        transform: scale(1.1);
    }
`;

/**
 * This component can be used to upload files to the blob storage.
 * It accepts a list of files type of (UserFile) and a callback that returns the uploaded (UserFile).
 */
export const FileUploader: React.FC<IProps> = (props: IProps) => {
    const [viewModel] = useState(new FileUploaderViewModel(props.files));
    const [isLoading, setIsLoading] = React.useState(true);

    useEffect(() => {
        if (props.files.length > 0) {
            viewModel.showEditFile(props.files);
        }
    }, [props.files]);

    const showUploadArea = (): boolean => {
        let show = true;
        if (props.singleFile == true && viewModel.filesToDisplay.length > 0) {
            show = false;
        }
        return show;
    };
    const hasFile = (): boolean => {
        return viewModel.filesToDisplay.length > 0;
    };

    const handleErrorText = (): any => {
        if (props.errorText) {
            props.errorText();
        } else {
            return "";
        }
    };

    const removeImage = () => {
        viewModel.delete(viewModel.filesToDisplay[0]);
        props.onDelete(viewModel.filesToDisplay[0]);
    };

    return useObserver(() => (
        <>
            <Uploader color={props.color} onClick={() => handleErrorText()}>
                <legend>{props.text}</legend>
                {hasFile() === false && (
                    <div className="upload-drop-area" style={{ background: "transparent" }}>
                        <>
                            <div className="upload-placeholder">
                                {viewModel.isLoading && <Loader loadingText="Please wait..." />}
                                <FileSVG />
                            </div>
                            <p className="upload-placeholder">Click or drag image file</p>
                        </>
                        <input
                            type="file"
                            accept=".jpg,.png,.svg"
                            disabled={props.disabled || (props.singleFile && viewModel.filesToDisplay.length > 0)}
                            onChange={async (e) => {
                                let userFile: UserFile | null = await viewModel.upload(e);
                                if (userFile != null) {
                                    props.onUpload(userFile);
                                }
                            }}
                        />
                    </div>
                )}
                {hasFile() === true && (
                    <>
                        <div className="upload-drop-area">
                            <>
                                <div className="upload-placeholder">
                                    <img ref={viewModel.imageRef} onLoad={viewModel.checkImageDimensions} src={viewModel.filesToDisplay[0].fileUrl} className="img-development" />
                                </div>
                            </>
                        </div>
                        <Mui.Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            style={{
                                padding: "10px",
                                backgroundColor: "#F2F0F0",
                                width: "100%",
                            }}
                        >
                            <span
                                className="file-name"
                                style={{
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                }}
                            >
                                {viewModel.filesToDisplay[0].fileName}
                            </span>

                            <AnswerBtn onClick={() => removeImage()}>
                                <CancelRounded style={{ width: "30px", height: "30px", color: "#404040" }} />
                            </AnswerBtn>
                        </Mui.Box>
                    </>
                )}
                {viewModel.errorMessage.length !== 0 && (
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        style={{
                            padding: "10px",
                            backgroundColor: `${theme.palette.warning.light}`,
                            width: "100%",
                            margin: "5px 0",
                        }}
                    >
                        <span style={{ fontSize: 14, color: `${theme.palette.common.white}`, marginRight: 10 }}>{viewModel.errorMessage}</span>

                        <AnswerBtn
                            onClick={() => {
                                viewModel.resetErrorMsg();
                                console.log(viewModel.errorMessage);
                            }}
                        >
                            <CancelRounded style={{ width: "30px", height: "30px", color: `${theme.palette.common.white}` }} />
                        </AnswerBtn>
                    </Box>
                )}
            </Uploader>
        </>
    ));
};

import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { theme } from "../../../Globals/Styles/AppTheme";

export const CustomerTabs: any = styled(Box)`
    box-sizing: content-box;
    width: 608px;
    height: 40px;
    display: flex;
    align-items: center;
    border: 1px solid ${theme.palette.healthAppyBlue.main};

    .selected {
        background-color: ${theme.palette.healthAppyBlue.main};
        color: ${theme.palette.common.white};
    }
`;

export const CustomerTab: any = styled(Box)`
    height: 100%;
    width: 50%;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${theme.palette.common.black};
    background-color: ${theme.palette.common.white};

    :hover {
        cursor: pointer;
        background-color: ${theme.palette.healthAppyBlue.main};
        color: ${theme.palette.common.white};
        opacity: 0.4;
    }
`;

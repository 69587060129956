// Libs
import { ModelBase } from "@shoothill/core";
import { Validator } from "../../../../Globals/Validation";
import { action, makeObservable, observable } from "mobx";

export class NewCustomerUserModel extends ModelBase<NewCustomerUserModel> {
    public id: string = "";
    public customerId: string = "";
    public firstName: string = "";
    public lastName: string = "";
    public contactEmail: string = "";
    public contactNumber: string = "";
    public latitude: string = "";
    public longitude: string = "";
    public address: string = "";
    public postcode: string = "";
    public height: number = 0;
    public weight: number = 0;
    public dateOfBirth: Date | null = null;
    public ethnicityId: string = "";
    public roleId: string = "user";

    constructor() {
        super();
        makeObservable(this, {
            id: observable,
            customerId: observable,
            firstName: observable,
            lastName: observable,
            contactEmail: observable,
            contactNumber: observable,
            latitude: observable,
            longitude: observable,
            address: observable,
            postcode: observable,
            height: observable,
            weight: observable,
            dateOfBirth: observable,
            ethnicityId: observable,
            roleId: observable,
            reset: action,
        });
    }

    public reset = (): void => {
        this.id = "";
        this.customerId = "";
        this.firstName = "";
        this.lastName = "";
        this.contactEmail = "";
        this.contactNumber = "";
        this.latitude = "";
        this.longitude = "";
        this.address = "";
        this.postcode = "";
        this.height = 0;
        this.weight = 0;
        this.dateOfBirth = null;
        this.ethnicityId = "";
    };
}

export class NewCustomerUserValidator extends Validator<NewCustomerUserModel> {
    constructor() {
        super();

        this.ruleFor("firstName").notEmpty();
        this.ruleFor("lastName").notEmpty();
        this.ruleFor("contactEmail").notEmpty().emailAddress();
        this.ruleFor("contactNumber").notEmpty();
        //this.ruleFor("address").notEmpty();
        // this.ruleFor("height").notEmpty();
        // this.ruleFor("height").notEmpty();
        // this.ruleFor("dateOfBirth").notEmpty();
        //this.ruleFor("ethnicityId").notEmpty();
    }
}

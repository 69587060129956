import * as React from "react";
import * as MobX from "mobx";
import * as MobXReact from "mobx-react-lite";
import { CustomerTab, CustomerTabs } from "./Tabs.styles";

export interface ITabProps {
    title: string | MobX.IComputedValue<string>;
    key: string;
}

interface ITabsProps {
    tabs: ITabProps[];
    selected: string;
    onTabClick(tab: ITabProps): void;
}

export const Tabs: React.FC<ITabsProps> = ({ tabs, selected, onTabClick }) => {
    const handleTabClick = (tab: ITabProps) => {
        onTabClick(tab);
    };

    return MobXReact.useObserver(() => (
        <CustomerTabs>
            {tabs.map((tab: ITabProps) => (
                <CustomerTab className={`${tab.key === selected ? "selected" : ""} `} role="button" tabIndex={0} onClick={() => handleTabClick(tab)}>
                    {tab.title}
                </CustomerTab>
            ))}
        </CustomerTabs>
    ));
};

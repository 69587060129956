import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { ApiResult, FieldType, HttpClient, ViewModelBase } from "@shoothill/core";
import { container } from "tsyringe";

// App
import { NewCustomerModel, NewCustomerValidator } from "../../../Globals/Models/Domain/Admin/NewCustomerModel";
import { NewCustomer } from "../../../Globals/Models/NewCustomer";
import { AppUrls } from "../../../AppUrls";
import { DomainStores } from "../../../Globals/Stores";
import { RelayCommandAsync, ICommandAsync } from "../../../Globals/Commands";
import { IKeyState } from "../../../Globals";
export default class NewCustomerViewModel extends ViewModelBase<NewCustomerModel> {
    private httpClient = container.resolve(HttpClient);
    private domainStores = container.resolve(DomainStores);

    constructor() {
        super(new NewCustomerModel());
        this.setValidator(new NewCustomerValidator());
    }

    public updateNameCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("name", value, keyState);
    });

    public updateContactNameCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("contactName", value, keyState);
    });

    public updateContactEmailCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("contactEmail", value, keyState);
    });

    public updateContactNumberCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("contactNumber", value, keyState);
    });

    private async updateField(fieldName: keyof FieldType<NewCustomerModel>, value: any, keyState?: IKeyState) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    public createNewCustomer = async (): Promise<boolean> => {
        this.setIsLoading(true);
        let isMyModelValid: boolean = await this.isModelValid();
        if (isMyModelValid) {
            let modelToSave = this.getModel;
            let newCustomer: NewCustomer = {
                name: modelToSave.name,
                contactName: modelToSave.contactName,
                contactEmail: modelToSave.contactEmail,
                contactNumber: modelToSave.contactNumber,
            };

            const apiResult = await this.httpClient.Post<NewCustomer>(AppUrls.Server.Admin.CreateNewCustomer, newCustomer);
            if (apiResult.wasSuccessful) {
                this.history.push("/");
                return true;
            } else {
                apiResult.errors.forEach((error) => {
                    this.domainStores.OpenSnackBar(`Error: ${error.message}`, "error");
                });
                return false;
            }
        }

        return false;
    };
}

import { Box, Button, Modal } from "@mui/material";
import styled from "@emotion/styled";
import { theme } from "../../../Globals/Styles/AppTheme";
import NewCustomerViewModel from "../../../Globals/ViewModels/Admin/NewCustomerViewModel";
import { EditText } from "../../../Globals/Views/Primitives/EditText";
import React, { useEffect, useContext, useState } from "react";
import { useObserver } from "mobx-react-lite";
import Typography from "@mui/material/Typography";
import { AutoGrid } from "../../../Globals";
import { DeleteSVG } from "../../../Globals/Views/IconSVGs/DeleteSVG";
import { ButtonsContainer, ModalBase, ModalHeader } from "./ModalComponents";

interface IProps {
    isActive: boolean;
    confirmClose(confirm: boolean): void;
    onCancel(): void;
}

export const NewCustomerModal: React.FC<IProps> = (props) => {
    const [viewModel] = useState(() => new NewCustomerViewModel());
    const [errorMessage, setErrorMessage] = useState("");
    useEffect(() => {}, []);

    return useObserver(() => (
        <>
            <ModalBase open={props.isActive} onClose={() => props.onCancel()}>
                <div>
                    <ModalHeader color={theme.palette.healthAppyBlue.main} title={"Add customer"} />
                    <div className="modalclosebtn" onClick={() => props.onCancel()}>
                        <DeleteSVG className="file-delete" />
                    </div>
                    <Box className="modalbody">
                        <Box className="content" style={{ width: " 100%" }}>
                            {errorMessage !== "" && (
                                <Typography variant="caption" style={{ color: ` ${theme.palette.warning.main}` }}>
                                    {errorMessage}
                                </Typography>
                            )}
                            <AutoGrid columnGap={"30px"} dc={"1fr"}>
                                <EditText
                                    displayName="Customer name"
                                    command={viewModel.updateNameCommand}
                                    value={() => viewModel.getValue("name")}
                                    validationMessage={() => viewModel.getError("name")}
                                    type="text"
                                />
                            </AutoGrid>
                            <AutoGrid columnGap={"30px"} dc={"1fr"}>
                                <EditText
                                    displayName="Contact"
                                    command={viewModel.updateContactNameCommand}
                                    value={() => viewModel.getValue("contactName")}
                                    validationMessage={() => viewModel.getError("contactName")}
                                    type="text"
                                />
                            </AutoGrid>
                            <AutoGrid columnGap={"30px"} dc={"1fr 1fr"}>
                                <EditText
                                    displayName="Contact email"
                                    command={viewModel.updateContactEmailCommand}
                                    value={() => viewModel.getValue("contactEmail")}
                                    validationMessage={() => viewModel.getError("contactEmail")}
                                    type="email"
                                />
                                <EditText
                                    displayName="Contact number"
                                    command={viewModel.updateContactNumberCommand}
                                    value={() => viewModel.getValue("contactNumber")}
                                    validationMessage={() => viewModel.getError("contactNumber")}
                                    type="number"
                                />
                            </AutoGrid>
                        </Box>
                        <ButtonsContainer color={theme.palette.healthAppyBlue.main}>
                            <Button
                                disableElevation
                                variant="contained"
                                onClick={async () => {
                                    let close = await viewModel.createNewCustomer();
                                    if (close == true) {
                                        props.confirmClose(true);
                                    }
                                }}
                                style={{ backgroundColor: `${theme.palette.healthAppyBlue.main}` }}
                            >
                                Create
                            </Button>

                            <Button disableElevation variant="contained" onClick={() => props.onCancel()}>
                                Cancel
                            </Button>
                        </ButtonsContainer>
                    </Box>
                </div>
            </ModalBase>
        </>
    ));
};

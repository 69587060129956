import { Box, Button, Link, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import { EditableCopyViewModel } from "Globals/ViewModels/EditableCopyViewModel";
import { LoginViewModel } from "./LoginViewModel";
import { Link as RouterLink } from "react-router-dom";
import { observer, useObserver } from "mobx-react-lite";
import { container } from "tsyringe";
import { useRouter, EditText, TypographyX, AutoGrid, Show } from "../../Globals";

//custom
import Logo from "Content/assets/logoPortrait.svg";
import { HHButton } from "Globals/Components/General/Buttons/HHButton";
import { ForgotPasswordLinkText, LoginContainer, LoginForm, LoginInput } from "./LoginView.style";

export const LoginView: React.FunctionComponent = observer(() => {
    const [viewModel] = useState(() => new LoginViewModel());
    const { history, location, match } = useRouter();

    useEffect(() => {
        if (viewModel.isLoggedIn) {
            history.push("/");
        }
    }, []);

    const doSubmit = async (e: any) => {
        e.preventDefault();
        await viewModel.loginCommand.execute();
    };

    return (
        <>
            <LoginContainer>
                <Box style={{ marginBottom: "34px" }}>
                    <img src={Logo} alt="Healthappy logo" />
                </Box>
                <Box style={{ marginBottom: "29px" }}>
                    <Typography variant="h5">Application administration</Typography>
                </Box>
                <LoginForm onSubmit={doSubmit}>
                    {viewModel.apiCallErrorMessage !== "" && (
                        <Typography variant="caption" style={{ color: "red" }}>
                            {viewModel.apiCallErrorMessage}
                        </Typography>
                    )}

                    <LoginInput
                        id={"emailAddress"}
                        displayName={"Email Address"}
                        value={() => viewModel.getValue("emailAddress")}
                        command={viewModel.updateEmailCommand}
                        validationMessage={() => viewModel.getError("emailAddress")}
                        placeholder="Enter your email here"
                        style={{ marginBottom: "15px" }}
                    />
                    <Box style={{ width: "100%", marginBottom: "30px" }}>
                        <LoginInput
                            displayName={"Password"}
                            type={"password"}
                            value={() => viewModel.getValue("password")}
                            command={viewModel.updatePasswordCommand}
                            validationMessage={() => viewModel.getError("password")}
                            style={{ width: "100%", marginBottom: "15px" }}
                            placeholder="Enter your password here"
                        />
                        <Link component={RouterLink} to="forgotpassword">
                            <ForgotPasswordLinkText>Forgot password?</ForgotPasswordLinkText>
                        </Link>
                    </Box>

                    <HHButton text={viewModel.IsLoading ? <>Please wait...</> : <>Login</>} type="submit" width="274px" />
                </LoginForm>
            </LoginContainer>
        </>
    );
});

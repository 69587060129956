import { Box, Button, Fade, Link, Paper, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import { Centered } from "Globals/Components/Centered";

import { ResetPasswordModel } from "./ResetPasswordModel";
import { ResetPasswordViewModel } from "./ResetPasswordViewModel";
import { runInAction } from "mobx";
import { useObserver } from "mobx-react-lite";
import { EditText } from "Globals/Views/Primitives/EditText";
import { useViewModel } from "Globals/Hooks/UseViewModel";
import Logo from "../../Content/assets/logoPortrait.svg";
import { LoginContainer } from "../Login/LoginView.style";
import { HHButton } from "../../Globals/Components/General/Buttons/HHButton";

export const ResetPasswordView: React.FunctionComponent = () => {
    const [viewModel] = useState(() => new ResetPasswordViewModel());

    useEffect(() => {
        viewModel.getTokenFromUrl();
    }, []);

    const doSubmit = async (e: any) => {
        e.preventDefault();
        await viewModel.resetPasswordCommand.execute();
    };

    const goToForgotPassword = async (e: any) => {
        viewModel.history.push("/forgotpassword");
    };

    const goToLogin = async (e: any) => {
        viewModel.history.push("/login");
    };

    return useObserver(() => (
        <LoginContainer>
            <Box style={{ marginBottom: "34px" }}>
                <img src={Logo} alt="Healthappy logo" />
            </Box>
            <Box>
                {viewModel.tokenValid === true ? (
                    <>
                        <Box justifyContent="center" textAlign="center" display="grid" minWidth="300px" maxWidth="300px">
                            <form onSubmit={doSubmit}>
                                {!viewModel.isSuccess && (
                                    <>
                                        {" "}
                                        <Typography variant="body1" gutterBottom>
                                            Please type a new password.
                                        </Typography>
                                        {viewModel.errorMessage !== "" && (
                                            <Typography variant="caption" style={{ color: "red" }}>
                                                {viewModel.errorMessage}
                                            </Typography>
                                        )}
                                        <EditText
                                            type={"password"}
                                            displayName="New password"
                                            value={() => viewModel.getValue("newPassword")}
                                            command={viewModel.updateNewPasswordCommand}
                                            validationMessage={() => viewModel.getError("newPassword")}
                                        />
                                        <EditText
                                            type={"password"}
                                            displayName="Confirm password"
                                            value={() => viewModel.getValue("confirmPassword")}
                                            command={viewModel.updateConfirmPasswordCommand}
                                            validationMessage={() => viewModel.getError("confirmPassword")}
                                        />
                                    </>
                                )}
                                <Box mt={3}>
                                    {!viewModel.isSuccess && <HHButton text={viewModel.IsLoading ? <>Please wait...</> : <>Reset password</>} type="submit" width="274px" />}
                                    {/* <HHButton type="submit" fullWidth variant="contained" color="primary">
                                {viewModel.IsLoading ? "Please Wait..." : "Reset password"}
                            </HHButton>*/}
                                    {viewModel.errorMessage !== "" && (
                                        <Link style={{ cursor: "pointer" }} onClick={goToForgotPassword}>
                                            Return to forgot password
                                        </Link>
                                    )}
                                    {viewModel.isSuccess && (
                                        <>
                                            <Typography variant="h6" color="primary">
                                                Successfully Reset Password
                                            </Typography>
                                            <Link style={{ cursor: "pointer" }} onClick={goToLogin}>
                                                Return to login
                                            </Link>
                                        </>
                                    )}
                                </Box>
                            </form>
                        </Box>
                    </>
                ) : viewModel.tokenValid === false ? (
                    <>
                        <Box justifyContent="center" textAlign="center" display="grid" minWidth="300px" maxWidth="300px">
                            <Typography variant="body1" gutterBottom>
                                Url is expired or invalid, please try again.
                            </Typography>
                            <Link style={{ cursor: "pointer" }} onClick={goToForgotPassword}>
                                Return to forgot password
                            </Link>
                        </Box>
                    </>
                ) : (
                    <>
                        <Box justifyContent="center" textAlign="center" display="grid" minWidth="300px" maxWidth="300px">
                            <Typography component="h1" variant="h3" style={{ marginBottom: "25px", marginTop: "25px" }}>
                                Reset password
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Loading...
                            </Typography>
                        </Box>
                    </>
                )}
            </Box>
        </LoginContainer>
    ));
};

import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { ApiResult, FieldType, HttpClient, ViewModelBase } from "@shoothill/core";
import { container } from "tsyringe";

// App
import { NewFAQModel, NewFAQValidator } from "../../../Globals/Models/Domain/Admin/NewFAQModel";
import { NewFAQ } from "../../../Globals/Models/NewFAQ";
import { AppUrls } from "../../../AppUrls";
import { DomainStores } from "../../../Globals/Stores";
import { RelayCommandAsync, ICommandAsync } from "../../../Globals/Commands";
import { IKeyState } from "../../../Globals";
export default class NewFAQViewModel extends ViewModelBase<NewFAQModel> {
    private httpClient = container.resolve(HttpClient);
    private domainStores = container.resolve(DomainStores);

    constructor() {
        super(new NewFAQModel());
        this.setValidator(new NewFAQValidator());
    }

    public updateQuestionCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("question", value, keyState);
    });

    public updateAnswerCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("answer", value, keyState);
    });

    private async updateField(fieldName: keyof FieldType<NewFAQModel>, value: any, keyState?: IKeyState) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    public createNewFAQ = async (): Promise<boolean> => {
        this.setIsLoading(true);
        let isMyModelValid: boolean = await this.isModelValid();
        if (isMyModelValid) {
            let modelToSave = this.getModel;
            let newFAQ: NewFAQ = {
                question: modelToSave.question,
                answer: modelToSave.answer,
            };

            const apiResult = await this.httpClient.Post<NewFAQ>(AppUrls.Server.Admin.CreateNewFAQ, newFAQ);

            if (apiResult.wasSuccessful) {
                this.history.push("/admin/faqs");
                return true;
            } else {
                apiResult.errors.forEach((error) => {
                    this.domainStores.OpenSnackBar(`Error: ${error.message}`, "error");
                });
                return false;
            }
        }

        return false;
    };
}

import { AppRoutes } from "./AppRoutes";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider as MuiThemeProvider, Theme, StyledEngineProvider } from "@mui/material/styles";
import React from "react";
import { Router } from "react-router-dom";
import StylesProvider from "@mui/styles/StylesProvider";
import { theme } from "Globals/Styles/AppTheme";
import { GlobalHistory } from "index";
import { jsx, ThemeProvider } from "@emotion/react";

declare module "@mui/styles/defaultTheme" {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}

export const App: React.FC = () => {
    return (
        <StylesProvider injectFirst>
            <StyledEngineProvider injectFirst>
                <MuiThemeProvider theme={theme}>
                    <ThemeProvider theme={theme}>
                        <CssBaseline>
                            <Router history={GlobalHistory}>
                                <AppRoutes />
                            </Router>
                        </CssBaseline>
                    </ThemeProvider>
                </MuiThemeProvider>
            </StyledEngineProvider>
        </StylesProvider>
    );
};

import React, { useEffect, useState } from "react";
import MaterialTable, { MTableBody, MTableToolbar } from "@material-table/core";
import * as Mui from "@mui/material";
import { useObserver } from "mobx-react-lite";
import UserViewModel from "Views/Admin/Users/UserViewModel";
import styled from "@emotion/styled";
import { theme } from "../../../Globals/Styles/AppTheme";
import { useRouter } from "../../../Globals/Hooks/UseRouter";

export const Container = styled(Mui.Box)`
    .MuiTableHead-root {
        background-color: rgba(230, 230, 230, 0.8);
        .MuiTableCell-root {
            font-size: 1rem;
            height: 70px;
        }
    }
    .MuiTableCell-root {
        font-size: 0.8rem;
    }
    .MuiTableSortLabel-root {
        :hover {
            color: ${theme.palette.secondary.main};
        }
    }
    .MuiTableRow-root {
        cursor: pointer;

        &:not(:first-of-type),
        &:not(:last-of-type) {
            :hover {
                background-color: rgba(230, 230, 230, 0.3);
            }
        }
    }
`;

export const UserListContainer: React.FC = () => {
    const [viewModel] = useState(() => new UserViewModel());
    const [loading, setLoading] = useState(false);
    const { history } = useRouter();

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            const apiResult = await viewModel.loadUsersAsync();
            if (apiResult.wasSuccessful) {
                console.log(apiResult);
            }
            setLoading(false);
        };
        let _ = fetchData();
    }, []);

    const handleRowClick = (e: any, rowData: any) => {
        history.push(`/userslist/user/${rowData.id}`);
    };

    const handleNewClick = () => {
        history.push(`/userslist/user/0`);
    };

    const handleRowDelete = (event: MouseEvent, rowData: any) => {
        // console.log(viewModel.getUsers);
        viewModel.setValue("isDeleted", true);
        // setTimeout(() => {
        //     console.log(viewModel.getUsers);
        // }, 1000);
        console.log(rowData["isDeleted"]);
        console.log(viewModel.getUser(rowData.id));
    };

    return useObserver(() => (
        <Container>
            <Mui.Box id="tablecontainer" p={3}>
                <Mui.Box display="flex" justifyContent="space-between" alignItems="center" margin="30px">
                    <Mui.Box justifyContent="flex-start" alignItems="center">
                        <Mui.Typography variant="h4" color="textPrimary">
                            Users
                        </Mui.Typography>
                    </Mui.Box>
                    <Mui.Box>
                        <Mui.Button variant="contained" color="secondary" onClick={() => handleNewClick()}>
                            <Mui.Typography variant="button">New User</Mui.Typography>
                        </Mui.Button>
                    </Mui.Box>
                </Mui.Box>
                <MaterialTable
                    actions={[
                        {
                            icon: "delete_outline",
                            onClick: (_, rowData: any) => {
                                setLoading(true);
                                viewModel.setDeleted(rowData.id);
                                setLoading(false);
                            },
                            iconProps: { style: { fontSize: "20px" } },
                            tooltip: "Remove User",
                        },
                    ]}
                    style={{
                        width: "99%",
                        boxShadow: "0 14px 28px rgba(0,0,0,0.15), 0 10px 10px rgba(0,0,0,0.12) ",
                        borderRadius: "15px",
                        margin: "20px auto 50px",
                        border: "none",
                    }}
                    columns={[
                        { title: "First name", field: "firstName" },
                        { title: "Surname", field: "lastName" },
                        { title: "Email", field: "emailAddress" },
                        { title: "Access Level", field: "userType" },
                    ]}
                    options={{
                        pageSize: 50,
                        pageSizeOptions: [10, 20, 50, 100],
                        emptyRowsWhenPaging: false,
                        actionsColumnIndex: -1,
                        actionsCellStyle: { padding: "0 30px" },
                    }}
                    //data={viewModel.getUsers.map((item) => Object.assign({}, item))}
                    data={viewModel.getUsers}
                    onRowClick={handleRowClick}
                    title=""
                    isLoading={loading}
                    components={{
                        Toolbar: (props) => (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "right",
                                }}
                            >
                                <MTableToolbar {...props} />
                            </div>
                        ),
                    }}
                />
            </Mui.Box>
        </Container>
    ));
};

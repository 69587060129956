import dotenv from "dotenv";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "index.scss";
import * as serviceWorker from "serviceWorker";
import "reflect-metadata";
import { App } from "./App";
import { CoreStoreInstance, getBaseUrl } from "@shoothill/core";
import { AppErrorBoundary } from "AppErrorBoundary";
import * as MobX from "mobx";
import * as History from "history";
import { useObserver } from "mobx-react-lite";
import loading from "Content/loading.json";
import Lottie from "lottie-react-web";
import { Loader } from "./Globals/Components/Loader";
import { Startup } from "./Startup";
import { LogLevel } from "@shoothill/core";

dotenv.config();

MobX.configure({ enforceActions: "observed" });

const baseUrl: string = getBaseUrl();
export const GlobalHistory: History.History = History.createBrowserHistory({
    basename: baseUrl,
});
//(window as any).GlobalHistory = GlobalHistory;

GlobalHistory.listen((location: any) => {
    if (CoreStoreInstance) {
        CoreStoreInstance.PageView(location.pathname);
    }

    // Use setTimeout to make sure this runs after React Router's own listener
    setTimeout(() => {
        // Keep default behavior of restoring scroll position when user:
        // - clicked back button
        // - clicked on a link that programmatically calls `history.goBack()`
        // - manually changed the URL in the address bar (here we might want
        // to scroll to top, but we can't differentiate it from the others)
        if (location.action === "POP") {
            return;
        }
        // In all other cases, check fragment/scroll to top
        let hash = window.location.hash;
        if (hash) {
            let element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ block: "start", behavior: "smooth" });
            }
        } else {
            window.scrollTo(0, 0);
        }
    });
});

export const IsDevMode: boolean = process.env.NODE_ENV === "development";
window.IsDev = IsDevMode;
window.LogLevel = LogLevel.Information;
window.CoreOptions = {
    UseSessionStorage: false,
};

const Init: React.FC = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        //let _ = viewModel.getInitialState();
        const startup = new Startup();
        const doAsync = async () => {
            await startup.init();
            setIsLoading(false);
        };
        const _ = doAsync();
        //container.register<HttpClient>(HttpClient, { useClass: HttpClient }, { lifecycle: Lifecycle.ContainerScoped });
    }, []);

    const getRender = () => {
        if (isLoading) {
            // EN: Delayed in code behind so we can see the loading text
            return <Loader delayBeforeShow={0} component={<Lottie options={{ animationData: loading }} height={100} />} />;
        } else {
            return (
                <AppErrorBoundary>
                    <App />
                </AppErrorBoundary>
            );
        }
    };
    return useObserver(() => {
        return getRender();
    });
};

ReactDOM.render(<Init />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

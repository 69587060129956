// Libraries
import * as MobX from "mobx";

// Core
import { FieldType, Validation, ViewModelBase } from "@shoothill/core";
import { container } from "tsyringe";
import { DomainStores } from "../Stores";

export class EditableCopyViewModel extends ViewModelBase {
    private domainStores = container.resolve(DomainStores);

    constructor() {
        super(null);
    }
    public getEditableCopyByRouteAndPlaceholderNumber = (route: string, placeholderNumber: number) => {
        let editableCopyRoute = this.domainStores.editableCopyRoutes.find((r) => r.route === route);

        if (editableCopyRoute !== undefined) {
            let editableCopyRouteId: string = editableCopyRoute.id;
            return this.domainStores.editableCopies.find((c) => c.routeId === editableCopyRouteId && c.placeholderNumber === placeholderNumber)?.copyValue as string;
        }

        return "";
    };

    /*public isModelValid(): boolean {
        return true;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<EditableCopyViewModel>): Validation {
        return [true, ""];
    }*/

    // #endregion Viewmodel Boilerplate
}

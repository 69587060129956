import React, { CSSProperties, MouseEventHandler } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Spinner } from "../../../Styles";
import { theme } from "../../../../Styles";

export interface MainButtonProps {
    /**
     * Show please wait and a spinner
     */
    isLoading?: boolean;
    className?: string;
    style?: CSSProperties;
    buttonText: string;
    showChevron?: boolean;
    onClick?: () => void;
    color?: "primary" | "secondary" | string;
    showDivider?: boolean;
}
export const MainSubmitButton: React.FC<MainButtonProps> = (props: MainButtonProps) => {
    let mainColor: string = props.color as string;
    if (mainColor === "primary" || mainColor === "secondary") {
        mainColor = theme.palette[mainColor].main as string;
    }

    return (
        <>
            {props.showDivider && <Box marginBottom={"50px"} marginTop={"50px"} borderTop={`1px solid ${mainColor}`} />}
            <Box textAlign={"center"} marginBottom={"50px"}>
                <Button
                    style={{ height: "50px", backgroundColor: mainColor, ...props.style }}
                    className={props.className}
                    disabled={props.isLoading}
                    onClick={props.onClick}
                    /*color={"primary"}*/
                    //type="submit"
                    variant={"contained"}
                >
                    <Typography component={"div"} style={{ color: "white" }}>
                        {props.isLoading ? (
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}
                            >
                                <Spinner />
                                <Typography component={"div"}>Please wait...</Typography>
                            </Box>
                        ) : (
                            <>
                                {props.buttonText} {props.showChevron && <span>&#187;</span>}
                            </>
                        )}
                    </Typography>
                </Button>
            </Box>
        </>
    );
};
MainSubmitButton.defaultProps = {
    isLoading: false,
    color: "primary",
};

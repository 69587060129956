import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { ProfileQuestionAnswersViewModel } from "./ProfileQuestionAnswersViewModel";

export class ProfileQuestionsModel extends ModelBase<ProfileQuestionsModel> {
    public id: string = "";
    public questionText: string = "";
    public mainImageUrl: string = "";
    public canSelectMultiple: boolean = false;
    public isDeleted: boolean = false;
    public isVisibleInApp: boolean = false;
    public orderPosition: number = 0;
    public totalScore: number = 0;
    public profileQuestionAnswers: ProfileQuestionAnswersViewModel[] = [];

    constructor(init?: ProfileQuestionsModel) {
        super();
        if (init) {
            this.toModel(init);
            this.profileQuestionAnswers = [];
            init.profileQuestionAnswers.forEach((item, index) => {
                let pqavm = new ProfileQuestionAnswersViewModel(item);
                this.profileQuestionAnswers.push(pqavm);
            });
        }
        makeObservable(this, {});
    }
}

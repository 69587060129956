import React, { useContext } from "react";
import { UserDetails } from "./Users/UserDetails";
import { RoleDetails } from "./Roles/RoleDetails";
import { UserModel } from "Globals/Models";
import { Admin } from "./Admin";
import { PrivateRoute } from "Globals/Views/PrivateRoute";
import { container } from "tsyringe";
import { AccountStore } from "../../Globals/Stores/Domain";
import { AdminAddContentView } from "./Content/AdminAddContentView";
import { AdminContentListView } from "./Content/AdminContentListView";
import { AdminCustomersListView } from "./Customers/AdminCustomersListView";
import { AdminCustomerUsersListView } from "./Customers/AdminCustomerUsersListView";
import { AdminFAQsListView } from "./FAQ/AdminFAQsListView";
import { AdminProfileView } from "./Profiles/AdminProfileView";
import { AdminCustomerView } from "./Customers/AdminCustomerView";
import { AdminAddNewProfileQuestion } from "./Profiles/AdminAddNewProfileQuestionView";
import { DefaultLayoutNoFooter } from "Globals/Views/Layouts/DefaultLayoutNoFooter";

export const AdminRoutes: React.FC = () => {
    const accountStore = container.resolve(AccountStore);

    return (
        <>
            <PrivateRoute
                exact
                path="/user"
                component={UserModel}
                isAllowed={() => {
                    return accountStore.IsLoggedIn && accountStore.isInRole("admin");
                }}
            />
            <PrivateRoute
                exact
                path="/admin"
                component={Admin}
                isAllowed={() => {
                    return accountStore.IsLoggedIn && accountStore.isInRole("admin");
                }}
            />
            <PrivateRoute
                exact
                path="/roleslist/role/:id"
                component={RoleDetails}
                isAllowed={() => {
                    return accountStore.IsLoggedIn && accountStore.isInRole("admin");
                }}
            />
            <PrivateRoute
                exact
                path="/userslist/user/:id"
                component={UserDetails}
                isAllowed={() => {
                    return accountStore.IsLoggedIn && accountStore.isInRole("admin");
                }}
            />
            <PrivateRoute
                exact
                path="/admin/customers"
                component={AdminCustomersListView}
                layout={DefaultLayoutNoFooter}
                isAllowed={() => {
                    return accountStore.IsLoggedIn && accountStore.isInRole("admin");
                }}
            />
            <PrivateRoute
                exact
                path="/admin/customers/customer/customerusers/:customerId?"
                component={AdminCustomerView}
                layout={DefaultLayoutNoFooter}
                isAllowed={() => {
                    return accountStore.IsLoggedIn && accountStore.isInRole("admin");
                }}
            />
            {/* <PrivateRoute
                exact
                path="/admin/customers/customer/customerusers/:customerId?"
                component={AdminCustomerUsersListView}
                layout={DefaultLayoutNoFooter}
                isAllowed={() => {
                    return accountStore.IsLoggedIn && accountStore.isInRole("admin");
                }}
            /> */}
            <PrivateRoute
                exact
                path="/admin/content"
                component={AdminContentListView}
                layout={DefaultLayoutNoFooter}
                isAllowed={() => {
                    return accountStore.IsLoggedIn && (accountStore.isInRole("admin") || accountStore.isInRole("contentprovider"));
                }}
            />
            <PrivateRoute
                exact
                path="/admin/content/addcontent/:contentId?"
                component={AdminAddContentView}
                layout={DefaultLayoutNoFooter}
                isAllowed={() => {
                    return accountStore.IsLoggedIn && (accountStore.isInRole("admin") || accountStore.isInRole("contentprovider"));
                }}
            />

            <PrivateRoute
                exact
                path="/admin/profiles"
                component={AdminProfileView}
                layout={DefaultLayoutNoFooter}
                isAllowed={() => {
                    return accountStore.IsLoggedIn && accountStore.isInRole("admin");
                }}
            />
            <PrivateRoute
                exact
                path="/admin/profiles/addprofile/:questionId?"
                component={AdminAddNewProfileQuestion}
                layout={DefaultLayoutNoFooter}
                isAllowed={() => {
                    return accountStore.IsLoggedIn && accountStore.isInRole("admin");
                }}
            />
            <PrivateRoute
                exact
                path="/admin/faqs"
                component={AdminFAQsListView}
                layout={DefaultLayoutNoFooter}
                isAllowed={() => {
                    return accountStore.IsLoggedIn && accountStore.isInRole("admin");
                }}
            />
        </>
    );
};

import React from "react";

export const SearchIcon: React.FC = () => {
    return (
        <span style={{ cursor: "pointer" }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16.001" height="16" viewBox="0 0 16.001 16">
                <path
                    id="magnifying-glass"
                    d="M19,16.9l-3.827-3.827a6.227,6.227,0,0,0,.91-3.251A6.6,6.6,0,0,0,9.625,3.362,6.263,6.263,0,0,0,3.36,9.627a6.6,6.6,0,0,0,6.458,6.458,6.228,6.228,0,0,0,3.155-.854l3.847,3.85a.965.965,0,0,0,1.363,0l.955-.955c.376-.376.239-.851-.137-1.227ZM5.288,9.626A4.336,4.336,0,0,1,9.624,5.289a4.673,4.673,0,0,1,4.531,4.531,4.338,4.338,0,0,1-4.338,4.338,4.675,4.675,0,0,1-4.53-4.531Z"
                    transform="translate(-3.36 -3.362)"
                    fill="#0095da"
                />
            </svg>
        </span>
    );
};

// Libs
import { ModelBase } from "@shoothill/core";
import { Validator } from "../../../../Globals/Validation";
import { makeObservable, observable } from "mobx";

export class NewCustomerModel extends ModelBase<NewCustomerModel> {
    public name: string = "";
    public contactName: string = "";
    public contactEmail: string = "";
    public contactNumber: string = "";

    constructor() {
        super();
        makeObservable(this, {
            name: observable,
            contactName: observable,
            contactEmail: observable,
            contactNumber: observable,
        });
    }
}
export class NewCustomerValidator extends Validator<NewCustomerModel> {
    constructor() {
        super();

        this.ruleFor("name").notEmpty();
        this.ruleFor("contactName").notEmpty();
        this.ruleFor("contactEmail").notEmpty().emailAddress();
        this.ruleFor("contactNumber").notEmpty();
    }
}

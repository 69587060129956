import styled from "@emotion/styled";
import { Box, Link } from "@mui/material";

import { LeftArrow } from "../../../Content/assets/LeftArrow";
import { theme } from "../../../Globals/Styles/AppTheme";

interface IBackToLinkProps {
    onClick: any;
    text: string;
}

export const BackToLink = (props: IBackToLinkProps) => {
    const { onClick, text } = props;

    const LinkText = styled(Box)`
        color: ${theme.palette.healthAppyBlue.main};
        margin-left: 5px;
        text-decoration: underline;
        font-size: 12px;
        //text-transform: capitalize;
        font-family: "Manrope Bold", sans-serif;
        font-weight: bold;
    `;

    return (
        <Link onClick={onClick} underline="none" style={{ cursor: "pointer" }}>
            <Box style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
                <LeftArrow />
                <LinkText>{text}</LinkText>
            </Box>
        </Link>
    );
};

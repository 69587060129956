// Libraries
import { action, makeObservable, observable, runInAction } from "mobx";
import { container } from "tsyringe";

// Core
import { HttpClient, ViewModelBase, PostFormWithFile } from "@shoothill/core/";

import { AppUrls } from "../../AppUrls";

// Models
import { UserFile } from "../../Globals/Models/UserFile";
import { createRef } from "react";

export class FileUploaderViewModel extends ViewModelBase {
    private httpClient = container.resolve(HttpClient);
    public filesToDisplay: UserFile[] = [];
    public errorMessage: string = "";
    public isLoading: boolean = false;
    public imageRef: any = {} as any;

    constructor(files: UserFile[]) {
        super(null);
        makeObservable(this, {
            filesToDisplay: observable,
            errorMessage: observable,
            isLoading: observable,
            upload: action,
            download: action,
            delete: action,
        });
        this.filesToDisplay = files;
    }

    public checkImageDimensions = () => {
        if (this.imageRef.current.naturalHeight >= 189 || this.imageRef.current.naturalWidth >= 306) {
            this.errorMessage = "Try keeping the image width bellow 305 pixels and the height bellow 188 pixels.";
        }
    };

    public upload = async (e: React.ChangeEvent<HTMLInputElement>): Promise<UserFile | null> => {
        let files: FileList | null = e.target.files;
        this.isLoading = true;
        if (files !== null && files !== undefined && files.length > 0) {
            if (files.length > 1) {
                this.errorMessage = "Too many files!";
                throw new Error("Too many files!");
            }
            const file = files[0];

            try {
                const formData = new FormData();
                formData.append("formFile", file);
                formData.append("fileName", file.name);

                const apiResult = await this.httpClient.Post<string>(AppUrls.Server.File.UploadFile, formData);
                if (apiResult.wasSuccessful) {
                    if (apiResult.errors.length > 0) {
                        return null;
                    }
                    let userFile: UserFile = {
                        fileName: file.name,
                        fileUrl: apiResult.payload,
                    };
                    runInAction(() => {
                        this.errorMessage = "";
                        this.filesToDisplay.push(userFile);
                    });
                    this.isLoading = false;
                    return userFile;
                } else {
                    files = null;
                    if (apiResult.wasSuccessful) {
                        runInAction(() => {
                            this.errorMessage = "Error uploading file please try again.";
                        });
                        this.setIsErrored(true);
                        this.IsLoading = false;
                    }
                }
            } catch (exception) {
                console.error(exception);
                runInAction(() => {
                    this.errorMessage = "Error uploading file please try again.";
                });
                this.setIsErrored(true);
                this.IsLoading = false;
            }
        }
        return null;
    };

    public download = async (fileUrl: string): Promise<void> => {
        let fileName = fileUrl.substr(fileUrl.lastIndexOf("/") + 1);
        try {
            const apiResult = await this.httpClient.Post<Blob>(AppUrls.Server.File.DownloadFile, fileUrl, undefined, { responseType: "blob" });
            const response = apiResult as any;
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
        } catch (exception) {
            console.error(exception);
            this.setIsErrored(true);
        }
    };

    public delete = async (userFile: UserFile): Promise<void> => {
        try {
            runInAction(() => {
                const index = this.filesToDisplay.indexOf(userFile);

                if (index > -1) {
                    this.filesToDisplay.splice(index, 1);
                }
            });
        } catch (e) {
            console.log(e);
        }
    };

    public showEditFile = async (userFile: UserFile[]): Promise<UserFile[] | []> => {
        runInAction(() => {
            this.errorMessage = "";
            this.filesToDisplay = userFile;
        });
        return userFile;
    };

    public resetErrorMsg = () => {
        this.errorMessage = "";
    };
}

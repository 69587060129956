import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { ApiResult, FieldType, HttpClient, KeyValuePair, ViewModelBase } from "@shoothill/core";
import { container } from "tsyringe";
import { AppUrls } from "AppUrls";
// App
import { NewContentModel, NewContentValidator } from "../../../Globals/Models/Domain/Admin/NewContentModel";
import { Category } from "../../../Globals/Models/Category";
import { Customer } from "../../../Globals/Models/Customer";
import { ContentAndRelated } from "../../../Globals/Models/DTO/ContentAndRelated";
// import { AppUrls } from "../../../AppUrls";
import { DomainStores } from "../../../Globals/Stores";
import { RelayCommandAsync, ICommandAsync } from "../../../Globals/Commands";
import { IKeyState } from "../../../Globals";
import { color } from "@mui/system";
import { UserFile } from "Globals/Models/UserFile";
import { CategoryColours } from "Globals/Models/CategoryColours";
import { copyFile } from "fs";
import { indexOf } from "lodash-es";
import { ContextType } from "react";
import { typographyClasses } from "@mui/material";
import { ContentType } from "Globals/Models/ContentType";

export default class NewContentViewModel extends ViewModelBase<NewContentModel> {
    private httpClient = container.resolve(HttpClient);
    private domainStores = container.resolve(DomainStores);

    constructor() {
        super(new NewContentModel());
        this.setValidator(new NewContentValidator());
        (window as any).ncvm = this;
    }

    public async getContent(contentId: string | null): Promise<void> {
        if (contentId === null) return;
        this.setIsLoading(true);
        const apiResult = await this.httpClient.Get<ContentAndRelated>(AppUrls.Server.Admin.GetContentAndRelated + "/?id=" + contentId);
        if (!apiResult.wasSuccessful) return;
        this.model.reset();
        this.model.toModel(apiResult.payload.content);
        this.updateField("bonusPoints", apiResult?.payload?.content?.bonusPoints.toString());
        this.updateField("points", apiResult?.payload?.content?.points.toString());
        // Handles what toModel() doesn't.
        this.model.fromDto(apiResult.payload);
        this.setIsLoading(false);
    }

    private async updateField(fieldName: keyof FieldType<NewContentModel>, value: any, keyState?: IKeyState) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    public updateTitleCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("title", value, keyState);
    });

    public updateSubtitleCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("subTitle", value, keyState);
    });

    public updateCategoryCommand: ICommandAsync = new RelayCommandAsync(async (value: KeyValuePair) => {
        await this.updateField("categoryId", value.value);
    });

    public updateTypeCommand: ICommandAsync = new RelayCommandAsync(async (value: KeyValuePair) => {
        await this.updateField("contentTypeId", value.value);
    });

    public updateHasBonusPointsCommand: ICommandAsync = new RelayCommandAsync(async (keyValuePair: KeyValuePair) => {
        await this.updateField("isCalendarBonus", keyValuePair.value);
    });

    public updateBonusPointsCommand: ICommandAsync = new RelayCommandAsync(async (keyValuePair: KeyValuePair) => {
        await this.updateField("bonusPoints", keyValuePair.value);
    });

    public updatePointsCommand: ICommandAsync = new RelayCommandAsync(async (keyValuePair: KeyValuePair) => {
        await this.updateField("points", keyValuePair.value);
    });
    public updateIsVisibleInApp: ICommandAsync = new RelayCommandAsync(async (value: boolean) => {
        await this.updateField("isVisibleInApp", value);
    });
    public updateOccurrenceTypeCommand: ICommandAsync = new RelayCommandAsync(async (keyValuePair: KeyValuePair) => {
        await this.updateField("occurrenceType", keyValuePair.value);
    });
    public updateStartDateCommand: ICommandAsync = new RelayCommandAsync(async (value: Date) => {
        await this.updateField("startDate", value);
    });

    public updateOccurrenceByDayCommand: ICommandAsync = new RelayCommandAsync(async (keyValuePair: KeyValuePair) => {
        await this.updateField("occurrenceDay", keyValuePair.value);
        if (keyValuePair.value === "user") {
            this.setValue("startDate", null);
            this.setValue("occurrenceDay", "");
        }
    });

    public updateMultiCustomersCommand: ICommandAsync = new RelayCommandAsync(async (value: string[]) => {
        this.updateField("customers", value);
    });

    public updateLocationCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("address", value, keyState);
    });

    public deleteOption = (option: KeyValuePair) => {
        let newCompanies = this.getValue<any[]>("customers").filter((companyValue) => companyValue !== option.value);
        this.setValue("customers", newCompanies);
    };

    public get getCategoriesSortedByOrdinal(): Category[] {
        return this.domainStores.categories.slice().sort((a, b) => (a.ordinal < b.ordinal ? -1 : 1));
    }

    public get getCategoryOptions(): KeyValuePair[] {
        return this.getCategoriesSortedByOrdinal.map((keyValuePair) => ({ value: keyValuePair.id, label: keyValuePair.name })) as KeyValuePair[];
    }
    public get getCustomersSortedByOrdinal(): Customer[] {
        return this.domainStores.customers.slice().sort((a, b) => (a.name < b.name ? -1 : 1));
    }

    public get getOccurrenceOptions(): KeyValuePair[] {
        const placeholder = [
            {
                value: "day",
                label: "Day",
            },
            {
                value: "date",
                label: "Date",
            },
            {
                value: "user",
                label: "Chosen by user",
            },
        ];
        return placeholder.map((keyValuePair) => ({ value: keyValuePair.value, label: keyValuePair.label })) as KeyValuePair[];
    }

    public get getSelectedOccurrenceOption(): KeyValuePair {
        const occurrence = this.getOccurrenceOptions.find((o) => o.value === this.getValue("occurrenceType"));

        if (occurrence) {
            return {
                value: occurrence.value,
                label: occurrence.label,
            } as KeyValuePair;
        }

        return { label: "", value: null } as KeyValuePair;
    }

    public get getOccurrenceByDayOptions(): KeyValuePair[] {
        const placeholder = [
            {
                value: "1",
                label: "Monday",
            },
            {
                value: "2",
                label: "Tuesday",
            },
            {
                value: "3",
                label: "Wednesday",
            },
            {
                value: "4",
                label: "Thursday",
            },
            {
                value: "5",
                label: "Friday",
            },
            {
                value: "6",
                label: "Saturday",
            },
            {
                value: "7",
                label: "Sunday",
            },
        ] as KeyValuePair[];
        //return placeholder.map((keyValuePair) => ({ value: keyValuePair.id, label: keyValuePair.name })) as KeyValuePair[];
        return placeholder;
    }

    public setOccurrenceDate(fieldName: keyof FieldType<NewContentModel>, value: Date) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    public get getContentFiles(): UserFile[] {
        return this.getValue("contentImages");
    }

    public get getMainImg(): UserFile[] {
        let retval: UserFile[] = [];
        if (this.getValue("mainImageUrl")) {
            retval.push({
                fileName: this.getValue("mainImageName"),
                fileUrl: this.getValue("mainImageUrl"),
            });
        }

        return retval;
    }

    public get getSponsorImg(): UserFile[] {
        let retval: UserFile[] = [];
        if (this.getValue("sponsorImageUrl")) {
            retval.push({
                fileName: this.getValue("sponsorImageName"),
                fileUrl: this.getValue("sponsorImageUrl"),
            });
        }

        return retval;
    }

    public get getSelectedCategoryOption(): KeyValuePair {
        let retVal: KeyValuePair = {} as KeyValuePair;
        let category = this.domainStores.categories.find((e) => e.id === this.getModel.categoryId);

        if (category) {
            retVal = {
                label: category.name,
                value: category.id,
            } as KeyValuePair;
        } else retVal = { label: "", value: null } as KeyValuePair;

        return retVal;
    }
    public get getTypesSortedByOrdinal(): KeyValuePair[] {
        let arr = this.domainStores.contentTypes.slice().sort((a, b) => (a.name < b.name ? -1 : 1));

        let keyValues: KeyValuePair[] = [];

        arr.map((item) =>
            keyValues.push({
                label: item.name,
                value: item.id,
            }),
        );

        return keyValues;
    }

    public get getCustomerOptions(): KeyValuePair[] {
        let placeholder = {
            label: "All",
            value: "All",
        } as KeyValuePair;

        let options = this.getCustomersSortedByOrdinal.map((keyValuePair) => ({ value: keyValuePair.id, label: keyValuePair.name })) as KeyValuePair[];

        options.unshift(placeholder);
        return options;
    }

    public get getCategoryColors(): CategoryColours[] {
        let retval: CategoryColours[] = [];

        retval = this.getCategoriesSortedByOrdinal.map((category: Category) => {
            return { id: category.id, name: category.name, colour: category.colour, accentColour: category.accentColour };
        });

        return retval;
    }

    public getCustomerNames = () => {
        let retVal: KeyValuePair[] = [];
        const customers = this.getValue<Customer[]>("customers");

        if (customers) {
            customers.forEach((index: any) => {
                retVal.push(...this.getCustomerOptions.filter((customerOption) => customerOption.value === index));
            });
        }

        return retVal;
    };

    public get getBonusPointsOptions(): KeyValuePair[] {
        let retval: KeyValuePair[] = [];

        for (let i = 0; i < 5; i++) {
            retval.push({ value: (i * 5).toString(), label: (i * 5).toString() });
        }
        return retval;
    }
    public get getPointsOptions(): KeyValuePair[] {
        let retval: KeyValuePair[] = [];

        for (let i = 0; i < 5; i++) {
            retval.push({ value: (i * 5).toString(), label: (i * 5).toString() });
        }
        return retval;
    }

    public setLocation = async (location: string, latitude: string, longitude: string, postcode: string) => {
        this.setValue("address", location);
        this.setValue("location", location + ", " + postcode);
    };

    public upsertContent = async (): Promise<boolean> => {
        this.setIsLoading(true);
        let isMyModelValid: boolean = this.isModelValid();
        if (isMyModelValid) {
            if (this.model.occurrenceType === "day" || this.model.occurrenceType === "user") {
                this.model.startDate = null;
            }

            if (this.model.customers.find((x) => x === "All")) {
                let options = this.getCustomersSortedByOrdinal.map((keyValuePair) => keyValuePair.id);
                this.model.customers = options;
            }

            let newContent: ContentAndRelated = {
                content: this.model.toDto(),
                customers: this.model.customersToDto(),
            };

            const apiResult = await this.httpClient.Post<ContentAndRelated>(AppUrls.Server.Admin.UpsertContent, newContent);
            if (apiResult.wasSuccessful) {
                this.domainStores.OpenSnackBar(`Content successfully saved.`, "success");
                this.history.push("/admin/content");
                return true;
            } else {
                apiResult.errors.forEach(() => {
                    this.domainStores.OpenSnackBar("There was an error creating this content. " + apiResult.errors[0].message, "error");
                });
                return false;
            }
        }
        return false;
    };
}

import React, { useRef, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { Link, Box } from "@mui/material";
import { AutoGrid } from "Globals/Components/Grid";
import { EditableCopyViewModel } from "Globals/ViewModels/EditableCopyViewModel";
import { GridLayouts } from "Examples/GridLayouts";
import { Buttons } from "Examples/Buttons";
import { TextInputs } from "Examples/TextInputs";
import { CustomDatePicker } from "Examples/CustomDatePicker";
import { Text } from "Examples/Text";
import { SelectInput } from "Examples/SelectInput";
import { Animation } from "Examples/Animation";
import { RadioInputExample } from "../../Examples/RadioInputExample";
import { Custom } from "../../Examples/Custom";
import { LottieView } from "../../Examples/LottieView";
import { Route } from "react-router-dom";
import { Dot, DotWrapper, LinkBox } from "../../Globals/Components";

export const SamplesView: React.FC = () => {
    const { current: viewModel } = useRef(new EditableCopyViewModel());

    return (
        <AutoGrid desktopColumns={"1fr 5fr"}>
            <div style={{ height: "100vh", overflow: "auto" }}>
                <LinkBox>
                    <Link onClick={() => viewModel.history.push("/samples/text")}>{ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/samples", 1))}</Link>
                </LinkBox>
                <LinkBox>
                    <Link onClick={() => viewModel.history.push("/samples/buttons")}>{ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/samples", 2))}</Link>
                </LinkBox>
                <LinkBox>
                    <Link onClick={() => viewModel.history.push("/samples/textinputs")}>
                        {ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/samples", 3))}
                    </Link>
                </LinkBox>
                <LinkBox>
                    <Link onClick={() => viewModel.history.push("/samples/selectinput")}>
                        {ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/samples", 4))}
                    </Link>
                </LinkBox>
                <LinkBox>
                    <Link onClick={() => viewModel.history.push("/samples/custom")}>{"Custom"}</Link>
                </LinkBox>
                <LinkBox>
                    <Link onClick={() => viewModel.history.push("/samples/lottie")}>{"Lottie"}</Link>
                </LinkBox>
                <LinkBox>
                    <Link onClick={() => viewModel.history.push("/samples/radio")}>{"Radio Input"}</Link>
                </LinkBox>
                <LinkBox>
                    <Link onClick={() => viewModel.history.push("/samples/date")}>{ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/samples", 5))}</Link>
                </LinkBox>
                <LinkBox>
                    <Link onClick={() => viewModel.history.push("/samples/grid")}>{ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/samples", 6))}</Link>
                </LinkBox>
                <LinkBox>
                    <Link onClick={() => viewModel.history.push("/samples/animation")}>{ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/samples", 8))}</Link>
                </LinkBox>
                <LinkBox>
                    {/*<Link onClick={() => setView(<Loaders />)}>{ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/samples", 9))}</Link>*/}
                    <Link onClick={() => viewModel.history.push("/samples/loaders")}>Loaders</Link>
                </LinkBox>
            </div>
            <Box style={{ height: "100vh", overflow: "auto" }} pt={5}>
                {/*{view}*/}
                <Route exact path={"/samples/text"} component={Text} />
                <Route exact path={"/samples/buttons"} component={Buttons} />
                <Route exact path={"/samples/textinputs"} component={TextInputs} />
                <Route exact path={"/samples/selectinput"} component={SelectInput} />
                <Route exact path={"/samples/lottie"} component={LottieView} />
                <Route exact path={"/samples/radio"} component={RadioInputExample} />
                <Route exact path={"/samples/date"} component={CustomDatePicker} />
                <Route exact path={"/samples/grid"} component={GridLayouts} />
                <Route exact path={"/samples/animation"} component={Animation} />
                <Route exact path={"/samples/loaders"} component={Loaders} />
            </Box>
        </AutoGrid>
    );
};

export const Loaders: React.FC = () => {
    return (
        <>
            <Box paddingTop={2}>
                Animated Dots
                <DotWrapper>
                    <Dot delay="0s" />
                    <Dot delay=".1s" />
                    <Dot delay=".2s" />
                </DotWrapper>
            </Box>
        </>
    );
};

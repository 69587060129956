import { ViewModelBase } from "@shoothill/core";
import { FieldType } from "@shoothill/core";
import { RegisterUserDetailsModel, RegisterUserDetailsValidator } from "./RegisterUserDetailslModel";
import { GlobalHistory } from "../../index";
import { RelayCommandAsync, ICommandAsync } from "Globals/Commands";
import { IKeyState } from "../../Globals";
import { RegisterUserModel } from "./RegisterUserModel";

//Pass in <any> as the generic type because we are showing the use of nested classes
export class RegisterUserDetailsViewModel extends ViewModelBase<RegisterUserDetailsModel> {
    constructor() {
        super(new RegisterUserDetailsModel());
        this.setValidator(new RegisterUserDetailsValidator());
    }

    public updateOrganisationNameCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("companyName", value, keyState);
    });
    public updatePositionCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("position", value, keyState);
    });
    public updateCompanyWebsiteCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("companyWebsite", value, keyState);
    });
    public updateCompanRegNoCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("companyRegNo", value, keyState);
    });
    public updateVATNumberCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("position", value, keyState);
    });
    public updateAddressLine1Command: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("address1", value, keyState);
    });
    public updateAddressLine2Command: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("address2", value, keyState);
    });
    public updateTownCityCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("city", value, keyState);
    });
    public updatePostCodeCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("postCode", value, keyState);
    });

    private async updateField(fieldName: keyof FieldType<RegisterUserDetailsModel>, value: any, keyState: IKeyState) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }
}

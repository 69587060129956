import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

export class ExampleModel extends ModelBase<ExampleModel> {
    public team: string = "0";
    public teams: string[] = [];
    public password: string = "";
    public firstName: string = "";
    public lastName: string = "";
    public date: Date | null = null;
    public gender: string = "male";

    constructor() {
        super();
        makeObservable(this, {
            team: observable,
            teams: observable,
            password: observable,
            firstName: observable,
            lastName: observable,
            date: observable,
            gender: observable,
        });
    }
}

import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { RegisterUserViewModel } from "./RegisterUserViewModel";
import { RegisterUserModel } from "./RegisterUserModel";
import { useObserver } from "mobx-react-lite";
import { KeyValuePair } from "@shoothill/core";
import { AutoCell, AutoGrid } from "Globals/Components/Grid";
import { EditText } from "../../Globals/Views/Primitives/EditText";
import { Select } from "../../Globals/Views/Primitives/Select";
import { CheckBox } from "../../Globals/Views/Primitives/CheckBox";
import { useViewModel } from "../../Globals/Hooks/UseViewModel";
import { MainSubmitButton, TypographyX } from "../../Globals/Components";
import { RegisterUserDetailsViewModel } from "./RegisterUserDetailsViewModel";

export const RegisterView: React.FC = () => {
    const [viewModel] = useState(() => new RegisterUserViewModel());
    const [errorMessage, setErrorMessage] = useState("");
    useEffect(() => {}, []);

    const doSubmit = async (e: any) => {
        e.preventDefault();
        //isValid will check all fields to make sure they are in a valid state.
        if (!viewModel.isModelValid()) {
            setErrorMessage("Problem");
        } else {
            setErrorMessage("");
            await viewModel.register();
        }
    };

    return (
        <Box marginTop="20px" marginLeft="5%" marginRight="5%">
            <form onSubmit={doSubmit}>
                {errorMessage !== "" && (
                    <Typography variant="caption" style={{ color: "red" }}>
                        {errorMessage}
                    </Typography>
                )}
                <AutoGrid desktopColumns="1fr 1fr 1fr" rowGap="20px">
                    <EditText
                        displayName="First Name"
                        command={viewModel.updateFirstNameCommand}
                        value={() => viewModel.getValue("firstName")}
                        validationMessage={() => viewModel.getError("firstName")}
                    />
                    <EditText
                        displayName="Last Name"
                        command={viewModel.updateLastNameCommand}
                        value={() => viewModel.getValue("lastName")}
                        validationMessage={() => viewModel.getError("lastName")}
                    />
                </AutoGrid>

                <UserDetailsView />

                <AutoGrid desktopColumns="1fr 1fr 1fr 1fr" paddingTop="35px">
                    <EditText
                        displayName="Contact email"
                        type={"email"}
                        command={viewModel.updateEmailAddressCommand}
                        value={() => viewModel.getValue("emailAddress")}
                        validationMessage={() => viewModel.getError("emailAddress")}
                    />
                    <EditText
                        displayName="Contact number"
                        command={viewModel.updatePhoneNumber}
                        value={() => viewModel.getValue("phoneNumber")}
                        validationMessage={() => viewModel.getError("phoneNumber")}
                    />
                </AutoGrid>
                <AutoGrid desktopColumns="1fr 1fr 1fr 1fr" paddingTop="35px">
                    <EditText
                        type={"password"}
                        displayName="Password"
                        command={viewModel.updatePasswordCommand}
                        value={() => viewModel.getValue("password")}
                        validationMessage={() => viewModel.getError("password")}
                        autoFill={false}
                    />
                    <EditText
                        type={"password"}
                        displayName="Confirm password"
                        command={viewModel.updateConfirmPasswordCommand}
                        value={() => viewModel.getValue("confirmPassword")}
                        validationMessage={() => viewModel.getError("confirmPassword")}
                        autoFill={false}
                    />
                </AutoGrid>
                <Box>
                    <TypographyX css="" style={{ paddingBottom: "50px", paddingTop: "10px" }}>
                        Password must include at least 6 characters and contain one number
                    </TypographyX>
                </Box>
                <CheckBox
                    displayName="Terms and Conditions"
                    command={viewModel.updateTermsCommand}
                    value={() => viewModel.getValue("terms")}
                    validationMessage={() => viewModel.getError("terms")}
                />
                <MainSubmitButton buttonText={"Register and Continue"} showDivider={true} />
            </form>
        </Box>
    );
};

function UserDetailsView() {
    const [viewModel] = useState(() => new RegisterUserDetailsViewModel());

    return (
        <>
            <AutoGrid desktopColumns="1fr 1fr 1fr" paddingTop="35px">
                <EditText
                    displayName="Organisation Name"
                    command={viewModel.updateOrganisationNameCommand}
                    value={() => viewModel.getValue("companyName")}
                    validationMessage={() => viewModel.getError("companyName")}
                />
                <EditText
                    displayName="Position"
                    command={viewModel.updatePositionCommand}
                    value={() => viewModel.getValue("position")}
                    validationMessage={() => viewModel.getError("position")}
                />
            </AutoGrid>
            <AutoGrid desktopColumns="1fr 1fr 1fr" paddingTop="35px">
                <EditText
                    displayName="Company website"
                    command={viewModel.updateCompanyWebsiteCommand}
                    value={() => viewModel.getValue("companyWebsite")}
                    validationMessage={() => viewModel.getError("companyWebsite")}
                />
                <EditText
                    displayName="Company registration number"
                    command={viewModel.updateCompanRegNoCommand}
                    value={() => viewModel.getValue("companyRegNo")}
                    validationMessage={() => viewModel.getError("companyRegNo")}
                />
                <EditText
                    displayName="VAT number"
                    command={viewModel.updateVATNumberCommand}
                    value={() => viewModel.getValue("companyVatNo")}
                    validationMessage={() => viewModel.getError("companyVatNo")}
                />
            </AutoGrid>
            <AutoGrid desktopColumns="1fr 1fr" paddingTop="35px">
                <EditText
                    displayName="Address line 1"
                    command={viewModel.updateAddressLine1Command}
                    value={() => viewModel.getValue("address1")}
                    validationMessage={() => viewModel.getError("address1")}
                />
                <EditText
                    displayName="Address line 2"
                    command={viewModel.updateAddressLine2Command}
                    value={() => viewModel.getValue("address2")}
                    validationMessage={() => viewModel.getError("address2")}
                />
            </AutoGrid>
            <AutoGrid desktopColumns="1fr 1fr" paddingTop="35px">
                <EditText
                    displayName="Town/City"
                    command={viewModel.updateTownCityCommand}
                    value={() => viewModel.getValue("city")}
                    validationMessage={() => viewModel.getError("city")}
                />
                <EditText
                    displayName="PostCode"
                    command={viewModel.updatePostCodeCommand}
                    value={() => viewModel.getValue("postCode")}
                    validationMessage={() => viewModel.getError("postCode")}
                />
            </AutoGrid>
        </>
    );
}
